import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import openWindow from '@tkl-packages/sdk/src/utils/openWindow';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import { getAuthTokenValidityApi } from '@tkl-packages/services/src/mapi/userApi';

const useLogin = () => {
  const navigate = useNavigate();

  const {
    data: isLoggedIn = false,
    isFetchedAfterMount,
    isError,
    refetch
  } = useQuery({
    queryKey: [QUERY_KEY.AUTH_TOCKEN_VALIDITY],
    queryFn: () => getAuthTokenValidityApi().then((res) => res?.data)
  });

  const goLogin = () => {
    if (isAppWeb()) {
      navigate('/login');
    } else {
      openWindow(`/auth/login?nextURL=${document.URL}`, '로그인', { width: 420, height: 550 });
    }
  };

  const needLogin =
    (fn: Function) =>
    (...param: any) => {
      if (!isError && isLoggedIn) {
        fn(...param);
        return;
      }

      if (confirm('로그인이 필요합니다')) {
        goLogin();
      }
    };

  return { isLoggedIn, goLogin, needLogin, isFetchedAfterMount, isError, refetch };
};

export default useLogin;
