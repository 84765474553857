import { CategoryListType } from '@tkl-packages/models';
import { Response } from '@tkl-packages/sdk/src/axios/axios.type';
import { requests } from '@tkl-packages/sdk/src/axios/mapiInterface';

// 오픈예정을 포함해서 호출할땐 includePreOpen = true

// 스포츠 - 각 종목 페이지(오프시즌(MO,PC)) 이벤트 목록: 배너
const getSportCategoryListByNameApi = async (categoryName: string, includePreOpen = false) => {
  const res = await requests.get<Response<CategoryListType[]>, unknown>(
    `sports/category-names/${categoryName}/planning-events?includePreOpen=${includePreOpen}`
  );
  return res.data;
};

const getSportCategoryListByIdApi = async (categoryId: string, includePreOpen = false) => {
  const res = await requests.get<Response<CategoryListType[]>, unknown>(
    `sports/categories/${categoryId}/planning-events?includePreOpen=${includePreOpen}`
  );
  return res.data;
};

// 스포츠 - 구단회원 페이지(MO,APP) 이벤트 목록: 배너 & [APP] 구단회원일 때 스포츠 기획전 페이지
const getFacilityCategoryListApi = async (includePreOpen = false) => {
  const res = await requests.get<Response<CategoryListType[]>, unknown>(
    `sports/facility/planning-events?includePreOpen=${includePreOpen}`
  );
  return res.data;
};

export { getSportCategoryListByNameApi, getSportCategoryListByIdApi, getFacilityCategoryListApi };
