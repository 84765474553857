import { TOUR_RESERVE_LIST_URL } from './drtour';
import { SERVICE_TYPE, ServiceTypeUnion } from './services';

export interface MenuType {
  name: string;
  path: string;
  useNative?: boolean;
  useLoginPopup?: boolean;
  serviceType?: ServiceTypeUnion;
}

export interface RootMenuType extends MenuType {
  subMenus: MenuType[];
}

export const MY_LEFT_MENUS: RootMenuType[] = [
  {
    name: '예매관리',
    path: '',
    subMenus: [
      {
        name: '예매확인/취소',
        path: '/my/reserve/list',
        useNative: true
      },
      {
        name: '여행 예약확인/취소',
        path: TOUR_RESERVE_LIST_URL,
        useNative: true,
        serviceType: SERVICE_TYPE.DRTOUR
      }
    ]
  },
  {
    name: '할인혜택',
    path: '',
    subMenus: [
      {
        name: 'PAYCO 포인트',
        path: '/my/discount/point',
        useNative: true
      },
      {
        name: '예매권',
        path: '/my/discount/advanceticket-new',
        useNative: true
      },
      {
        name: '상품권',
        path: '/my/discount/giftcard',
        useNative: true
      },
      {
        name: '쿠폰',
        path: '/my/discount/coupon',
        useNative: true
      }
    ]
  },
  {
    name: '활동관리',
    path: '',
    subMenus: [
      {
        name: '참여 이벤트',
        path: '/my/activity/event',
        useNative: true
      },
      {
        name: '후기관리',
        path: '/my/activity/review',
        useNative: true
      },
      {
        name: '팬클럽/멤버십 내역',
        path: '/my/activity/fanclub',
        useNative: true
      },
      {
        name: '취소표 대기 신청 내역',
        path: '/my/activity/waiting/list',
        useNative: true
      }
    ]
  },
  {
    name: '증빙서류',
    path: '',
    subMenus: [
      {
        name: '입금증',
        path: '/my/document/deposit',
        useNative: true
      },
      {
        name: '현금영수증',
        path: '/my/document/cash',
        useNative: true
      },
      {
        name: '신용카드 매출전표',
        path: '/my/document/creditCard',
        useNative: true
      }
    ]
  },
  {
    name: '회원정보관리',
    path: '',
    subMenus: [
      {
        name: '환불계좌관리',
        path: '/my/memberInfo/refundAccount',
        useNative: true
      },
      {
        name: '회원정보수정',
        path: '/my/memberInfo/info',
        useNative: true
      },
      {
        name: '회원탈퇴',
        path: '/my/memberInfo/withdrawal',
        useNative: true
      }
    ]
  }
];

export const HELP_MENUS: RootMenuType[] = [
  { name: '고객센터 홈', path: '/help/main', subMenus: [], useNative: true },
  { name: '공지사항', path: '/help/notice', subMenus: [], useNative: true },
  { name: 'FAQ', path: '/help/faq', subMenus: [] },
  { name: '1:1 상담', path: '/cs/inquiry', subMenus: [], useLoginPopup: true },
  { name: '1:1 문의내역', path: '/cs/inquiry-history', subMenus: [], useLoginPopup: true },
  {
    name: '안내',
    path: '',
    subMenus: [
      { name: '예매안내', path: '/help/guide/reservation' },
      { name: '회원정보안내', path: '/help/guide/memberInfo' },
      { name: '결제수단안내', path: '/help/guide/payment' },
      { name: '수수료안내', path: '/help/guide/charge' },
      { name: '취소/환불안내', path: '/help/guide/cancel' },
      { name: '티켓수령안내', path: '/help/guide/ticket' },
      { name: '예매채널안내', path: '/help/guide/channel' },
      { name: '할인수단안내', path: '/help/guide/discountMethod' },
      { name: '할인카드안내', path: '/help/guide/discountCard/baseball' },
      { name: '부정이용 규제안내', path: '/help/guide/legalGuide' },
      { name: '취소표대기안내', path: '/help/guide/waitingGuide' }
    ]
  },
  { name: '티켓판매안내', path: '/help/partner', subMenus: [] }
];
