import { ReactNode, useContext } from 'react';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import openWindow from '@tkl-packages/sdk/src/utils/openWindow';

export interface BaseProps {
  children?: ReactNode;
  to: string;
  isCurrentPath: boolean;
  useNative?: boolean;
  useLoginPopup?: boolean;
}

export type Props = BaseProps;

function LeftMenuItem(props: BaseProps) {
  const { children, to, isCurrentPath, useNative, useLoginPopup } = props;
  const { needLogin } = useContext(LoginContext);

  const openPopupWithLogin = needLogin(() =>
    openWindow(`https://${location.hostname}${to}`, '', {
      width: 880,
      height: 900,
      left: 100,
      top: 100
    })
  );

  return (
    <li className={`${isCurrentPath ? 'on' : ''}`}>
      <TKLink to={to} useNative={useNative} {...(useLoginPopup && { onClick: openPopupWithLogin })}>
        {children}
      </TKLink>
    </li>
  );
}

export default LeftMenuItem;
