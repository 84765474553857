import { ComponentType, useContext } from 'react';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import { isFacility } from '@tkl-packages/sdk/src/validations';
import LoginMobile from './Login.mobile';

interface Props {
  [key: string]: any;
}

const withLogin = (Component: ComponentType<Props>) => {
  const withLoginCore = ({ ...props }) => {
    // eslint-disable-next-line
    const { isFetchedAfterMount, isLoggedIn } = useContext(LoginContext);
    if (!isFetchedAfterMount) {
      return <></>;
    }

    if (isLoggedIn) {
      return <Component {...props} />;
    }

    if (!isFacility()) {
      return <LoginMobile />;
    }

    return <></>;
  };
  withLoginCore.displayName = 'withLoginCore';

  return withLoginCore;
};

export default withLogin;
