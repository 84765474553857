import { useState, useEffect, useRef, useCallback } from 'react';
import { SCHEDULE } from '@tkl-packages/constants/sports';
import useScroll from '@tkl-packages/hooks/src/useScroll';
import { ScheduleValues } from '@tkl-packages/models';
import dayjs from 'dayjs';

interface props {
  type: ScheduleValues;
  defaultDate: string;
}

/**
 * 스포츠 스케줄 컴포넌트에서 사용하는 hook
 * @param type - UI 타입 ( MAIN, TEAM )
 * @param defaultDate - 기본으로 선택된 날짜 '20210124'
 * @returns
 */
const useSportsSchedule = ({ type = SCHEDULE.MAIN, defaultDate }: props) => {
  const [activeDate, setActiveDate] = useState(defaultDate);
  const calendarScrollRef = useRef<HTMLElement>(null);
  const matchScrollRef = useRef<HTMLUListElement>(null);
  const matchElementInfoRef = useRef<{ [date: string]: HTMLElement }>({});
  const matchElementListRef = useRef<{ element: HTMLElement; date: string }[]>([]);
  const calendarElementInfoRef = useRef<{ [date: string]: HTMLElement }>({});

  const isShow = useCallback(
    (el: HTMLElement) => {
      if (!el) {
        return false;
      }
      const { top, bottom } = el.getBoundingClientRect();
      //TODO: 상단 여백 동적으로 구하기
      return top < window.innerHeight && bottom >= (type === SCHEDULE.TEAM ? 170 : 150);
    },
    [type]
  );

  //경기 컴포넌트 ref 주입
  const setMatchComponentRef = useCallback((element: HTMLElement, date: string) => {
    const formattedDate = dayjs(new Date(date)).tz().format('YYYY/MM/DD');
    if (!matchElementInfoRef.current[formattedDate]) {
      matchElementInfoRef.current[formattedDate] = element;
    }
    matchElementListRef.current.push({ element: element, date: date });
  }, []);

  // 경기 컴포넌트 ref 초기화
  const initMatchComponentRef = useCallback(() => {
    matchElementInfoRef.current = {};
    matchElementListRef.current = [];
  }, []);

  //캘린더 컴포넌트 ref 주입
  const setCalendarComponentRef = useCallback((element: HTMLElement, date: string) => {
    if (calendarElementInfoRef && !calendarElementInfoRef.current[date]) {
      calendarElementInfoRef.current[date] = element;
    }
  }, []);

  //캘린더 날짜 클릭시 경기 스크롤 이동
  const onClickDate = useCallback(
    (date: string) => {
      if (matchElementInfoRef?.current[date]) {
        //TODO: 상단 sticky 높이 동적으로 구하기
        const topStickyHeight = type === SCHEDULE.TEAM ? 180 : 87;
        const elementTop = matchElementInfoRef.current[date].getBoundingClientRect().top;
        const offset = elementTop + window.scrollY - topStickyHeight;
        window.scrollTo({
          top: offset,
          behavior: 'smooth'
        });
      }
    },
    [type]
  );

  //activeDate 변경시 캘린더 스크롤 이동
  useEffect(() => {
    const option: ScrollToOptions = {
      behavior: 'smooth'
    };

    const calendarElement = calendarElementInfoRef?.current?.[activeDate];

    if (calendarElement) {
      if (type === SCHEDULE.TEAM) {
        option.left = calendarElement.offsetLeft - 10;
      } else {
        option.top = calendarElement.offsetTop;
      }
      calendarScrollRef?.current?.scrollTo(option);
    }
  }, [activeDate, type]);

  //스크롤시 보이는 경기의 날짜로 activeDate 세팅
  const onMatchAreaScroll = () => {
    let match: any = matchElementListRef.current.find((ref: { element: HTMLElement }) => isShow(ref.element));

    if (matchScrollRef?.current) {
      const { bottom } = matchScrollRef.current.getBoundingClientRect();
      if (bottom + 100 <= window.innerHeight) {
        match = matchElementListRef.current[matchElementListRef.current.length - 1];
      }
    }

    if (match?.date) {
      setActiveDate(dayjs(new Date(match.date)).tz().format('YYYY/MM/DD'));
    }
  };

  useScroll(onMatchAreaScroll);

  return {
    calendarScrollRef,
    matchScrollRef,
    setMatchComponentRef,
    initMatchComponentRef,
    setCalendarComponentRef,
    onClickDate,
    setActiveDate,
    activeDate
  };
};

export default useSportsSchedule;
