import React from 'react';
import { useTranslation } from 'react-i18next';
import LayoutGlobal from '@tkl-packages/components/src/ticketlink/Layout/Layout.global';

const ESports = () => {
  const { t } = useTranslation();

  return (
    <LayoutGlobal>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100vh'
        }}
      >
        <h2>{t('e스포츠')}</h2>
      </div>
    </LayoutGlobal>
  );
};

export default ESports;
