import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { wrapBaseURL, wrapTourURL } from '@tkl-apps/drtour/src/utils/common';
import { LeftMenuItem, LeftSubMenu, LeftSubMenuItem } from '@tkl-packages/components/src/ticketlink/Menu';
import { RootMenuType } from '@tkl-packages/constants/menu';
import { SERVICE_TYPE, ServiceTypeUnion } from '@tkl-packages/constants/services';
import { getCurrentService } from '@tkl-packages/sdk/src/utils/getCurrentService';

export interface BaseProps {
  menus: RootMenuType[];
}

export type Props = BaseProps;

const LeftMenu = (props: Props) => {
  const { menus } = props;
  const { pathname } = useLocation();

  const wrapURL = useCallback((dest: string, targetService: ServiceTypeUnion = SERVICE_TYPE.TICKETLINK) => {
    const currentService = getCurrentService();
    if (currentService === targetService) {
      return dest;
    }

    if (targetService === SERVICE_TYPE.DRTOUR) {
      return wrapTourURL(dest);
    }

    return wrapBaseURL(dest);
  }, []);

  const isMatchCurrentPath = useCallback(
    (dest: string) => {
      if (pathname === dest) {
        return true;
      }

      if (pathname.includes(dest)) {
        return true;
      }

      return false;
    },
    [pathname]
  );

  return (
    <ul className="help_lmenu">
      {menus.map((menu) =>
        menu.subMenus.length > 0 ? (
          <LeftSubMenu name={menu.name} key={`LeftSubMenu_${menu.name}`}>
            {menu.subMenus.map((subMenu) => (
              <LeftSubMenuItem
                to={wrapURL(subMenu.path, subMenu.serviceType)}
                useNative={subMenu.useNative}
                isCurrentPath={isMatchCurrentPath(subMenu.path)}
                key={`LeftSubMenuItem_${subMenu.name}`}
              >
                {subMenu.name}
              </LeftSubMenuItem>
            ))}
          </LeftSubMenu>
        ) : (
          <LeftMenuItem
            to={menu.path}
            useNative={menu.useNative}
            useLoginPopup={menu.useLoginPopup}
            isCurrentPath={isMatchCurrentPath(menu.path)}
            key={`LeftMenuItem_${menu.name}`}
          >
            {menu.name}
          </LeftMenuItem>
        )
      )}
    </ul>
  );
};

export default LeftMenu;
