const PRODUCT_QUERY_KEY = {
  PRODUCTS: 'products',
  PRODUCT_DATES: 'productDates',
  PRODUCT_ROUNDS: 'productRounds',
  PRODUCT_GRADES: 'productGrades',
  EXHIBITION_PRODUCTS: 'exhibitonProducts',
  PERFORMANCE_PRODUCTS: 'performanceProducts',
  PLAN_PRODUCTS: 'planProducts',
  LOCAL_PRODUCTS: 'localProducts',
  PLAN_CATEGORIES: 'planCategories',
  PRODUCT_SUMMARY: 'productSummary',
  ADDITIONAL_PRODUCT_GUIDE: 'additionalProductGuide',
  PRODUCT_PLACE: 'productPlace',
  REVIEW_EVENT: 'reviewEvent',
  REVIEW_INFO: 'reviewInfo',
  PRODUCT_LIST_BANNER: 'productListBanner',
  PRODUCT: 'product',
  PRODUCT_BANNER_FIRST: 'productBannerFirst',
  PRODUCT_BANNER_SECOND: 'productBannerSecond',
  ATTENTION_PRODUCTS: 'attentionProducts',
  RECOMMEND_PRODUCTS: 'recommendProducts',
  PRODUCT_COMMON_INFO: 'productCommonInfo',
  CAST_SEARCH: 'castSearch',
  CATEGORY_TICKET_OPEN: 'categoryTicketOpen',
  FACE_ENTRANCE_PRODUCTS: 'faceEntranceProducts',
  LOCAL_CATEGORY_PRODUCT_COUNT: 'localCategoryProductCount',
  BUGS_HERO_BANNER: 'bugsHeroBanner',
  BUGS_LIVE_PRODUCTS: 'bugsLiveProducts',
  BUGS_PREMIUM_PRODUCTS: 'bugsPremiumProducts'
} as const;

const SPORTS_QUERY_KEY = {
  MATCH_LIST: 'matchList',
  TEAM_INFORMATION: 'teamInformation',
  TEAM_NOTICES: 'teamNotices',
  TEAM_PRICE_GUIDE: 'teamPriceGuide',
  TEAM_SEAT_MAP: 'teamSeatMap',
  SPORTS_EVENT: 'sportsEvent',
  SPORTS_EVENTS: 'sportsEvents',
  SPORTS_FACILITY_EVENTS: 'sportsFacilityEvents',
  SPORTS_MAIN_BANNERS: 'sportsMainBanners',
  SPORTS_DISCOUNT_CARD_BANNER: 'sportsDiscountCardBanner',
  SPORTS_TEAM_BANNERS: 'sportsTeamBanners',
  SPORTS_POPUP_BANNER: 'sportsPopupBanner',
  SPORTS_KBO_BANNERS: 'sportsKboBanners',
  SPORTS_WING_BANNERS: 'sportsWingBanners',
  SPORTS_RESERVE_BANNERS: 'sportsReserveBanners',
  SPORTS_ESPORTS_BANNERS: 'sportsEsportsBanners',
  SPORTS_CATEGORIES: 'sportsCategories',
  SPORTS_RESERVE_OPEN: 'sportsReserveOpen',
  SPORTS_RESERVE_OPEN_CATEGORIES: 'sportsReserveOpenCategories',
  TEAMS: 'teams',
  TEAM_DETAIL: 'teamDetail',
  TEAM_POPUP_NOTICE: 'teamPopupNotice',
  TEAM_POPUP_BANNERS: 'teamPopupBanners',
  RESERVE_OPEN_SUBSCRIBE_STATUS: 'reserveOpenSubscribeStatus',
  OFF_SEASON_SETTINGS: 'offSeasonSettings',
  CLUB_TEAM_GAME: 'clubTeamGame'
} as const;

const MAIN_QUERY_KEY = {
  PLANS: 'plans',
  MOBILE_MAIN_BANNERS: 'mobileMainBanners',
  MOBILE_FLASH_BANNER: 'mobileFlashBanner',
  FLASH_BANNER: 'flashBanner',
  MOBILE_POPUP_BANNER: 'mobilePopupBanner',
  CATEGORY_BANNERS: 'category_banners',
  POPUP_BANNER: 'popupBanner',
  PAYCO_VIP: 'paycoVip',
  TICKET_OPEN: 'ticketOpen',
  TICKET_SPOT: 'ticketSpot',
  VENUE: 'venue',
  VENUE_PRODUCTS: 'venueProducts',
  WEEKLY_SHOW: 'weeklyShow',
  BEST_SHOW: 'bestShow',
  TODAY_RECOMMENDS: 'todayRecommends',
  HERO_BANNER: 'heroBanner',
  PROMOTION_BANNER: 'promotionBanner',
  WING_BANNER: 'wingBanner',
  GNB_BANNER: 'gnbBanner'
} as const;

const SEARCH_QUERY_KEY = {
  AUTO_COMPLETE: 'autoComplete',
  POPULAR_QUERIES: 'popularQueries',
  SEARCH_BANNERS: 'searchBanners',
  SEARCH_PRODUCTS: 'searchProducts'
} as const;

const AD_QUERY_KEY = {
  HEADER_AD_BANNER: 'headerAdBanner',
  SHOW_PROMOTION_AD_BANNER: 'showPromotionAdBanner',
  SPORTS_PROMOTION_AD_BANNER: 'sportsPromotionAdBanner',
  SHOW_LINE_AD_BANNER: 'showLineAdBanner',
  SPORTS_LINE_AD_BANNER: 'sportsLineAdBanner',
  EXHIBITION_LINE_AD_BANNER: 'exhibitionLineAdBanner',
  PERFORMANCE_LINE_AD_BANNER: 'performanceLineAdBanner',
  PRODUCT_LINE_AD_BANNER: 'productLineAdBanner'
} as const;

const FACILITY_QUERY_KEY = {
  FACILITY_MEMBER_CARD: 'facilityMemberCard'
} as const;

const RANKING_QUERY_KEY = {
  RANKING: 'ranking',
  RANKING_CATEGORIES: 'rankingCategories',
  RANKING_GENRE_CATEGORY: 'rankingGenreCategory',
  RANKING_GENRE_2DEPTH_CATEGORY: 'rankingGenre2DepthCategory',
  RANKING_LOCAL_CATEGORY: 'rankingLocalCategory',
  RANKING_WEEKLY_BEST: 'rankingWeeklybest'
};

const USER_QUERY_KEY = {
  USER_WITHDRAWAL: 'userWithdrawal',
  USER_FACE_INFO: 'userFaceInfo',
  USER_IDENTITY_CERTIFICATION: 'userIdentityCertification',
  DEVICE_AUTH_INFO: 'deviceAuthInfo',
  DEVICE_AUTH_STATUS: 'deviceAuthStatus'
};

const TOUR_QUERT_KEY = {
  TOUR_SERVER_APPLY: 'tourServerApply',
  TOUR_SET_APPLY: 'tourSetApply',
  TOUR_GET_MENU_INFO: 'tourMenuInfo'
};

const PLANNING_QUERY_KEY = {
  PLANNING_MAIN_BANNER: 'planningMainBanner',
  PLANNING_SPEECH_BUBBLERS: 'planningSpeechBubblers',
  PLANNING_PLANNING_EVENTS: 'planningPlanningEvents',
  PLANNING_EXHIBITION_CATEGORY_LIST: 'planningExhibitionCategoryList',
  PLANNING_EXHIBITION_CLASSIFICATION_TITLE_DATA: 'planningExhibitionClassificationTitleData',
  PLANNING_SPORTS_CATEGORY_LIST: 'planningSportsCategoryList',
  PLANNING_SPORTS_CLASSIFICATION_TITLE_DATA: 'planningExhibitionClassificationTitleData',
  PLANNING_MOBILE_SPORTS_CATEGORY_LIST: 'planningMobileSportsCategoryList',
  PLANNING_FACILITY_CATEGORY_LIST: 'planningFacilityCategoryList',
  PLANNING_COUPON: 'planningCoupon'
};

const HELP_QUERY_KEY = {
  GUIDE_DISCOUNT_CARD_LIST: 'guideDiscountCardList'
};

const QUICK_MENU_QUERY_KEY = {
  PAYCO_MILEAGE: 'paycoMileage',
  MY_PRESALE_COUNT: 'myPresaleCount',
  MY_COUPON_COUNT: 'myCouponCount'
};

const MYPAGE_QUERY_KEY = {
  DEPOSIT_LIST: 'depositList',
  CASH_LIST: 'cashList',
  CREDIT_CARD_LIST: 'creditCardList',
  CREDIT_CARD_PRINT: 'creditCardPrint'
};

const QUERY_KEY = {
  CATEGORY: 'category',
  FAQS: 'faqs',
  FAQ_DETAIL: 'faqDetail',
  PARTNERS: 'partners',
  TODAY: 'today',
  AUTH_TOCKEN_VALIDITY: 'authTokenValidity',
  ADULT_AUTH: 'adultAuth',
  USER_INFO: 'userInfo',
  APP_PUSH_NOTIFICATIONS: 'appPushNotifications',
  APP_PUSH_AGREEMENT: 'appPushAgreement',
  SMART_TICKETS: 'smartTickets',
  ADVANCE_TICKETS: 'advanceTickets',
  ...PRODUCT_QUERY_KEY,
  ...SPORTS_QUERY_KEY,
  ...MAIN_QUERY_KEY,
  ...SEARCH_QUERY_KEY,
  ...AD_QUERY_KEY,
  ...FACILITY_QUERY_KEY,
  ...RANKING_QUERY_KEY,
  ...USER_QUERY_KEY,
  ...TOUR_QUERT_KEY,
  ...PLANNING_QUERY_KEY,
  ...HELP_QUERY_KEY,
  ...QUICK_MENU_QUERY_KEY,
  ...MYPAGE_QUERY_KEY
} as const;

export { QUERY_KEY };
