import axios, { AxiosResponse } from 'axios';
import { AdJsonResponse, AdResponse } from './axios.type';

const adResponseBody = (response: AxiosResponse): AdResponse => response.data;

const adPcAxiosInstance = axios.create({
  baseURL: 'https://ads.netinsight.co.kr/NetInsight/text/ticketlink/',
  timeout: 10000
});

const adMoAxiosInstance = axios.create({
  baseURL: 'https://ads.netinsight.co.kr/NetInsight/bundle/mo.app_ticketlink/',
  timeout: 10000
});

const adPcRequests = {
  get: (url: string) => adPcAxiosInstance.get<AdResponse>(url).then(adResponseBody)
};

const adMoRequests = {
  get: (url: string) => adMoAxiosInstance.get<AdJsonResponse[]>(url).then(adResponseBody)
};

export { adPcRequests, adMoRequests };
