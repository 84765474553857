import { Union } from '@tkl-packages/models';

export const SERVICE_TYPE = {
  TICKETLINK: 'ticketlink',
  DRTOUR: 'drtour',
  RESERVE: 'reserve',
  ACTION_TRACER: 'action-tracer',
  ADMIN: 'admin',
  I18N: 'i18n',
  TK_I18N: 'tk-i18n'
} as const;

export type ServiceTypeUnion = Union<typeof SERVICE_TYPE>;
