import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { isFacility, isPc } from '@tkl-packages/sdk/src/validations';

type ChannelProps = {
  pc?: ReactNode;
  mobile?: ReactNode;
  facility?: ReactNode;
};

const Channel: FC<ChannelProps> = ({ pc = <Navigate to="/404" />, mobile = <Navigate to="/404" />, facility } = {}) => {
  if (isFacility() && facility) {
    return <>{facility}</>;
  }

  if (isPc()) {
    return <>{pc}</>;
  }

  return <>{mobile}</>;
};

export default Channel;
