import { useMemo } from 'react';
import { wrapBaseURL } from '@tkl-apps/drtour/src/utils/common';
import { APP_SCHEME, appScheme } from '@tkl-packages/constants/appScheme';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';

interface UseFooterAnchorType {
  useWrapUrl: boolean;
}

const useFooterAnchor = ({ useWrapUrl }: UseFooterAnchorType) => {
  // 회사소개
  const moveCompany = useMemo(() => {
    if (useWrapUrl) {
      return wrapBaseURL('/introduction/company');
    }
    return '/introduction/company';
  }, [useWrapUrl]);

  // 개인정보 처리방침
  const movePersonalInformation = useMemo(() => {
    if (isAppWeb()) {
      return appScheme(APP_SCHEME.TERM_PERSONAL_INFORMATION);
    }

    if (useWrapUrl) {
      return wrapBaseURL('/policy/privacy');
    }

    return '/policy/privacy';
  }, [useWrapUrl]);

  // 청소년 보호정책
  const moveYouth = useMemo(() => {
    if (isAppWeb()) {
      return appScheme(APP_SCHEME.TERM_YOUTH);
    }

    if (useWrapUrl) {
      return wrapBaseURL('/policy/youth');
    }

    return '/policy/youth';
  }, [useWrapUrl]);

  // 이용약관
  const movePolicyUsage = useMemo(() => {
    if (isAppWeb()) {
      return appScheme(APP_SCHEME.TERM_USE_AGREEMENT);
    }

    if (useWrapUrl) {
      return wrapBaseURL('/policy/usage');
    }

    return '/policy/usage';
  }, [useWrapUrl]);

  // 고객센터
  const moveHelp = useMemo(() => {
    if (useWrapUrl) {
      return wrapBaseURL('/help/main');
    }
    return '/help/main';
  }, [useWrapUrl]);

  // 티켓판매안내
  const movePartner = useMemo(() => {
    if (useWrapUrl) {
      return wrapBaseURL('/help/partner');
    }
    return '/help/partner';
  }, [useWrapUrl]);

  // 광고안내
  const moveAd = useMemo(() => {
    if (useWrapUrl) {
      return wrapBaseURL('/advertisement');
    }
    return '/advertisement';
  }, [useWrapUrl]);

  // 사업자정보
  const moveBusinessInformation = useMemo(() => {
    if (isAppWeb()) {
      return appScheme(APP_SCHEME.TERM_BUSINESS_INFORMATION);
    }
    return 'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1448125090';
  }, []);

  return {
    moveCompany,
    movePersonalInformation,
    moveYouth,
    movePolicyUsage,
    moveHelp,
    movePartner,
    moveAd,
    moveBusinessInformation
  };
};

export default useFooterAnchor;
