const MODAL_KEY = {
  REVIEW_RULE: 'reviewRule',
  REVEIW_PRIVACY: 'reviewPrivacy',
  SEARCH_TOAST: 'searchToast',
  SPORTS_EVENT: 'sportsEvent',
  NO_MATCH_TOAST: 'noMatchToast',
  WAITING_RESERVE: 'waitingReserve',
  MAIN_FLASH: 'mainFlash',
  MAIN_POPUP: 'mainPopup',
  PRODUCT_NOTICE: 'productNotice',
  SCHEDULE_NOTICE: 'scheduleNotice',
  DEVICE_AUTH_NOTICE: 'deviceAuthNotice',
  MOBILE_TICKET_INSTRUCTIONS: 'mobileTicketInstructions', // 모바일 티켓 이용 안내
  MOBILE_TICKET_VERIFICATION: 'mobileTicketVerification', // 모바일 티켓 직원 확인
  MOBILE_TICKET_BARCODE: 'mobileTicketBarcode', // 모바일 티켓 바코드 팝업
  PLANNING_AGREEMENT: 'planningAgreement', // 기획전 약관 팝업
  PLANNING_COUPON_ACTION_SHEET: 'planningCouponActionSheet', // 기획전 쿠폰 액션 시트
  PLANNING_LIST_ACTION_SHEET: 'planningListActionSheet', // 기획전 목록 액션 시트
  MOBILE_TICKET_MD_VERIFICATION: 'mobileTicketMdVerification' // 모바일 티켓 MD수령 직원 확인
} as const;

export { MODAL_KEY };
