import { useTranslation } from 'react-i18next';
import { FullScreenModalMobile } from '@tkl-packages/components/src/ticketlink/Modal';
import { WAITING_RESEVE_CLOSE } from '@tkl-packages/constants/product';
import { WaitingReservation } from '@tkl-packages/models';

interface props {
  info: WaitingReservation;
  onClose: () => void;
}

const WaitingReserveModalMobile = ({ info, onClose }: props) => {
  const { t } = useTranslation();

  return (
    <FullScreenModalMobile title={t('취소표대기 서비스 안내')} onClose={onClose}>
      <div className="waiting_guide">
        <h4 className="popup_title">{t('취소표대기 서비스란')}</h4>
        <p className="popup_desc">
          {t('이미 판매된 좌석이 취소되면 대기 순번에 따라 먼저 예매할 수 있는 기회를 제공하는 서비스입니다.')}
        </p>
        <p className="popup_desc">
          {t('취소표대기를 신청한 좌석 또는 구역 안에서 예매건이 취소되면 1순위 대기자에게 안내 드립니다.')}
          <br />
          {t('알림 후 6시간 동안 고객님께 좌석이 선점되며, 대기 취소 시 이용료는 전액 환불됩니다.')}
        </p>
        <div className="popup_table type2">
          <table>
            <caption>
              <span>{t('부가상품 수령 안내 및 유의사항')}</span>
            </caption>
            <colgroup>
              <col style={{ width: '45.625%' }} />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">{t('취소표대기 신청 시작일')}</th>
                <td>
                  {t('예매오픈 <<startDayAfterReservationOpen>>일 후 <<startTime>>', {
                    startDayAfterReservationOpen: info.startDayAfterReservationOpen,
                    startTime: info.startTime
                  })}
                </td>
                {/*<td>{`${t('예매오픈')} ${info.startDayAfterReservationOpen}일 후 ${info.startTime}`}</td>*/}
              </tr>
              <tr>
                <th scope="row">{t('취소표대기 신청 종료일')}</th>
                <td>
                  {t('<<label>> <<endDaysBeforeReservationClose>>일 전 <<endTime>>', {
                    label: info.serviceCloseType === WAITING_RESEVE_CLOSE.PRODUCT_DATE ? t('관람일') : t('예매마감일'),
                    endDaysBeforeReservationClose: info.endDaysBeforeReservationClose,
                    endTime: info.endTime
                  })}
                </td>
                {/*<td>*/}
                {/*  {`${info.serviceCloseType === WAITING_RESEVE_CLOSE.PRODUCT_DATE ? '관람일' : '예매마감일'} ${*/}
                {/*    info.endDaysBeforeReservationClose*/}
                {/*  }일 전 ${info.endTime}`}*/}
                {/*</td>*/}
              </tr>
            </tbody>
          </table>
        </div>
        <p className="popup_caption">{t('*상품, 회차별로 시작/종료일은 다르게 적용될 수 있습니다.')}</p>
      </div>
    </FullScreenModalMobile>
  );
};

export default WaitingReserveModalMobile;
