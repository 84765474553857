import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { product2ShareParams } from '@tkl-apps/ticketlink/src/hooks/product/convertShareParams';
import ShareIconMobile from '@tkl-packages/components/src/ticketlink/Share/Icon.mobile';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { SHARE_TYPES } from '@tkl-packages/constants/share';
import { getProductSummaryApi } from '@tkl-packages/services/src/mapi/productApi';

interface props {
  onClose: () => void;
}

const ProductShareModalMobile = ({ onClose }: props) => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const { data: product, isFetchedAfterMount } = useQuery({
    queryKey: [QUERY_KEY.PRODUCT_SUMMARY, productId],
    queryFn: () => getProductSummaryApi(productId!).then((res) => res?.data)
  });

  const params = product2ShareParams(product);

  return (
    <div className="ly_pop_wrap">
      <div className="dim" onClick={onClose}></div>
      <div className="layer ly_share">
        <h2 className="ly_tit">{t('공유하기')}</h2>
        <div className="ly_cont">
          <div className="share_bx">
            <ul className="share_list">
              {isFetchedAfterMount &&
                SHARE_TYPES.map((type) => <ShareIconMobile key={type.code} type={type} params={params} />)}
            </ul>
          </div>
        </div>
        <button type="button" className="btn_close" onClick={onClose}>
          <span className="sp">{t('닫기')}</span>
        </button>
      </div>
    </div>
  );
};

export default ProductShareModalMobile;
