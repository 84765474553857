import { ShareParams } from '@tkl-apps/ticketlink/src/hooks/product/convertShareParams';
import {
  shareOnFacebook,
  shareOnBand,
  shareOnClipboard,
  shareOnKakao,
  shareOnLine,
  shareOnTwitter
} from '@tkl-apps/ticketlink/src/hooks/product/share';
import { SHARE_TYPE } from '@tkl-packages/constants/share';
import { Share } from '@tkl-packages/models';

const useShare = (type: Share, params: ShareParams) => {
  const onShare = () => {
    if (type === SHARE_TYPE.FACEBOOK) {
      shareOnFacebook(params);
      return;
    }

    if (type === SHARE_TYPE.KAKAO) {
      shareOnKakao(params);
      return;
    }

    if (type === SHARE_TYPE.LINE) {
      shareOnLine(params);
      return;
    }

    if (type === SHARE_TYPE.BAND) {
      shareOnBand(params);
      return;
    }

    if (type === SHARE_TYPE.TWITTER) {
      shareOnTwitter(params);
      return;
    }

    shareOnClipboard();
  };

  return { onShare };
};

export default useShare;
