import { memo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useGetFirstPlanningUrl } from '@tkl-apps/ticketlink/src/hooks/planning/useGetFirstPlanningUrl';
import { useGetSpeechBubblers } from '@tkl-apps/ticketlink/src/hooks/planning/useGetSpeechBubblers';
import { isSports } from '@tkl-packages/sdk/src/validations';
import isEmpty from 'lodash/isEmpty';

const Bubble = () => {
  const bubble = useGetSpeechBubblers();

  return (
    <span className="exhibit_badge">
      <Link to={bubble?.isIntegration ? `/planning-event/${bubble?.planningEventId}` : '#'} className="link">
        {bubble?.content}
      </Link>
    </span>
  );
};

const PlanningLi = () => {
  const location = useLocation();
  const { pathname } = location;
  const { data: firstPlanningUrl } = useGetFirstPlanningUrl(!isSports());
  const getAriaCurrent = useCallback(
    (path: string) => {
      return pathname.indexOf(path) > -1 ? 'page' : false;
    },
    [pathname]
  );
  const bubble = useGetSpeechBubblers();

  return (
    <li className="header_gnb_item">
      <div className="header_badge_wrap">
        <Link
          to={firstPlanningUrl || '/planning-event'}
          className="header_gnb_link"
          aria-current={getAriaCurrent('/planning-event')}
        >
          기획전
        </Link>
        {!isEmpty(bubble) && <Bubble />}
      </div>
    </li>
  );
};

export default memo(PlanningLi);
