import { ReactNode } from 'react';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperProps } from 'swiper/react';

interface props extends eventProps {
  children: ReactNode;
  className?: string;
  wrapperTag?: string;
  navigation?: SwiperProps['navigation'];
  pagination?: SwiperProps['pagination'];
  allowTouchMove?: boolean;
  effect?: SwiperProps['effect'];
  autoplay?: boolean;
  delay?: number;
  onSwiper?: SwiperProps['onSwiper'];
  onSlideChange?: SwiperProps['onSlideChange'];
  onSlideChangeTransitionStart?: SwiperProps['onSlideChangeTransitionStart'];
  onSlideChangeTransitionEnd?: SwiperProps['onSlideChangeTransitionEnd'];
  slidesPerView?: SwiperProps['slidesPerView'];
  slidesPerGroup?: SwiperProps['slidesPerGroup'];
  spaceBetween?: SwiperProps['spaceBetween'];
  loop?: boolean;
}

interface eventProps {
  onSwiper?: SwiperProps['onSwiper'];
  onSlideChange?: SwiperProps['onSlideChange'];
  onSlideChangeTransitionStart?: SwiperProps['onSlideChangeTransitionStart'];
  onSlideChangeTransitionEnd?: SwiperProps['onSlideChangeTransitionEnd'];
}

const SwiperWrapper = ({
  children,
  className,
  wrapperTag = 'ul',
  navigation = false,
  pagination = false,
  allowTouchMove = true,
  effect,
  autoplay = true,
  delay = 3000,
  onSwiper,
  onSlideChange,
  onSlideChangeTransitionStart,
  onSlideChangeTransitionEnd,
  slidesPerView = 'auto',
  slidesPerGroup = 1,
  spaceBetween = 0,
  loop = true
}: props): JSX.Element => {
  const eventProps: eventProps = {};
  if (onSwiper) {
    eventProps.onSwiper = onSwiper;
  }

  if (onSlideChange) {
    eventProps.onSlideChange = onSlideChange;
  }

  if (onSlideChangeTransitionStart) {
    eventProps.onSlideChangeTransitionStart = onSlideChangeTransitionStart;
  }

  if (onSlideChangeTransitionEnd) {
    eventProps.onSlideChangeTransitionEnd = onSlideChangeTransitionEnd;
  }

  return (
    <Swiper
      effect={effect}
      className={`swiper-container${effect ? `-${effect}` : ' '} ${className || ''}`}
      wrapperTag={wrapperTag}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      modules={[Autoplay, Pagination, Navigation, EffectFade]}
      loop={loop}
      allowTouchMove={allowTouchMove}
      autoplay={autoplay && { delay: delay, disableOnInteraction: false }}
      navigation={navigation}
      pagination={pagination}
      spaceBetween={spaceBetween}
      {...eventProps}
    >
      {children}
    </Swiper>
  );
};

export default SwiperWrapper;
