import { useEffect } from 'react';

const useNoScroll = () => {
  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  });
};

export default useNoScroll;
