import { useContext } from 'react';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import PlanningLi from './PlanningLi';

interface GnbEventListProps {
  isActiveEvent?: boolean;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  isExhibition?: boolean;
}

const GnbEventList = ({ isActiveEvent, onMouseOver, onMouseLeave, isExhibition }: GnbEventListProps) => {
  const { isLoggedIn, goLogin } = useContext(LoginContext);

  const onClickParticipatedEvent = () => {
    if (isLoggedIn) {
      window.location.href = '/my/activity/event';
    } else {
      goLogin();
    }
  };

  return (
    <>
      <li className="header_gnb_item" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <a href="/event/main" className="header_gnb_link">
          이벤트
        </a>
        <div className={`header_lnb ${isActiveEvent ? 'is-active' : false}`}>
          <ul className="header_lnb_list">
            <li className="header_lnb_item">
              <a href="/event/main" className="header_lnb_link">
                진행중인 이벤트
              </a>
            </li>
            <li className="header_lnb_item">
              <a href="/event/winner" className="header_lnb_link">
                당첨자 발표
              </a>
            </li>
            <li className="header_lnb_item">
              <a href="#" className="header_lnb_link" onClick={onClickParticipatedEvent}>
                참여 이벤트
              </a>
            </li>
          </ul>
        </div>
      </li>
      {isExhibition && <PlanningLi />}
      <li className="header_gnb_item">
        <a href="/vip/main" className="header_gnb_link">
          PAYCO VIP ZONE
        </a>
      </li>
    </>
  );
};

export default GnbEventList;
