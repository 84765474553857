export const baseUrl = 'https://www.ticketlink.co.kr';
export const baseMoUrl = 'https://m.ticketlink.co.kr';
export const baseAppUrl = 'https://link-appweb.ticketlink.co.kr';
export const tourUrl = 'https://tour.ticketlink.co.kr';
export const tourMoUrl = 'https://mtour.ticketlink.co.kr';
export const tourAppUrl = 'https://apptour.ticketlink.co.kr';

export const alphaBaseUrl = 'https://new.ticketlink.co.kr';
export const alphaBaseMoUrl = 'https://new-m.ticketlink.co.kr';
export const alphaAppUrl = 'https://new-appweb.ticketlink.co.kr';
export const alphaTourUrl = 'https://dev-tour.ticketlink.co.kr';
export const alphaTourMoUrl = 'https://dev-mtour.ticketlink.co.kr';
export const alphaTourAppUrl = 'https://dev-apptour.ticketlink.co.kr';

export const PROMOTION_BANNER = 'PROMOTION_BANNER';
export const LINE_BANNER = 'LINE_BANNER';

export const MYPAGE_LEFT_MENU = 'MYPAGE_LEFT_MENU';
export const MYPAGE_MY_INFO = 'MYPAGE_MY_INFO';
