import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnEmptyString: false,
    partialBundledLanguages: true,
    ns: [],
    resources: {},
    // debug: true,
    fallbackLng: 'ko', // URL에서 언어를 감지하지 못했을 때 사용할 기본 언어
    supportedLngs: ['ko', 'en', 'ja', 'zh'],
    keySeparator: '.', // '.'를 키의 구분자로 사용합니다.
    nsSeparator: false, // 네임스페이스 구분자 : 사용하지 않음
    interpolation: {
      escapeValue: false, // React는 자동으로 XSS를 방지하므로 escapse가 필요 없음
      prefix: '<<', // 시작 구분자
      suffix: '>>' // 끝 구분자
    },
    detection: {
      order: ['path'], // path에서만 언어를 감지
      lookupFromPathIndex: 1, // URL 경로의 두 번째 부분을 언어로 사용
      // caches: [], // localStorage, cookie 등에 언어를 저장하지 않음
      excludeCacheFor: ['cimode']
    },
    backend: {
      // 리소스 파일의 경로를 지정
      loadPath: ([lng]: string[], [ns]: string[]) => {
        return `${process.env.I18N_URL}${process.env.NODE_ENV === 'development' ? '/dev' : ''}/${lng}/${ns}.json`;
      }
    }
  });

export default i18next;
