import {
  CastSearchRequest,
  GradeRequest,
  MainProduct,
  MobileMainProduct,
  ReviewRequest,
  ScheduleDate
} from '@tkl-packages/models';
import { Response } from '@tkl-packages/sdk/src/axios/axios.type';
import { requests } from '@tkl-packages/sdk/src/axios/mapiInterface';

const getMobileMainProductsApi = (code: MobileMainProduct): Promise<Response> =>
  requests.get('mobileMain', { codeName: code });
const getMainProductsApi = (code: MainProduct): Promise<Response> => requests.get('web/main/home', { codeName: code });
const getPlanCategoriesApi = (): Promise<Response> => requests.get('plan/category');
const getShowProductsApi = (params: { page: number; categoryId: string; locationCode?: string }): Promise<Response> =>
  requests.get('productList/show', params);
const getPlanProductsApi = (params: { page: number; planningId: string }): Promise<Response> =>
  requests.get('plan/product', params);
const getLocalProductsApi = (params: {
  page: number;
  locationCode: string;
  categoryId?: string;
  orderByType: string;
}): Promise<Response> => requests.get('productList/local', params);
const getVenueProductsApi = (venueCode: string): Promise<Response> => requests.get(`web/main/home/venue/${venueCode}`);

const getProductSummaryApi = (productId: string): Promise<Response> => requests.get(`product/${productId}`);
const getProductDetailApi = (productId: string, deviceId?: string): Promise<Response> =>
  requests.get(`product/${productId}/detail?deviceId=${deviceId}`);
const getAdditionalProductApi = (): Promise<Response> => requests.get(`product/additional`);
const getCoordinateApi = (productId: string): Promise<Response> => requests.get(`product/${productId}/coordinate`);
const getReviewApi = ({ productId, type, page }: ReviewRequest): Promise<Response> =>
  requests.get(`product/${productId}/review/list?page=${page}&reviewType=${type}`);
const getReviewInfoApi = ({ productId, type }: ReviewRequest): Promise<Response> =>
  requests.get(`product/${productId}/review/info?reviewType=${type}`);
const deleteReviewApi = ({ productId, reviewId }: ReviewRequest): Promise<Response> =>
  requests.delete(`product/${productId}/review/${reviewId}`);
const addReviewApi = ({ productId, ...params }: ReviewRequest): Promise<Response> =>
  requests.post(`product/${productId}/review`, params);
const modifyReviewApi = ({ productId, reviewId, ...params }: ReviewRequest): Promise<Response> =>
  requests.post(`product/${productId}/review/${reviewId}`, params);
const getCommonInfoApi = (productId: string): Promise<Response> => requests.get(`product/${productId}/common/info`);

const getPlanListApi = (params?: {}): Promise<Response> => requests.get('plans', params);
const getTicketOpenListApi = (): Promise<Response> => requests.get('webMain/category?codeName=CATEGORY_TICKET_OPEN');
const getCategoryProductsApi = (codeName: string): Promise<Response> => requests.get('webMain/category', { codeName });

const getProductDatesApi = (productId: string): Promise<Response<ScheduleDate[]>> =>
  requests.get(`product/${productId}/date`);
const getProductRoundsApi = (productId: string, productDate: string): Promise<Response> =>
  requests.get(`product/${productId}/round?productDate=${productDate}`);
const getProductGradesApi = ({ scheduleId, productClassCode, productId }: GradeRequest): Promise<Response> =>
  requests.get(`schedule/${String(scheduleId)}/grades?productClassCode=${productClassCode}&productId=${productId}`);
const getProductCastsApi = (params: CastSearchRequest): Promise<Response> =>
  requests.get(`product/${params.productId}/cast`, params);
const getBugsProductListApi = (codeName: string): Promise<Response> =>
  requests.get(`webMain/category?codeName=${codeName}`);

const getLocalCategoryProductCountApi = (locationCode: string): Promise<Response> =>
  requests.get(`productList/local/count?locationCode=${locationCode}`);

const getFaceEntranceProductsApi = (): Promise<Response> => requests.get('biometrics/face/reserve/product/list');

export {
  getMobileMainProductsApi,
  getMainProductsApi,
  getShowProductsApi,
  getLocalProductsApi,
  getPlanListApi,
  getTicketOpenListApi,
  getVenueProductsApi,
  getPlanProductsApi,
  getPlanCategoriesApi,
  getProductSummaryApi,
  getProductDetailApi,
  getAdditionalProductApi,
  getCoordinateApi,
  getReviewInfoApi,
  getReviewApi,
  deleteReviewApi,
  addReviewApi,
  modifyReviewApi,
  getCommonInfoApi,
  getCategoryProductsApi,
  getProductDatesApi,
  getProductRoundsApi,
  getProductGradesApi,
  getProductCastsApi,
  getFaceEntranceProductsApi,
  getBugsProductListApi,
  getLocalCategoryProductCountApi
};
