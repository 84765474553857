import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { SpreadCalendarMobile, MatchListMobile } from '@tkl-apps/ticketlink/src/components/Sports';
import useSportsSchedule from '@tkl-apps/ticketlink/src/hooks/sports/useSportsSchedule.mobile';
import { ToastModalMobile } from '@tkl-packages/components/src/ticketlink/Modal';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { MODAL_KEY } from '@tkl-packages/constants/modal';
import { SCHEDULE } from '@tkl-packages/constants/sports';
import useModal from '@tkl-packages/hooks/src/useModal';
import useScroll from '@tkl-packages/hooks/src/useScroll';
import { SportsScheduleDate, ScheduleMatch } from '@tkl-packages/models';
import { isAppWeb, isFacility } from '@tkl-packages/sdk/src/validations';
import { getSchedulesApi, getTodayDateApi } from '@tkl-packages/services/src/mapi/sportsApi';
import dayjs from 'dayjs';

interface props {
  teamId: string;
  facilityCategoryId?: string;
  setIsShowCoupon: (param: boolean) => void;
}

const TeamScheduleMobile = ({ teamId, setIsShowCoupon, facilityCategoryId }: props) => {
  const { open, close } = useModal(MODAL_KEY.NO_MATCH_TOAST);
  const { categoryId } = useParams();
  const [isOnlyHomeTeam, setIsOnlyHomeTeam] = useState(true);
  useEffect(() => setIsOnlyHomeTeam(true), [teamId]);

  const [dateList, setDateList] = useState<SportsScheduleDate[]>(() => {
    return Array.from({ length: 92 }, (v, i) => {
      const date = dayjs().add(i, 'day').tz().format('YYYY/MM/DD');
      return { date: date, isEmpty: true };
    });
  });

  const startDate = dayjs().tz().format('YYYYMMDD');
  const endDate = dayjs().add(3, 'month').tz().format('YYYYMMDD');

  const { data: matchList = [], isSuccess: matchListSuccess } = useQuery({
    queryKey: [QUERY_KEY.MATCH_LIST, teamId, isOnlyHomeTeam],
    queryFn: () =>
      getSchedulesApi({
        categoryId: isFacility() && isAppWeb() ? facilityCategoryId : categoryId,
        teamId,
        startDate,
        endDate
      }).then((res) => {
        setIsShowCoupon(res?.data.couponCount > 0);
        return isOnlyHomeTeam
          ? res?.data.schedules.filter(({ homeTeam }: ScheduleMatch) => homeTeam.teamId.toString() === teamId)
          : res?.data.schedules;
      })
  });

  useEffect(() => {
    if (matchListSuccess) {
      const matchs = matchList.map((m: ScheduleMatch) => dayjs(new Date(m.scheduleDate)).tz().format('YYYY/MM/DD'));
      setDateList((prev) => {
        return prev.map(({ date }) => ({
          date: date,
          isEmpty: matchs.indexOf(date) === -1
        }));
      });
    }
  }, [matchListSuccess, matchList]);

  const { data: today = new Date().getTime() } = useQuery({
    queryKey: [QUERY_KEY.TODAY],
    queryFn: () => getTodayDateApi().then((res) => res?.data),
    staleTime: 150
  });

  const [isSticky, setIsSticky] = useState(false);
  const scheduleRef = useRef<HTMLDivElement>(null);
  const scheduleType = SCHEDULE.TEAM;
  const {
    calendarScrollRef,
    matchScrollRef,
    setMatchComponentRef,
    initMatchComponentRef,
    setCalendarComponentRef,
    onClickDate,
    activeDate,
    setActiveDate
  } = useSportsSchedule({
    type: scheduleType,
    defaultDate: dateList[0]?.date
  });

  const onScroll = () => {
    if (scheduleRef?.current) {
      const { top } = scheduleRef.current.getBoundingClientRect();
      setIsSticky(top <= 52);
    }
  };

  const onClickToday = () => {
    const formattedToday = dayjs(today).tz().format('YYYY/MM/DD');
    const foundDate = dateList.find((date) => date.date === formattedToday);
    if (foundDate && !foundDate?.isEmpty) {
      onClickDate(formattedToday);
    } else {
      open(<ToastModalMobile text="선택한 날짜에 예매 가능한 경기가 없습니다." onClose={close} />);
    }
  };

  useEffect(() => {
    setActiveDate(dateList.find((d) => !d.isEmpty)?.date || dateList[0].date);
  }, [dateList, setActiveDate]);

  useScroll(onScroll);

  return (
    <section className="m_section section_schedule">
      <div className={`team_schedule ${isSticky ? 'is-sticky' : ''}`} ref={scheduleRef}>
        <div className="team_schedle_sticky">
          <div className="section_heading">
            <h3 className="section_title blind">구단 스케줄</h3>
            <button
              type="button"
              className="team_onlyhome_btn"
              aria-pressed={isOnlyHomeTeam}
              onClick={() => setIsOnlyHomeTeam((prev) => !prev)}
            >
              홈 경기만 보기
            </button>
            <button type="button" className="common_btn btn_ghost btn_small" onClick={onClickToday}>
              TODAY
            </button>
          </div>
          <SpreadCalendarMobile
            ref={calendarScrollRef}
            type={scheduleType}
            setRef={setCalendarComponentRef}
            dateList={dateList}
            activeDate={activeDate}
            onClickDate={onClickDate}
          />
        </div>

        {matchList.length > 0 ? (
          <MatchListMobile
            ref={matchScrollRef}
            setRef={setMatchComponentRef}
            initRef={initMatchComponentRef}
            matchList={matchList}
          />
        ) : (
          <div className="match_schedule_none">
            <span className="text">
              선택한 날짜에 예매가능한
              <br />
              경기가 없습니다.
            </span>
          </div>
        )}
      </div>
    </section>
  );
};

export default TeamScheduleMobile;
