import { BottomModalMobile } from '@tkl-packages/components/src/ticketlink/Modal';
import { APP_SCHEME, appScheme } from '@tkl-packages/constants/appScheme';
import { MODAL_KEY } from '@tkl-packages/constants/modal';
import useModal from '@tkl-packages/hooks/src/useModal';
import { Event } from '@tkl-packages/models';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';

interface props {
  categoryId: string;
  events: Event[];
}

const EventsMobile = ({ events, categoryId }: props) => {
  const { open, close } = useModal(MODAL_KEY.SPORTS_EVENT);
  const onClick = () => {
    open(
      <BottomModalMobile title="이벤트 경기" onClose={close} isLarge={true} hasFooter={true}>
        <ul className="bottom_banner_list">
          {events.map(({ eventId, imagePath, title }: Event) => (
            <li className="bottom_banner_item" key={eventId}>
              <div className="bottom_banner_imgbox">
                <a
                  href={
                    isAppWeb()
                      ? appScheme(APP_SCHEME.PLANNING, { type: 'sports', categoryId, planningEventId: eventId })
                      : `/sports/${categoryId}/planning-event/${eventId}`
                  }
                  className="bottom_banner_link event_game_list"
                >
                  <img src={imagePath} alt={title} className="bottom_banner_img" />
                </a>
              </div>
            </li>
          ))}
        </ul>
      </BottomModalMobile>
    );
  };

  return (
    <div className="common_btn_box">
      <button type="button" className="common_btn btn_ghost_secondary btn_medium" onClick={onClick}>
        이벤트 경기
      </button>
    </div>
  );
};

export default EventsMobile;
