import { useState } from 'react';
import { TARGET_TYPE } from '@tkl-packages/constants/common';
import { GnbBanner as GnbBannerModel } from '@tkl-packages/models';

interface props {
  banner: GnbBannerModel;
}

const GnbBanner = ({ banner }: props) => {
  const [isShow, setIsShow] = useState(true);

  if (!isShow) {
    return null;
  }

  return (
    <div className="gnb_banner" style={{ background: banner?.bgColorCode }}>
      <a
        href={banner?.bannerUrl}
        target={banner?.bannerUrlTargetType === TARGET_TYPE.BLANK ? '_blank' : '_self'}
        className="gnb_banner_link"
        rel="noreferrer"
      >
        <img src={banner?.imagePath} alt={banner?.bannerTitle} className="gnb_banner_img" />
      </a>
      <button type="button" className="gnb_banner_close" onClick={() => setIsShow(false)}>
        <span className="common_icon icon_close"></span>
        <span className="blind">배너 닫기</span>
      </button>
    </div>
  );
};

export default GnbBanner;
