import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { PlanMobile } from '../../pages/mobile';
import { SportsMain } from '../../pages/pc';

const PlanRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel mobile={<PlanMobile />} />
          </NormalHeader>
        }
      />
      <Route path={'*'}>
        <Route
          index
          element={
            <NormalHeader>
              <Channel mobile={<PlanMobile />} />
            </NormalHeader>
          }
        />
        <Route
          path=":category"
          element={
            <NormalHeader>
              <Channel pc={<SportsMain />} mobile={<PlanMobile />} />
            </NormalHeader>
          }
        />
        <Route path="*" element={<Navigate to=".." />} />
      </Route>
    </Routes>
  );
};

export default PlanRoute;
