import { FC, PropsWithChildren } from 'react';
import { Footer } from '@tkl-packages/components/src/ticketlink/Footer';
import { Header } from '@tkl-packages/components/src/ticketlink/Header';
import { Modals } from '@tkl-packages/components/src/ticketlink/Modal';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import Cookies from 'js-cookie';
import { BenepiaBanner } from '../Banner';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <LayoutFragment>
      <div className="common_wrap">
        <Header />
        {children}
        <Footer />
      </div>
      <Modals />
    </LayoutFragment>
  );
};

const LayoutFragment: FC<PropsWithChildren> = ({ children }) => {
  const isBenepia = !!Cookies.get(COOKIE_KEY.BENEPIA);

  return (
    <>
      {isBenepia && <BenepiaBanner />}
      {children}
    </>
  );
};

export default Layout;
