import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCategory } from '@tkl-apps/ticketlink/src/stores/categoryStore/categoryStore';
import { link } from '@tkl-apps/ticketlink/src/utils/category';
import { APP_SCHEME, appScheme } from '@tkl-packages/constants/appScheme';
import { TARGET_TYPE } from '@tkl-packages/constants/common';
import { SUB_HEADER_TYPE } from '@tkl-packages/constants/user';
import { GnbCategory } from '@tkl-packages/models';
import { SubHeaderType } from '@tkl-packages/models';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import GnbEventList from './GnbEventList';

interface HeaderGnbShowProps {
  pathname: string;
  isProductDetailPage: (type: SubHeaderType) => 'page' | false;
  isExhibition?: boolean;
}

const HeaderGnbShow = ({ pathname, isProductDetailPage, isExhibition }: HeaderGnbShowProps) => {
  const [activeEvent, setActiveEvent] = useState<SubHeaderType | null>(null);

  const getAriaCurrent = useCallback(
    (path: string) => {
      return pathname.indexOf(path) > -1 ? 'page' : false;
    },
    [pathname]
  );

  const onMouseOver = useCallback(
    (type: SubHeaderType) => {
      setActiveEvent(type);
    },
    [setActiveEvent]
  );

  const onMouseLeave = useCallback(() => {
    setActiveEvent(null);
  }, [setActiveEvent]);

  const { performanceSubCategories, exhibitionSubCategories } = useCategory();

  return (
    <nav className="header_gnb_area">
      <ul className="header_gnb_list">
        {/* <!-- [D] hover, focus 시 .header_gnb_item에 is-active 클래스 추가 --> */}
        <li className="header_gnb_item">
          {/* <!-- [D] 현재 위치하고 있는 페이지 : .header_gnb_link에 aria-current="page" 추가 --> */}
          <Link to="/home" className="header_gnb_link" aria-current={pathname === '/home' ? 'page' : false}>
            홈
          </Link>
        </li>
        <li
          className="header_gnb_item"
          onMouseOver={() => onMouseOver(SUB_HEADER_TYPE.CONCERT)}
          onMouseLeave={onMouseLeave}
        >
          <Link
            to="/performance/10"
            className="header_gnb_link"
            aria-current={getAriaCurrent('/performance') || isProductDetailPage(SUB_HEADER_TYPE.CONCERT)}
          >
            공연
          </Link>
          <div className={`header_lnb ${activeEvent === SUB_HEADER_TYPE.CONCERT ? 'is-active' : ''}`}>
            <ul className="header_lnb_list">
              {performanceSubCategories.map((category: GnbCategory) => {
                const { url, target } = link(category);
                return (
                  <li className="header_lnb_item" key={category.categoryId}>
                    <a
                      href={url}
                      className="header_lnb_link"
                      target={target === TARGET_TYPE.BLANK ? '_blank' : '_self'}
                      rel="noreferrer"
                    >
                      {category.categoryNameKor}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </li>
        <li
          className="header_gnb_item"
          onMouseOver={() => onMouseOver(SUB_HEADER_TYPE.EXHIBITION)}
          onMouseLeave={onMouseLeave}
        >
          <Link
            to="/exhibition/11"
            className="header_gnb_link"
            aria-current={getAriaCurrent('/exhibition') || isProductDetailPage(SUB_HEADER_TYPE.EXHIBITION)}
          >
            전시
          </Link>
          <div className={`header_lnb ${activeEvent === SUB_HEADER_TYPE.EXHIBITION ? 'is-active' : ''}`}>
            <ul className="header_lnb_list">
              {exhibitionSubCategories.map((category: GnbCategory) => {
                const { url, target } = link(category);
                return (
                  <li className="header_lnb_item" key={category.categoryId}>
                    <a
                      href={url}
                      className="header_lnb_link"
                      target={target === TARGET_TYPE.BLANK ? '_blank' : '_self'}
                      rel="noreferrer"
                    >
                      {category.categoryNameKor}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </li>
        <li className="header_gnb_item">
          <a href={isAppWeb() ? appScheme(APP_SCHEME.LINK_ON) : '/linkon/main'} className="header_gnb_link">
            링크ON
          </a>
        </li>
      </ul>
      <ul className="header_gnb_list">
        <li className="header_gnb_item">
          <Link
            to="/ranking"
            state={{ initial: true }}
            className="header_gnb_link"
            aria-current={getAriaCurrent('/ranking')}
          >
            랭킹
          </Link>
        </li>
        <li
          className="header_gnb_item"
          onMouseOver={() => onMouseOver(SUB_HEADER_TYPE.LOCAL)}
          onMouseLeave={onMouseLeave}
        >
          <Link
            to="/local"
            state={{ initial: true }}
            className="header_gnb_link"
            aria-current={getAriaCurrent('/local')}
          >
            지역
          </Link>
          <div className={`header_lnb ${activeEvent === SUB_HEADER_TYPE.LOCAL ? 'is-active' : ''}`}>
            <ul className="header_lnb_list">
              <li className="header_lnb_item">
                <Link to="/local/seoul" state={{ initial: true }} className="header_lnb_link">
                  서울
                </Link>
              </li>
              <li className="header_lnb_item">
                <Link to="/local/gyeonggi" state={{ initial: true }} className="header_lnb_link">
                  경기/인천
                </Link>
              </li>
              <li className="header_lnb_item">
                <Link to="/local/gangwon_choongchung" state={{ initial: true }} className="header_lnb_link">
                  충청/강원
                </Link>
              </li>
              <li className="header_lnb_item">
                <Link to="/local/gyeongbuk" state={{ initial: true }} className="header_lnb_link">
                  대구/경북
                </Link>
              </li>
              <li className="header_lnb_item">
                <Link to="/local/gyeongnam" state={{ initial: true }} className="header_lnb_link">
                  부산/경남
                </Link>
              </li>
              <li className="header_lnb_item">
                <Link to="/local/jeonla" state={{ initial: true }} className="header_lnb_link">
                  광주/전라
                </Link>
              </li>
              <li className="header_lnb_item">
                <Link to="/local/jeju" state={{ initial: true }} className="header_lnb_link">
                  제주
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <GnbEventList
          isActiveEvent={activeEvent === SUB_HEADER_TYPE.EVENT_COUPON}
          onMouseOver={() => onMouseOver(SUB_HEADER_TYPE.EVENT_COUPON)}
          onMouseLeave={onMouseLeave}
          isExhibition={isExhibition}
        />
      </ul>
    </nav>
  );
};

export default HeaderGnbShow;
