import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { MainMobile } from '../../pages/mobile';
import { Main } from '../../pages/pc';

const HomeRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<Main />} mobile={<MainMobile />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default HomeRoute;
