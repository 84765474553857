import { useCallback, useState } from 'react';
import { wrapTourURL } from '@tkl-apps/drtour/src/utils/common';
import GnbEventList from './GnbEventList';

// interface HeaderGnbDrTourProps {
// pathname: string;
// }

const HeaderGnbDrTour = () => {
  const pathname = window.location.pathname;

  const moveTour = useCallback(() => {
    window.location.href = wrapTourURL('/');
  }, []);

  const [isActiveEvent, setIsActiveEvent] = useState<boolean>(false);

  const onMouseOver = useCallback(() => {
    setIsActiveEvent(true);
  }, [setIsActiveEvent]);

  const onMouseLeave = useCallback(() => {
    setIsActiveEvent(false);
  }, [setIsActiveEvent]);

  return (
    <nav className="header_gnb_area">
      <ul className="header_gnb_list">
        <li className="header_gnb_item">
          {/* <!-- [D] 현재 위치하고 있는 페이지 : .header_gnb_link에 aria-current="page" 추가 --> */}
          <a href={'#'} onClick={moveTour} className="header_gnb_link" aria-current={pathname === '/' ? 'page' : false}>
            홈
          </a>
        </li>
        <li className="header_gnb_item">
          <a
            href={wrapTourURL('/list/3470/3475/3481/0/0')}
            className="header_gnb_link"
            aria-current={pathname.includes('/list/3470') ? 'page' : false}
          >
            패키지여행
          </a>
        </li>
        <li className="header_gnb_item">
          <a
            href={wrapTourURL('/list/3527/3578/3579/0/0')}
            className="header_gnb_link"
            aria-current={pathname.includes('/list/3527') ? 'page' : false}
          >
            자유여행
          </a>
        </li>
        <li className="header_gnb_item">
          <a
            href={wrapTourURL('/list/3472/3615/3618/3622/0')}
            className="header_gnb_link"
            aria-current={pathname.includes('/list/3472') ? 'page' : false}
          >
            부산 | 지방
          </a>
        </li>
        <li className="header_gnb_item">
          <a
            href={wrapTourURL('/list/3936/3937/3939/0/0')}
            className="header_gnb_link"
            aria-current={pathname.includes('/list/3936') ? 'page' : false}
          >
            크루즈
          </a>
        </li>
      </ul>
      <ul className="header_gnb_list">
        <GnbEventList
          isActiveEvent={isActiveEvent}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          isExhibition={false}
        />
      </ul>
    </nav>
  );
};

export default HeaderGnbDrTour;
