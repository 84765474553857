import { forwardRef, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ProductContext } from '@tkl-apps/ticketlink/src/components/Context/Product';
import { Calendar, ReserveButton } from '@tkl-apps/ticketlink/src/components/Product';
import payco_banner_src from '@tkl-packages/assets/payco_payment_banner.png';
import { Actor, Grade, Round, ScheduleDate } from '@tkl-packages/models';
import { dateWithTimezone } from '@tkl-packages/sdk/src/utils/date';
import { number } from '@tkl-packages/sdk/src/utils/number';

interface props {
  globalWebUseYn: boolean;
}

const Reserve = forwardRef(({ globalWebUseYn }: props, ref: any) => {
  const { t } = useTranslation();

  const {
    hasNotSchedule,
    isShowAuthFan,
    dates,
    selectedDate,
    setSelectedDate,
    rounds,
    isFetchedRounds,
    selectedRound,
    setSelectedRound,
    grades,
    isFetchedGrades,
    onReserve,
    onGlobalReserve,
    fanclubName,
    onFanClubCertify,
    saleStatus,
    isRestSeatExposure,
    isAuthReinforced
  } = useContext(ProductContext);

  const onClickDate = (date: Date) => {
    setSelectedDate(date);
    setSelectedRound({} as Round);
  };

  return (
    <section className="common_section section_product_reserve">
      <h2 className="blind">상품 예매</h2>
      {hasNotSchedule ? (
        <div className="product_detail_reserve" style={{ display: 'flex' }}>
          <p className="product_reserve_notice">{t('날짜/회차 선택 없이 관람가능합니다.')}</p>
        </div>
      ) : (
        <div className="product_detail_reserve">
          <div className="product_reserve_date">
            <h3 className="product_reserve_heading">
              <span className="product_reserve_step">{t('STEP1')}</span>
              <span className="product_reserve_title">{t('날짜 선택')}</span>
            </h3>
            {selectedDate && (
              <Calendar
                selected={dateWithTimezone(new Date(selectedDate))}
                onChange={onClickDate}
                includeDates={dates.map((date: ScheduleDate) => dateWithTimezone(new Date(date.productDate)))}
              />
            )}
          </div>
          <div className="product_reserve_time">
            <h3 className="product_reserve_heading">
              <span className="product_reserve_step">{t('STEP2')}</span>
              <span className="product_reserve_title">{t('회차 선택')}</span>
            </h3>
            {selectedDate ? (
              <ul className="product_time_list" role="listbox">
                {isFetchedRounds &&
                  rounds.map((round: Round) => (
                    <li
                      className="product_time_item"
                      role="none"
                      key={round.scheduleId}
                      onClick={() => setSelectedRound(round)}
                    >
                      <button
                        type="button"
                        className="product_time_btn"
                        role="option"
                        aria-selected={selectedRound?.scheduleId === round.scheduleId}
                      >
                        {/*TODO: [글로벌개편] startTime, personName 백엔드에서 내려줘야함*/}
                        <span className="product_time">{round.startTime}</span>
                        {round.actors && (
                          <span className="product_time_cast">
                            <span className="product_time_title">{t('출연진')}</span>
                            <span className="product_time_actor">
                              {round.actors.map((actor: Actor) => actor.personName).join(', ')}
                            </span>
                          </span>
                        )}
                      </button>
                    </li>
                  ))}
              </ul>
            ) : (
              <p className="product_time_notice">{t('관람일을 선택해주세요.')}</p>
            )}
          </div>
          <div className="product_reserve_seat">
            <h3 className="product_reserve_heading">
              <span className="product_reserve_title">{t('예매가능좌석')}</span>
            </h3>
            {selectedRound?.scheduleId ? (
              <>
                {isRestSeatExposure ? (
                  <ul className="product_seat_list">
                    {isFetchedRounds &&
                      isFetchedGrades &&
                      grades.map((grade: Grade) => (
                        <li className="product_seat_item" key={grade.gradeId}>
                          <span className="product_seat_title">{grade.name}</span>
                          <span className="product_seat_remain">
                            {grade.remainCnt > 0 ? (
                              <>
                                <span className="product_seat_number">{number(grade.remainCnt)}</span>
                                <span className="product_seat_text">
                                  {/** TODO
                                   i18n을 위한 마크업 구조 변경 필요
                                   **/}
                                  석{/*{grade.remainCnt > 1*/}
                                  {/*  ? t('<<seats>> 석', { seats: grade.remainCnt })*/}
                                  {/*  : t('<<seat>> 석', { seat: grade.remainCnt })}*/}
                                </span>
                              </>
                            ) : (
                              <span className="product_seat_number">{t('매진')}</span>
                            )}
                          </span>
                        </li>
                      ))}
                  </ul>
                ) : (
                  <p className="product_seat_notice">
                    <Trans i18nKey="잔여좌석 서비스를 제공하지 않는<br>공연입니다." />
                  </p>
                )}
              </>
            ) : (
              <p className="product_seat_notice">{t('관람일, 회차 선택 후 확인 가능합니다.')}</p>
            )}
          </div>
        </div>
      )}
      <div className="product_reserve_util" ref={ref}>
        <div className="product_reserve_banner">
          <a href="/event/paycoPromo" target="_blank" className="product_reservebn_link">
            <img src={payco_banner_src} alt="페이코 간편 결제" className="product_reservebn_img" />
          </a>
        </div>
        <div className="product_reserve_btnarea">
          {isShowAuthFan && (
            <>
              <a href="#" className="common_btn btn_ghost btn_xlarge" onClick={onFanClubCertify}>
                {fanclubName} {t('인증')}
              </a>
              <span className="product_reserve_arrow">
                <span className="common_icon icon_arrow_right ty_sm"></span>
              </span>
            </>
          )}

          {globalWebUseYn && (
            <a className="common_btn btn_ghost btn_xlarge" onClick={onGlobalReserve}>
              {t('GLOBAL BOOKING')}
            </a>
          )}

          <ReserveButton
            isAuthReinforced={isAuthReinforced}
            saleStatus={saleStatus}
            className="btn_xlarge"
            onReserve={onReserve}
          />
        </div>
      </div>
    </section>
  );
});

Reserve.displayName = 'Reserve';

export default Reserve;
