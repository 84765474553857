import { MobileBannerCode, BannerCode } from '@tkl-packages/models';
import { Response } from '@tkl-packages/sdk/src/axios/axios.type';
import { requests } from '@tkl-packages/sdk/src/axios/mapiInterface';

const getMobileMainBannersApi = (code: MobileBannerCode): Promise<Response> =>
  requests.get('mobileMain', { codeName: code });
const getBannersApi = (code: MobileBannerCode): Promise<Response> => requests.get('banner', { codeName: code });
const getMainBannersApi = (code: BannerCode): Promise<Response> => requests.get('web/main/home', { codeName: code });
const getCategoryBannersApi = (code: BannerCode): Promise<Response> =>
  requests.get('webMain/category', { codeName: code });

export { getMobileMainBannersApi, getMainBannersApi, getBannersApi, getCategoryBannersApi };
