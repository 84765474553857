import { FC, ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { FacilityMemberCard } from '../../pages/facility';

const MobileRoute: FC<{ ticket: ReactNode }> = ({ ticket }) => {
  return (
    <Routes>
      <Route
        path="member-card"
        element={
          <NormalHeader>
            <Channel facility={<FacilityMemberCard />} />
          </NormalHeader>
        }
      />
      <Route path="ticket" element={<NormalHeader disableGoogleAnalytics>{ticket}</NormalHeader>} />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default MobileRoute;
