const MOBILE_BANNER_CODE = {
  HERO_BANNER: 'MOBILE_MAIN_BIG',
  POPUP_BANNER: 'MOBILE_MAIN_POPUP',
  FLASH_BANNER: 'MOBILE_MAIN_FLASH',
  MIDDLE_FIRST_BANNER: 'MOBILE_MAIN_CENTER_FIRST',
  MIDDLE_SECOND_BANNER: 'MOBILE_MAIN_CENTER_SECOND',
  BOTTOM_BANNER: 'MOBILE_MAIN_BOTTOM',
  SEARCH: 'MOBILE_SEARCH_PAGE',
  PLAN: 'MOBILE_PLAN_EXHIBITION',
  PRODUCT_LIST: 'MOBILE_LIST_CENTER',
  TODAY_RECOMMEND: 'MOBILE_TODAY_RECOMMEND',
  PRODUCT_BANNER_FIRST: 'MOBILE_DETAIL_PAGE_FIRST',
  PRODUCT_BANNER_SECOND: 'MOBILE_DETAIL_PAGE_SECOND',
  BUGS_HERO_BANNER: 'MOBILE_BUGS_LOUNGE_MAIN_BIG'
} as const;

const BANNER_CODE = {
  POPUP_BANNER: 'WEB_MAIN_POPUP',
  HERO_BANNER: 'WEB_UP_BIG_V1',
  PROPMOTION_BANNER: 'WEB_UP_RIGHT_V1',
  WING_BANNER: 'WEB_WING_V1',
  GNB_BANNER: 'WEB_UP_LINE',
  FLASH_BANNER: 'WEB_MAIN_FLASH',
  TODAY_RECOMMEND: 'WEB_COMMON_RECOMMEND_V1',
  TICKET_SPOT: 'WEB_SPOT_V1',
  WEEKLY_SHOW: 'WEB_WEEK_V1',
  VENUE: 'WEB_VENUE_BIG_TOP',
  TICKET_OPEN: 'WEB_TICKET_OPEN',
  PERFORMANCE_BANNER: 'SHOW_UP_LEFT_V1',
  MUSICAL_BANNER: 'MUSICAL_UP_LEFT_V1',
  THEATER_BANNER: 'THEATER_UP_LEFT_V1',
  CONCERT_BANNER: 'CONCERT_UP_LEFT_V1',
  CLASSIC_BANNER: 'CLASSIC_UP_LEFT_V1',
  DAEHAKRO_BANNER: 'DAEHAKRO_UP_LEFT_V1',
  FAMILY_BANNER: 'FAMILY_UP_LEFT_V1',
  EXHIBITION_BANNER: 'DISPLAY_UP_LEFT_V1',
  FESTIVAL_BANNER: 'FESTIVAL_UP_LEFT_V1',
  LEISURE_BANNER: 'LEISURE_UP_LEFT_V1',
  BUGS_LIVE: 'BUGS_LOUNGE_PRODUCT_LIVE',
  BUGS_PREMIUM: 'BUGS_LOUNGE_PRODUCT_PREMIUM',
  BUGS_HERO_BANNER: 'BUGS_LOUNGE_MAIN_TOP',
  NOTFOUND: ''
} as const;

const MOBILE_AD_BANNER_CODE = {
  MOBILE_MAIN_CENTER_FIRST: 'MOBILE_MAIN_CENTER_FIRST',
  MOBILE_MAIN_CENTER_SECOND: 'MOBILE_MAIN_CENTER_SECOND',
  MOBILE_MAIN_BOTTOM: 'MOBILE_MAIN_BOTTOM',
  MOBILE_SEARCH: 'MOBILE_SEARCH'
};

const WEB_VIEW_TARGET_CODES: readonly string[] = ['MOBILE', 'WEB_VIEW', 'CURRENT'];

const TEXT_COLOR = {
  WHITE: '#ffffff',
  BLACK: '#242428'
} as const;

const POPUP_BANNER_CODE = {
  THOW_OR_THREE: 'BANNER_TWO_OR_THREE_TYPE',
  SIX: 'BANNER_SIX_TYPE',
  ONE: 'BANNER_ONE_TYPE'
};

export { MOBILE_BANNER_CODE, BANNER_CODE, MOBILE_AD_BANNER_CODE, WEB_VIEW_TARGET_CODES, TEXT_COLOR, POPUP_BANNER_CODE };
