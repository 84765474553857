import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { DAEHAKRO, EXHIBITION, PERFORMANCE } from '@tkl-packages/constants/category';
import { COOKIE_OPTION, LOCAL_STORAGE_KEY, VISIBLE_VALUE } from '@tkl-packages/constants/user';
import { CategoryComponentType } from '@tkl-packages/models';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import { expireCookieFormat, getDateAfterDays } from '@tkl-packages/sdk/src/utils/date';
import { isAppWeb, isIos, isPc, isWeb } from '@tkl-packages/sdk/src/validations';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

const hasContent = (content: string) => {
  return !!content?.replace(/<p>&nbsp;<\/p>/g, '');
};

const isUpperCategory = (category?: CategoryComponentType) =>
  category?.code === PERFORMANCE || category?.code === EXHIBITION;
const hasLocalCategory = (category?: CategoryComponentType) => category?.code !== DAEHAKRO;

const setTodayViewProductCookie = (productId: string, imgUrl: string) => {
  let todayViewProducts = Cookies.get(COOKIE_KEY.TODAY_VIEW_PRODUCTS) || '';
  if (!todayViewProducts.includes(productId)) {
    todayViewProducts += `${todayViewProducts ? ',' : ''}${productId}|${imgUrl}`;
    Cookies.set(COOKIE_KEY.TODAY_VIEW_PRODUCTS, todayViewProducts, {
      ...COOKIE_OPTION,
      expires: getDateAfterDays(),
      path: '/'
    });
  }
};

function isAbleFraudulentReservationNotice(productId: string) {
  const key = `${LOCAL_STORAGE_KEY.FRAUDULENT_RESERVATION_NOTICE_SHOW}_${productId}`;

  if (isAppWeb() && isIos()) {
    const value = Cookies.get(key);
    return !value ? true : value !== VISIBLE_VALUE.HIDE;
  }

  const data = window.localStorage.getItem(key);
  if (!data) {
    return true;
  }

  const { value, expires } = JSON.parse(data);

  if (Date.now() > expires) {
    window.localStorage.removeItem(key);
    return true;
  }

  return value !== VISIBLE_VALUE.HIDE;
}

function setDisableFraudulentReservationNotice(productId: string) {
  const key = `${LOCAL_STORAGE_KEY.FRAUDULENT_RESERVATION_NOTICE_SHOW}_${productId}`;
  if (isAppWeb() && isIos()) {
    callAppScheme(APP_SCHEME.NOT_SHOW_UP, { key, expire: expireCookieFormat(getDateAfterDays()) });
  } else {
    window.localStorage.setItem(
      key,
      JSON.stringify({
        value: VISIBLE_VALUE.HIDE,
        expires: getDateAfterDays().getTime()
      })
    );
  }
}

const mobileWindowFeatures =
  'toolbar=no, location=no, directories=no, status=no, menubar=no, resizable=no, copyhistory=no';

const pcWindowFeatures =
  'width=990, height=900, toolbar=no, location=no, directories=no, status=no, menubar=no, resizable=no, copyhistory=no';

let reserveWindow: Window | null = null;

const openReservePopup4MobileWeb = ({
  productDate,
  scheduleId,
  productId,
  hasNotSchedule = false
}: {
  productDate: Date | undefined;
  scheduleId?: string | number;
  productId: string;
  hasNotSchedule?: boolean;
}) => {
  const queryObject: any = {};
  if (productDate) {
    queryObject.productDate = dayjs(productDate).tz().format('YYYY.MM.DD');
  }

  if (scheduleId) {
    queryObject.scheduleId = String(scheduleId);
  }
  const query = new URLSearchParams(queryObject).toString();

  reserveWindow?.close();
  reserveWindow = window.open(
    `/reserve/product/${productId}${hasNotSchedule ? '' : `?${query}`}`,
    '예매하기',
    mobileWindowFeatures
  );
};

const openReservePopup4PCWeb = ({
  productDate,
  scheduleId,
  productId,
  hasNotSchedule = false
}: {
  productDate: Date | undefined;
  scheduleId?: string | number;
  productId: string;
  hasNotSchedule?: boolean;
}) => {
  const queryObject: any = {};
  if (productDate) {
    queryObject.productDate = dayjs(productDate).tz().format('YYYY.MM.DD');
  }

  if (scheduleId) {
    queryObject.scheduleId = String(scheduleId);
  }
  const query = new URLSearchParams(queryObject).toString();

  reserveWindow?.close();
  reserveWindow = window.open(
    `/reserve/product/${productId}${hasNotSchedule ? '' : `?${query}`}`,
    '예매하기',
    pcWindowFeatures
  );
};

const openReservePopup = ({
  productDate,
  scheduleId,
  productId,
  hasNotSchedule = false
}: {
  productDate: Date | undefined;
  scheduleId?: string | number;
  productId: string;
  hasNotSchedule?: boolean;
}) => {
  if (isWeb() && isPc()) {
    openReservePopup4PCWeb({
      productDate,
      scheduleId,
      productId,
      hasNotSchedule
    });
    return;
  }
  if (isWeb() && !isPc()) {
    openReservePopup4MobileWeb({
      productDate,
      scheduleId,
      productId,
      hasNotSchedule
    });
  }

  callAppScheme(APP_SCHEME.RESERVE, { productId });
};

const openSportsReservePopup4PCWeb = ({ scheduleId, productId }: { scheduleId: string; productId: string }) => {
  reserveWindow?.close();
  reserveWindow = window.open(`/reserve/product/${productId}?scheduleId=${scheduleId}`, '예매하기', pcWindowFeatures);
};

const openSportsReservePopup4MobileWeb = ({ scheduleId, productId }: { scheduleId: string; productId: string }) => {
  reserveWindow?.close();
  reserveWindow = window.open(
    `/reserve/product/${productId}?scheduleId=${scheduleId}`,
    '예매하기',
    mobileWindowFeatures
  );
};

const openSportsReservePopup = ({ scheduleId, productId }: { scheduleId: string; productId: string }) => {
  if (isWeb()) {
    openSportsReservePopup4PCWeb({
      scheduleId,
      productId
    });
  } else {
    callAppScheme(APP_SCHEME.RESERVE, { scheduleId });
  }
};

const moveToAdult = () => {
  if (confirm('성인인증이 필요한 상품입니다. 성인인증 페이지로 이동하시겠습니까?')) {
    if (isWeb()) {
      window.location.href = '/adult/confirm?nextPage=' + document.URL;
    } else {
      callAppScheme(APP_SCHEME.ADULT);
    }
  }
};

const rearrange = (list: any[]) => {
  return list.reduce((acc, cur, index) => {
    if (index % 2 === 0) {
      acc.push([cur]);
    } else {
      acc[acc.length - 1].push(cur);
    }
    return acc;
  }, []);
};

export {
  hasContent,
  isUpperCategory,
  hasLocalCategory,
  setTodayViewProductCookie,
  openReservePopup,
  moveToAdult,
  openSportsReservePopup,
  rearrange,
  isAbleFraudulentReservationNotice,
  setDisableFraudulentReservationNotice,
  openSportsReservePopup4PCWeb,
  openSportsReservePopup4MobileWeb,
  openReservePopup4MobileWeb
};
