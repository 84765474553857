import { useQuery } from '@tanstack/react-query';
import { hasContent } from '@tkl-apps/ticketlink/src/utils/product';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { getTeamSeatMapApi } from '@tkl-packages/services/src/mapi/sportsApi';

interface props {
  teamId: string;
}

const TeamSeatMapMobile = ({ teamId }: props) => {
  const { data } = useQuery({
    queryKey: [QUERY_KEY.TEAM_SEAT_MAP, teamId],
    queryFn: () => getTeamSeatMapApi(teamId).then((res) => res?.data)
  });

  return (
    <>
      {hasContent(data) ? (
        <div className="team_seat">
          <div className="team_seat_guide" dangerouslySetInnerHTML={{ __html: data }}></div>
        </div>
      ) : (
        <div className="common_data_none">
          <p className="text">좌석도 내용이 없습니다.</p>
        </div>
      )}
    </>
  );
};

export default TeamSeatMapMobile;
