import { ReactNode } from 'react';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';

export interface BaseProps {
  children?: ReactNode;
  to: string;
  isCurrentPath: boolean;
  useNative?: boolean;
}

export type Props = BaseProps;

function LeftSubMenuItem(props: BaseProps) {
  const { children, to, isCurrentPath, useNative } = props;

  return (
    <li className={`${isCurrentPath ? 'on' : ''}`}>
      <TKLink to={to} useNative={useNative}>
        {children}
      </TKLink>
    </li>
  );
}

export default LeftSubMenuItem;
