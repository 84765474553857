import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import PlanningHeader from '../../components/HTMLHeader/PlanningHeader';
import { PlanningMobile } from '../../pages/mobile';
import { Planning } from '../../pages/pc';

const PlanningEventRoute = () => {
  return (
    <Routes>
      <Route
        path=":planningEventId?"
        element={
          <PlanningHeader>
            <Channel pc={<Planning />} mobile={<PlanningMobile />} />
          </PlanningHeader>
        }
      >
        <Route
          path="preview/:previewKey"
          element={
            <PlanningHeader>
              <Channel pc={<Planning />} mobile={<PlanningMobile />} />
            </PlanningHeader>
          }
        />
      </Route>
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default PlanningEventRoute;
