import { Suspense, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import NormalHeader from '@tkl-apps/ticketlink/src/components/HTMLHeader/NormalHeader';
import { NotFoundMobile, RenewalTicketMobile4Sns } from '@tkl-apps/ticketlink/src/pages/mobile';
import { NotFound } from '@tkl-apps/ticketlink/src/pages/pc';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { PageLoading } from '@tkl-packages/components/src/ticketlink/Loading';
import { isPc } from '@tkl-packages/sdk/src/validations';
import AdvertisementRoute from './paths/Advertisement';
import AppRoute from './paths/App';
import BugsRoute from './paths/Bugs';
import CategoryRoute from './paths/Category';
import ExhibitionRoute from './paths/Exhibition';
import HelpRoute from './paths/Help';
import HomeRoute from './paths/Home';
import LocalRoute from './paths/Local';
import LoginRoute from './paths/Login';
import MobileRoute from './paths/Mobile';
import MyRoute from './paths/My';
import PerformanceRoute from './paths/Performance';
import PlanRoute from './paths/Plan';
import PlanningEventRoute from './paths/PlanningEvent';
import PolicyRoute from './paths/Policy';
import ProductRoute from './paths/Product';
import RankingRoute from './paths/Ranking';
import SearchRoute from './paths/Search';
import SportsRoute from './paths/Sports';
import TicketRoute from './paths/Ticket';

const DefaultRoute = () => {
  useEffect(() => {
    if (isPc()) {
      document.querySelector('link[href*="/common.css"]')?.setAttribute('rel', 'stylesheet');
      document.querySelector('link[href*="/ticketlink_renewal.css"]')?.setAttribute('rel', 'stylesheet');
    } else {
      document.querySelector('link[href*="/w.css"]')?.setAttribute('rel', 'stylesheet');
      document.querySelector('link[href*="/m_ticketlink.css"]')?.setAttribute('rel', 'stylesheet');
    }

    return () => {
      if (isPc()) {
        document.querySelector('link[href*="/w.css"]')?.remove();
        document.querySelector('link[href*="/m_ticketlink.css"]')?.remove();
      } else {
        document.querySelector('link[href*="/common.css"]')?.remove();
        document.querySelector('link[href*="/ticketlink_renewal.css"]')?.remove();
      }
    };
  }, []);

  return (
    <Suspense fallback={<PageLoading />}>
      <Routes>
        <Route path="*" element={<Outlet />}>
          <Route index element={<Navigate to="home" />} />
          <Route path="home" element={<HomeRoute />} />
          <Route path="advertisement" element={<AdvertisementRoute />} />
          <Route path="sports/*" element={<SportsRoute />} />
          <Route path="product/*" element={<ProductRoute />} />
          <Route path="performance/*" element={<PerformanceRoute />} />
          <Route path="exhibition/*" element={<ExhibitionRoute />} />
          <Route path="local/*" element={<LocalRoute />} />
          <Route path="ranking" element={<RankingRoute />} />
          <Route path="bugs" element={<BugsRoute />} />
          <Route path="planning-event/*" element={<PlanningEventRoute />} />
          <Route path="plan/*" element={<PlanRoute />} />
          <Route path="my/*" element={<MyRoute />} />
          <Route path="category" element={<CategoryRoute />} />
          <Route path="search/*" element={<SearchRoute />} />
          <Route path="ticket" element={<TicketRoute />} />
          <Route path="help/*" element={<HelpRoute />} />
          <Route path="mobile/*" element={<MobileRoute ticket={<Channel mobile={<RenewalTicketMobile4Sns />} />} />} />
          <Route path="login" element={<LoginRoute />} />
          <Route path="app/*" element={<AppRoute />} />
          <Route path="policy/*" element={<PolicyRoute />} />
          <Route
            path="404"
            element={
              <NormalHeader enableContentsCSS>
                <Channel pc={<NotFound />} mobile={<NotFoundMobile />} />
              </NormalHeader>
            }
          />
          <Route path="*" element={<Navigate to=".." />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default DefaultRoute;
