import { getMobileBannerLinkInfo } from '@tkl-apps/ticketlink/src/utils/banner';
import SwiperWrapper from '@tkl-packages/components/src/ticketlink/Banner/SwiperWrapper';
import { Banner } from '@tkl-packages/models';
import { SwiperSlide } from 'swiper/react';

interface props {
  banners: Banner[];
}

const SportsBannerMobile = ({ banners }: props) => {
  return (
    <SwiperWrapper className="team_banner" pagination={true}>
      {banners.map((banner: Banner) => {
        const { url, target } = getMobileBannerLinkInfo({
          appTarget: banner.bannerTargetCode,
          appUrl: banner.bannerAppUrl,
          webTarget: banner.bannerUrlTargetCode,
          webUrl: banner.bannerUrl
        });
        return (
          <SwiperSlide tag="li" key={banner.bannerId}>
            <a href={url} target={target} className="team_banner_link">
              <img src={banner.imagePath} alt={banner.bannerTitle} className="team_banner_img" />
            </a>
          </SwiperSlide>
        );
      })}
    </SwiperWrapper>
  );
};

export default SportsBannerMobile;
