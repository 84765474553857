import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { moveToAdult, openReservePopup, setTodayViewProductCookie } from '@tkl-apps/ticketlink/src/utils/product';
import { Modal } from '@tkl-packages/components/src/ticketlink/Modal';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { MODAL_KEY } from '@tkl-packages/constants/modal';
import { AUTH_REINFORCE_POPUP_EXPOSURE_TYPE, SALE_STATE, WAITING_SALE_STATUS } from '@tkl-packages/constants/product';
import { CERTIFIED_DEVICE_STATUS, VISIBLE_VALUE } from '@tkl-packages/constants/user';
import useModal from '@tkl-packages/hooks/src/useModal';
import {
  CertifiedDeviceStatus,
  Detail,
  Grade,
  ProductGrade,
  ProductNotice,
  Round,
  SaleState,
  ScheduleDate
} from '@tkl-packages/models';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import { isMobile, isPc, isWeb } from '@tkl-packages/sdk/src/validations';
import {
  getProductDatesApi,
  getProductDetailApi,
  getProductGradesApi,
  getProductRoundsApi
} from '@tkl-packages/services/src/mapi/productApi';
import { getTodayDateApi } from '@tkl-packages/services/src/mapi/sportsApi';
import { getAdultAuthStatusApi } from '@tkl-packages/services/src/mapi/userApi';
import { isBefore } from 'date-fns';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { LoginContext } from './Login';

interface props {
  children: React.ReactNode;
  productId: string;
}

interface productContextProps {
  hasNotSchedule: boolean;
  isShowAuthFan: boolean;
  dates: ScheduleDate[];
  selectedDate: Date | undefined;
  setSelectedDate: (date: Date) => void;
  rounds: Round[];
  isFetchedRounds: boolean;
  selectedRound: Round;
  setSelectedRound: (round: Round) => void;
  grades: Grade[];
  isFetchedGrades: boolean;
  onReserve: () => void;
  onGlobalReserve: () => void;
  fanclubName: string;
  onFanClubCertify: () => void;
  saleStatus: SaleState;
  detail: Detail;
  isRestSeatExposure: boolean;
  remainSeatCount: number;
  isNeedAuthAdult: boolean;
  refetchAdultAuthStatus: () => void;
  callNoticePopup: (callback: Function) => void;
  isAuthReinforced: boolean;
  certifiedDeviceStatusCode: null | CertifiedDeviceStatus;
}

const ProductContext = createContext<productContextProps>({
  hasNotSchedule: false,
  isShowAuthFan: false,
  dates: [],
  selectedDate: new Date(),
  setSelectedDate: () => {},
  rounds: [],
  isFetchedRounds: false,
  selectedRound: {} as Round,
  setSelectedRound: () => {},
  grades: [],
  isFetchedGrades: false,
  onReserve: () => {},
  onGlobalReserve: () => {},
  fanclubName: '멤버십',
  onFanClubCertify: () => {},
  saleStatus: SALE_STATE.SALE,
  detail: {} as Detail,
  isRestSeatExposure: true,
  remainSeatCount: 0,
  isNeedAuthAdult: false,
  refetchAdultAuthStatus: () => {},
  callNoticePopup: () => {},
  isAuthReinforced: false,
  certifiedDeviceStatusCode: null
});

const ProductProvider = ({ children, productId }: props) => {
  const isPcMemo = useMemo(() => isPc(), []);
  const isWebMemo = useMemo(() => isWeb(), []);

  const navigate = useNavigate();
  const [hasNotSchedule, setHasNotSchedule] = useState(true);
  const waitingReserveWindow = useRef<Window | null>(null);

  const { open, close } = useModal(MODAL_KEY.PRODUCT_NOTICE);

  const { data: today = new Date().getTime() } = useQuery({
    queryKey: [QUERY_KEY.TODAY],
    queryFn: () => getTodayDateApi().then((res) => res?.data),
    staleTime: 150
  });

  const deviceId = isWebMemo ? '' : Cookies.get(COOKIE_KEY.DEVICE_ID);
  const {
    isFetching,
    isError,
    isSuccess: detailSuccess,
    data: detail
  } = useQuery({
    queryKey: ['product', productId],
    queryFn: () => getProductDetailApi(productId!, deviceId).then((res) => res?.data)
  });

  const showProductNotice = useCallback(
    (notices: ProductNotice[]) => {
      const openProductNotice = () => {
        const notice = notices.shift();

        if (notice?.code && Cookies.get(notice?.code) === VISIBLE_VALUE.HIDE) {
          notices.length > 0 && openProductNotice();
          return;
        }

        notice &&
          open(
            <Modal
              title={notice.title}
              onClose={closeProductNotice}
              buttonName="확인"
              hasHideToday={notice.hasHideToday}
              code={notice.code}
            >
              <div
                className="modal_notice"
                dangerouslySetInnerHTML={{ __html: notice.content!.replace(/\n/g, '<br>') }}
              />
            </Modal>
          );
      };

      const closeProductNotice = () => {
        close();
        notices.length > 0 && openProductNotice();
      };

      openProductNotice();
    },
    [close, open]
  );

  // onSuccess 대체
  useEffect(() => {
    if (detailSuccess) {
      const {
        product: { productClassCode, productTypeCode, alertMessage, authReinforceInfo },
        productImage: { imgUrl }
      } = detail;

      setHasNotSchedule(
        !isPcMemo ||
          (productClassCode === 'EXHIBITION' && productTypeCode === 'SEASON') ||
          productClassCode === 'ADVANCE_TICKET'
      );

      const notices: ProductNotice[] = [];

      if (
        authReinforceInfo &&
        authReinforceInfo.isAuthReinforced &&
        authReinforceInfo.authReinforcePopupDisplayCode !== AUTH_REINFORCE_POPUP_EXPOSURE_TYPE.NONE &&
        detail.certifiedDeviceStatusCode !== CERTIFIED_DEVICE_STATUS.DEVICE_ACCORD &&
        !!authReinforceInfo.authReinforcePopupContent
      ) {
        notices.push({
          title: '기기 인증 안내',
          hasHideToday:
            authReinforceInfo.authReinforcePopupDisplayCode === AUTH_REINFORCE_POPUP_EXPOSURE_TYPE.ONCE_PER_DAY,
          code: `AUTH_REINFORCE_NOTICE_${productId}`,
          content: authReinforceInfo.authReinforcePopupContent
        });
      }

      if (detail.product.alertYn && alertMessage) {
        notices.push({
          title: '예매안내',
          hasHideToday: true,
          code: `PRODUCT_NOTICE_${productId}`,
          content: alertMessage
        });
      }

      notices.length > 0 && showProductNotice(notices);

      // 오늘 본 상품 쿠키 세팅
      setTodayViewProductCookie(productId, imgUrl);
    }
  }, [detailSuccess, detail, productId, isPcMemo, showProductNotice]);

  // onError 대체
  useEffect(() => {
    if (isError) {
      navigate('/404', { replace: true });
    }
  }, [isError, navigate]);

  const { data: adultAuth, refetch: refetchAdultAuthStatus } = useQuery({
    queryKey: [QUERY_KEY.ADULT_AUTH],
    queryFn: () => getAdultAuthStatusApi().then((res) => res?.data),
    enabled: !!detail?.product.adultYn
  });

  const isShowAuthFan =
    detail?.reserveButtonAttributes.fanclubExposure && !detail?.reserveButtonAttributes.authorizedFan;
  const isNeedAuthAdult = detail?.product.adultYn && adultAuth?.adultAuthYn === 'N';
  const fanclubName = detail?.reserveButtonAttributes?.fanclub?.fanclubDisplayName;

  const [selectedDate, setSelectedDate] = useState<Date>();
  const { isSuccess: datesSuccess, data: dates = [] } = useQuery({
    queryKey: [QUERY_KEY.PRODUCT_DATES, productId],
    queryFn: () => getProductDatesApi(productId).then((res) => res?.data),
    enabled: !hasNotSchedule
  });

  // onSuccess 대체
  useEffect(() => {
    if (datesSuccess) {
      if (dates.length > 0) {
        setSelectedDate(new Date(dates[0].productDate));
      } else {
        setSelectedDate(undefined);
        setSelectedRound({} as Round);
      }
    }
  }, [datesSuccess, dates]);

  const [selectedRound, setSelectedRound] = useState<Round>({} as Round);
  const {
    isSuccess: roundsSuccess,
    data: rounds = [],
    isFetchedAfterMount: isFetchedRounds
  } = useQuery({
    queryKey: [QUERY_KEY.PRODUCT_ROUNDS, productId, selectedDate],
    queryFn: () =>
      getProductRoundsApi(productId!, dayjs(selectedDate).tz().format('YYYY.MM.DD')).then((res) => res?.data),
    enabled: !!selectedDate,
    retry: 3
  });

  // onSuccess 대체
  useEffect(() => {
    if (roundsSuccess && rounds.length > 0) {
      setSelectedRound(rounds[0]);
    }
  }, [roundsSuccess, rounds]);

  const [remainSeatCount, setRemainSeatCount] = useState(0);
  const {
    isSuccess: gradesSuccess,
    data: grades = [],
    isFetchedAfterMount: isFetchedGrades
  } = useQuery({
    queryKey: [QUERY_KEY.PRODUCT_GRADES, productId, selectedRound],
    queryFn: () =>
      getProductGradesApi({
        scheduleId: selectedRound?.scheduleId!,
        productClassCode: detail.product.productClassCode,
        productId: detail.product.productId
      }).then((res) => res?.data),
    enabled: !!selectedDate && !!selectedRound?.scheduleId
  });

  // onSuccess 대체
  useEffect(() => {
    if (gradesSuccess) {
      const newRemainSeatCount = grades.reduce(
        (pre: number, cur: Grade) => (pre += cur.restriction ? 0 : cur.remainCnt),
        0
      );
      setRemainSeatCount(newRemainSeatCount);
    }
  }, [gradesSuccess, grades]);

  const { needLogin } = useContext(LoginContext);
  const onReserve = needLogin(() => {
    let openWaitingReservation = false;
    if (!hasNotSchedule && !selectedRound.scheduleId) {
      alert('회차 정보를 불러오고 있습니다.');
      return;
    }

    if (isPcMemo && !hasNotSchedule && detail.product.restSeatExposureYn === 'Y' && !isFetchedGrades) {
      alert(`선택하신 회차의 등급정보 조회 중 입니다.\n다시 시도해 주세요.`);
      return;
    }

    if (detail.product.restSeatExposureYn === 'Y' && remainSeatCount === 0 && !hasNotSchedule) {
      const { scheduleWaitingReservation } = selectedRound;
      let isWaitingInSale = false;

      if (scheduleWaitingReservation && scheduleWaitingReservation.saleStatus) {
        if (scheduleWaitingReservation.saleStatus === WAITING_SALE_STATUS.INSALE) {
          isWaitingInSale = true;
        }
      }

      if (!isWaitingInSale) {
        alert('선택하신 회차는 매진되었습니다.');
        return;
      } else {
        openWaitingReservation = true;
        if (!confirm('선택하신 회차는 매진되었습니다. 취소표를 신청하시겠습니까?')) {
          return;
        }
      }
    }

    if (detail?.reserveButtonAttributes.alertMessage && !detail?.reserveButtonAttributes.authorizedFan) {
      alert(detail?.reserveButtonAttributes.alertMessage);
      return;
    }

    if (isNeedAuthAdult) {
      moveToAdult();
      return;
    }

    if (openWaitingReservation) {
      openWaitingReservePopup();
      return;
    }

    callNoticePopup(callReservePopup);

    if (!isPcMemo) {
      localStorage.removeItem('selected_date');
      localStorage.removeItem('selected_scheduleId');
    }
  });

  const callNoticePopup = (callback: Function) => {
    // 예매취소마감시간 팝업
    const noticeList: string[] = [];
    let reserveCancelCloseDate = selectedRound?.reserveCancelCloseDate;
    if (detail.reserveCancelCloseDate) {
      reserveCancelCloseDate = detail.reserveCancelCloseDate;
    }

    if (reserveCancelCloseDate) {
      const isBeforeReserveCancelClose = isBefore(new Date(reserveCancelCloseDate), today);
      if (isBeforeReserveCancelClose) {
        const htmlText = `<b><span style="color: red">해당 회차는 예매 후 취소/환불/변경이 불가합니다.</span>
          위 내용에 동의 후 예매를 진행합니다.

          -----------------------------------------
          ※ 불가 사유 예시 ※
          - 관람 전일 오후 5시 이후 (평일/주말/공휴일)
          - 관람 전일 오전 11시 이후 (토요일)
          - 관람 당일

          상품별 취소마감기준은 다르게 적용 될 수 있음
          -----------------------------------------</b>`;
        noticeList.push(htmlText);
      }
    }

    // 상품공지
    // if (detail.product.alertYn && detail.product.alertMessage) {
    //   noticeList.push(detail.product.alertMessage);
    // }

    // 회차별
    if (isPcMemo && selectedRound?.notice) {
      noticeList.push(selectedRound?.notice);
    }

    if (noticeList.length === 0) {
      callback();
    } else {
      const openProductNotice = () =>
        open(
          <Modal title="예매안내" onClose={closeProductNotice} buttonName="확인">
            <div
              className="modal_notice"
              dangerouslySetInnerHTML={{ __html: noticeList.shift()!.replace(/\n/g, '<br>') }}
            />
          </Modal>
        );

      const closeProductNotice = () => {
        close();
        if (noticeList.length > 0) {
          openProductNotice();
        } else {
          callback();
        }
      };

      openProductNotice();
    }
  };

  const callReservePopup = () => {
    openReservePopup({
      productDate: selectedDate,
      scheduleId: selectedRound.scheduleId,
      productId,
      hasNotSchedule
    });
  };

  const openWaitingReservePopup = () => {
    if (isWebMemo) {
      waitingReserveWindow.current?.close();
      const protocol = 'https:';
      const host = `${protocol}//${window.location.host}`;
      waitingReserveWindow.current = window.open(
        `${host}/reserve/waiting/schedule/${selectedRound?.scheduleId}`,
        '예매하기',
        'width=990, height=900,toolbar=no, location=no, directories=no, status=no, menubar=no, resizable=no, copyhistory=no'
      );
    } else {
      callAppScheme(APP_SCHEME.WAITING_RESERVATION);
    }
  };

  const onGlobalReserve = () => {
    window.open(`/global/en/product/${productId}`, 'Reserve');
  };

  const onFanClubCertify = needLogin(() => {
    if (isWebMemo) {
      window.open(
        `/member/fanclub/auth?productId=${productId}`,
        '팬클럽 인증',
        isMobile()
          ? 'toolbar=no, location=no, directories=no, status=no, menubar=no, resizable=no, copyhistory=no'
          : 'width=500, height=500'
      );
    } else {
      callAppScheme(APP_SCHEME.FAN_CLUB, {
        fanClubNo: detail.reserveButtonAttributes.fanclub?.fanclubNo
      });
    }
  });

  /** GA 수집 script */
  useEffect(() => {
    if (detail) {
      let productItems;
      if (detail.productGradeList.length > 0) {
        productItems = detail.productGradeList.map((grade: ProductGrade) => {
          return {
            item_id: detail.product.productId,
            item_name: detail.product.productName,
            price: grade.price,
            grade: grade.productGradeName,
            quantity: '1'
          };
        });
      } else {
        productItems = [
          {
            item_id: detail.product.productId,
            item_name: detail.product.productName,
            quantity: '1'
          }
        ];
      }

      if (!window.dataLayer) {
        window.dataLayer = [];
      }

      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          currency: 'KRW',
          items: productItems
        }
      });
    }
  }, [detail]);

  if (isFetching) {
    return null;
  }

  if (isError) {
    return <Navigate replace to="/" />;
  }

  return (
    <ProductContext.Provider
      value={{
        hasNotSchedule,
        isShowAuthFan,
        dates,
        selectedDate,
        setSelectedDate,
        rounds,
        isFetchedRounds,
        selectedRound,
        setSelectedRound,
        grades,
        isFetchedGrades,
        onReserve,
        onGlobalReserve,
        fanclubName,
        onFanClubCertify,
        saleStatus: detail.reserveButtonAttributes.saleStatus,
        detail,
        isRestSeatExposure: detail.product.restSeatExposureYn === 'Y',
        remainSeatCount,
        isNeedAuthAdult,
        refetchAdultAuthStatus,
        callNoticePopup,
        isAuthReinforced: detail.product.authReinforceInfo?.isAuthReinforced,
        certifiedDeviceStatusCode: detail.certifiedDeviceStatusCode
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export { ProductContext, ProductProvider };
