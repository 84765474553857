import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { SearchMobile, SearchResultMobile } from '../../pages/mobile';

const SearchRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel mobile={<SearchMobile />} />
          </NormalHeader>
        }
      />
      <Route
        path="result"
        element={
          <NormalHeader>
            <Channel mobile={<SearchResultMobile />} />
          </NormalHeader>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default SearchRoute;
