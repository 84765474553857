import UAParser from 'ua-parser-js';

export function isMobile(userAgent: string = navigator.userAgent): boolean {
  const hostName = window.location.hostname.split(/[.-]/);
  const hasMobileHost = hostName.includes('m');

  const parserResult = new UAParser(userAgent).getResult();

  return hasMobileHost || !!parserResult.device.type;
}
