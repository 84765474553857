import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { NotificationMobile, NotificationSettingMobile } from '../../pages/mobile';

const AppRoute = () => {
  return (
    <Routes>
      <Route path={'notification/*'}>
        <Route
          index
          element={
            <NormalHeader>
              <Channel mobile={<NotificationMobile />} />
            </NormalHeader>
          }
        />
        <Route
          path="setting"
          element={
            <NormalHeader>
              <Channel mobile={<NotificationSettingMobile />} />
            </NormalHeader>
          }
        />
        <Route path="*" element={<Navigate to=".." />} />
      </Route>
    </Routes>
  );
};

export default AppRoute;
