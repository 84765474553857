import { FC, PropsWithChildren } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import category from '@tkl-packages/assets/toolbar_category.json';
import home from '@tkl-packages/assets/toolbar_home.json';
import my from '@tkl-packages/assets/toolbar_my.json';
import search from '@tkl-packages/assets/toolbar_search.json';
import ticket from '@tkl-packages/assets/toolbar_ticket.json';
import Lottie from '@tkl-packages/components/src/ticketlink/Navigation/Lottie';
import { isWeb } from '@tkl-packages/sdk/src/validations';

const Navigation: FC<PropsWithChildren<{ isHide: boolean }>> = ({ children, isHide }) => {
  const { pathname } = useLocation();
  const isHome = pathname === '/home' || pathname.indexOf('/sports') === 0;

  return (
    <nav className={`m_toolbar ${isHide ? 'is-hide' : ''}`}>
      {isWeb() && (
        <ul className="toolbar_menu">
          <li className="toolbar_item">
            <NavLink to="/category" className={({ isActive }) => 'toolbar_link ' + (isActive ? 'is-active' : '')}>
              {({ isActive }) => (
                <>
                  <Lottie autoplay={isActive} className="icon_toolbar toolbar_category" animationData={category} />
                  <span className="toolbar_text">카테고리</span>
                </>
              )}
            </NavLink>
          </li>
          <li className="toolbar_item">
            <NavLink to="/search" className={({ isActive }) => 'toolbar_link ' + (isActive ? 'is-active' : '')}>
              {({ isActive }) => (
                <>
                  <Lottie autoplay={isActive} className="icon_toolbar toolbar_search" animationData={search} />
                  <span className="toolbar_text">검색</span>
                </>
              )}
            </NavLink>
          </li>
          <li className="toolbar_item">
            <a href="/" className={`toolbar_link ${isHome ? 'is-active' : ''}`}>
              <Lottie autoplay={isHome} className="icon_toolbar toolbar_home" animationData={home} />
              <span className="toolbar_text">홈</span>
            </a>
          </li>
          <li className="toolbar_item">
            <NavLink to="/ticket" className={({ isActive }) => 'toolbar_link ' + (isActive ? 'is-active' : '')}>
              {({ isActive }) => (
                <>
                  <Lottie autoplay={isActive} className="icon_toolbar toolbar_ticket" animationData={ticket} />
                  <span className="toolbar_text">티켓</span>
                </>
              )}
            </NavLink>
          </li>
          <li className="toolbar_item">
            <NavLink to="/my" className={({ isActive }) => 'toolbar_link ' + (isActive ? 'is-active' : '')}>
              {({ isActive }) => (
                <>
                  <Lottie autoplay={isActive} className="icon_toolbar toolbar_my" animationData={my} />
                  <span className="toolbar_text">마이</span>
                </>
              )}
            </NavLink>
          </li>
        </ul>
      )}
      {children}
    </nav>
  );
};

export default Navigation;
