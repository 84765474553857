import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { Notice } from '@tkl-packages/models';
import { getTeamNoticeApi } from '@tkl-packages/services/src/mapi/sportsApi';
import dayjs from 'dayjs';

interface props {
  teamId: string;
}

const TeamNoticeMobile = ({ teamId }: props) => {
  const { data: notices = [] } = useQuery({
    queryKey: [QUERY_KEY.TEAM_NOTICES, teamId],
    queryFn: () => getTeamNoticeApi(teamId).then((res) => res?.data)
  });

  const [openedNotice, setOpenedNotice] = useState<Partial<Notice>>();
  const toggleOpenedNotice = (notice: Notice) => {
    setOpenedNotice(openedNotice?.noticeId === notice.noticeId ? {} : notice);
  };
  return (
    <>
      {notices.length > 0 ? (
        <div className="team_notice">
          <ul className="popup_notice_list">
            {notices.map((notice: Notice, index: number) => (
              <li
                key={`${notice.noticeId}_${index}`}
                className="popup_notice_item"
                onClick={() => toggleOpenedNotice(notice)}
              >
                <button
                  type="button"
                  className="notice_toggle_btn"
                  aria-expanded={openedNotice?.noticeId === notice.noticeId}
                >
                  <strong className="notice_title">{notice.noticeTitle}</strong>
                  <span className="notice_type">공지</span>
                  <span className="notice_date">{dayjs(notice.noticeDate).tz().format('YYYY.MM.DD')}</span>
                </button>
                <div className="notice_cont">
                  <div dangerouslySetInnerHTML={{ __html: notice.noticeContent }}></div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="common_data_none">
          <p className="text">구단공지 내용이 없습니다.</p>
        </div>
      )}
    </>
  );
};

export default TeamNoticeMobile;
