import { useEffect } from 'react';
import { isAndroid, isAppWeb } from '@tkl-packages/sdk/src/validations';

const useAndroidAppBlockSwipeRefresh = (isShowModal: boolean) => {
  useEffect(() => {
    if (isAppWeb() && isAndroid()) {
      if (isShowModal) {
        document.body.style.overflowY = 'hidden';
        document.body.style.minHeight = 'calc(100vh + 1px)';
        window.scrollBy(0, 1);
      } else {
        document.body.style.overflowY = 'scroll';
        document.body.style.minHeight = '0px';
        window.scrollBy(0, -1);
      }
    }
  }, [isShowModal]);
};

export default useAndroidAppBlockSwipeRefresh;
