import { CSSProperties } from 'react';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import Cookies from 'js-cookie';

const BenepiaBanner = () => {
  const sitename = Cookies.get(COOKIE_KEY.BENEPIA);
  const domain = `https://${sitename}.benepia.co.kr/`;
  const style: CSSProperties = {
    fontSize: '0px',
    lineHeight: '0px'
  };
  return (
    <table style={{ margin: '0px auto', width: '745px' }}>
      <tbody>
        <tr>
          <td height="57">
            <table width="745" style={{ height: '57px' }}>
              <tbody>
                <tr>
                  <td width="360" style={style}>
                    <img src={`${domain}images/img/partner/ticketLink/tab_00.jpg`} width="360" height="57" />
                  </td>
                  <td width="140" style={style}>
                    <a href={`${domain}mypia/pointUseTr.do`} target="_blank" rel="noreferrer">
                      <img src={`${domain}images/img/tab_01.jpg`} width="140" height="57" />
                    </a>
                  </td>
                  <td width="122" style={style}>
                    <a href={`${domain}mypia/main.do`} target="_blank" rel="noreferrer">
                      <img src={`${domain}images/img/tab_02.jpg`} width="122" height="57" />
                    </a>
                  </td>
                  <td width="123" style={style}>
                    <a href={`${domain}main.do`} target="_blank" rel="noreferrer">
                      <img src={`${domain}images/img/tab_03.jpg`} width="123" height="57" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BenepiaBanner;
