import React, { FC, PropsWithChildren } from 'react';
import { Footer } from '@tkl-packages/components/src/ticketlink/Footer';
import Header from '../Header/Header.global';

const LayoutGlobal: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="common_wrap">
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default LayoutGlobal;
