import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { Advertisement } from '../../pages/pc';

const AdvertisementRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader enableContentsCSS>
            <Channel pc={<Advertisement />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default AdvertisementRoute;
