import { createContext, PropsWithChildren } from 'react';
import useLogin from '@tkl-packages/hooks/src/useLogin';

interface loginContextProps {
  isLoggedIn: boolean;
  goLogin: () => void;
  needLogin: (fn: Function) => (...param: any) => void;
  isFetchedAfterMount: boolean;
  isError: boolean;
  refetch: () => void;
}

const LoginContext = createContext<loginContextProps>({
  isLoggedIn: false,
  goLogin: () => {},
  needLogin: () => () => {},
  isFetchedAfterMount: false,
  isError: false,
  refetch: () => {}
});

const LoginProvider = ({ children }: PropsWithChildren) => {
  const { isLoggedIn, goLogin, needLogin, isFetchedAfterMount, isError, refetch } = useLogin();
  return (
    <LoginContext.Provider value={{ isLoggedIn, goLogin, needLogin, isFetchedAfterMount, isError, refetch }}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };
