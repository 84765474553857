import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';

const NormalHeader: FC<
  PropsWithChildren<{ disableGoogleAnalytics?: boolean; enableContentsCSS?: boolean; enablePopupCss?: boolean }>
> = ({ children, disableGoogleAnalytics, enableContentsCSS, enablePopupCss }) => {
  const location = useLocation();

  return (
    <>
      <OgTag />
      <Channel
        pc={
          <Helmet key={location.pathname}>
            {enableContentsCSS && (
              <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/contents.css`} />
            )}
            {enablePopupCss && <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/popup.css`} />}
          </Helmet>
        }
        mobile={<Helmet key={location.pathname}></Helmet>}
      />
      {!disableGoogleAnalytics && <GoogleAnalytics />}
      {children}
    </>
  );
};

export default NormalHeader;
