import { MouseEventHandler } from 'react';
import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { VISIBLE_VALUE } from '@tkl-packages/constants/user';
import { ModalProps } from '@tkl-packages/models';
import { expireCookieFormat, getDateAfterDays } from '@tkl-packages/sdk/src/utils/date';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import Cookies from 'js-cookie';

const BottomModalMobile = ({
  children,
  onClose,
  title,
  hasFooter = false,
  hasNeverSee = false,
  isLarge = false,
  code
}: ModalProps) => {
  if (hasNeverSee && code && Cookies.get(code) === VISIBLE_VALUE.HIDE) {
    return null;
  }
  const setNeverSee: MouseEventHandler = (e) => {
    e.preventDefault();
    if (code) {
      Cookies.set(code, VISIBLE_VALUE.HIDE, { expires: 1 });
    }
    if (isAppWeb()) {
      callAppScheme(APP_SCHEME.NOT_SHOW_UP, { key: code, expire: expireCookieFormat(getDateAfterDays()) });
    }
    onClose();
  };
  return (
    <div className="bottom_sheet_wrap">
      <div className="dimmed" onClick={onClose}></div>
      <div className={`bottom_sheet ${isLarge ? 'type_large' : ''}`} role="dialog" aria-modal="true">
        <div className="bottom_sheet_header">{title && <h3 className="bottom_sheet_title">{title}</h3>}</div>
        <div className="bottom_sheet_content">{children}</div>
        <div className={`bottom_sheet_footer ${hasFooter ? '' : 'blind'}`}>
          {hasNeverSee && (
            <span className="checkbox_item">
              <input
                type="checkbox"
                name="checkbox_group"
                id="check_today_02"
                className="input_checkbox"
                onClick={setNeverSee}
              />
              <label htmlFor="check_today_02" className="label_checkbox">
                다시 보지 않기
              </label>
            </span>
          )}
          <button type="button" className="bottom_sheet_close" onClick={onClose}>
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default BottomModalMobile;
