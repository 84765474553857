import { Layout } from '@tkl-packages/components/src/ticketlink/Layout';
import { HelpMenu, HelpQuickMenu } from '../../../components/Guide';

const Partner = () => {
  return (
    <Layout>
      <main id="container" className="common_container cs_center" role="main">
        <div className="inner">
          <HelpQuickMenu />
          <div className="help_cont">
            <HelpMenu />
            <div className="help_rcont">
              <h3 className="blind">티켓판매안내</h3>
              <div className="partner_logo">
                <span>고객을 부르는 티켓 판매 파트너</span>
              </div>
              <div className="partner_effect">
                <em></em>
                <ul>
                  <li className="pe01">
                    <p>
                      <strong>다양하고 편리한 예매서비스</strong>인터넷, 모바일, 콜센터, 유/무인 예매 채널에서 판매가
                      가능하며 배송, 이벤트, 고객센터를 대행합니다.
                    </p>
                  </li>
                  <li className="pe02">
                    <p>
                      <strong>마케팅 및 고객 분석 서비스 제공</strong>통계 시스템을 활용한 고객 티켓 예매 현황을
                      수집/분석하여 마케팅 솔루션을
                      <br />
                      제공합니다.
                    </p>
                  </li>
                  <li className="pe03">
                    <p>
                      <strong>판매자 보호</strong>티켓링크는 판매자를 보호하며
                      <br />
                      지불을 보장하는 신뢰할 수 있는 티켓팅
                      <br />
                      업체입니다.
                    </p>
                  </li>
                </ul>
              </div>
              <h4 className="partner_text">판매대행 서비스 절차</h4>
              <div className="partner_sales">
                <div className="sales_lst">
                  <ul className="sales_list">
                    <li className="contract">
                      <p className="sales_title">계약</p>
                      <span className="sales_txt">
                        계약서
                        <br />
                        사업자등록증사본
                        <br />
                        통장사본
                      </span>
                    </li>
                    <li className="product">
                      <p className="sales_title">상품등록</p>
                      <span className="sales_txt">
                        상품등록의뢰서
                        <br />
                        수신일로부터
                        <br />
                        3일 이내 등록 완료
                      </span>
                    </li>
                    <li className="sale">
                      <p className="sales_title">판매</p>
                      <span className="sales_txt">
                        티켓링크 내외부
                        <br />
                        채널을 통해
                        <br />
                        노출 및 홍보지원
                      </span>
                    </li>
                    <li className="calculate">
                      <p className="sales_title">정산</p>
                      <span className="sales_txt">
                        상품종료 후<br />
                        10일 이내 정산
                      </span>
                    </li>
                  </ul>
                </div>
                <ul className="salesdetail_lst">
                  <li className="sd1">
                    <h5>
                      <span>판매상담 및 계약</span>
                    </h5>
                    <p>
                      담당자와 상품 판매 대행관련 상담을 합니다. 만약 행사의 취지가 당사와 맞지 않다고 판단될 경우
                      판매가 진행되지 않을 수 있습니다.
                      <br />
                      연간계약과 단일계약 중 선택하여 계약하고 관련 서류 작업을 완료합니다.
                      <br />
                      상담과 계약이 완료되었으면 필요 서류를 접수합니다. 계약서/ 사업자등록증 사본/ 통장사본은 필수로
                      제출하셔야 합니다.
                    </p>
                  </li>
                  <li className="sd2">
                    <h5>
                      <span>상품등록</span>
                    </h5>
                    <p>
                      판매할 상품의 소개 자료 및 판매대행의뢰서를 접수합니다.
                      <br />
                      - 판매대행의뢰서, 상품 포스터, 상품 소개 자료가 필요합니다.
                      <br />
                      - 지정석일 경우 좌석배치도가 필요합니다. (티켓링크에서 판매할 좌석 표기 필수)
                      <br />
                      통상 의뢰서 수신일로부터 영업일 기준 3-4일 이내에 등록이 완료됩니다.
                      <br />
                      단, 연말 등 특수한 경우에는 그 기간이 좀 더 길어질 수 있습니다.
                    </p>
                  </li>
                  <li className="sd3">
                    <h5>
                      <span>판매</span>
                    </h5>
                    <p>
                      등록 작업이 완료되면, 원하는 시점에 맞춰 상품 판매가 시작됩니다. 단, 연말 성수기 등 특정 시점에는
                      협의가 필요할 수 있습니다. 티켓판매는 티켓링크 인터넷 사이트, 공연/전시 앱, 스포츠 앱,
                      고객센터에서 판매됩니다.
                      <br />
                      티켓링크의 다양한 홍보 채널을 통해 상품이 홍보됩니다.
                    </p>
                  </li>
                  <li className="sd4">
                    <h5>
                      <span>정산</span>
                    </h5>
                    <p>
                      상품 종료되면 3일 이내에 정산서를 보내드립니다.
                      <br />
                      7일 이내에 판매수수료를 제외한 정산금액을 입금하여 드립니다.
                    </p>
                  </li>
                </ul>
              </div>
              <h4 className="partner_text v2">티켓판매 문의</h4>
              <div className="partner_step">
                <ul>
                  <li className="category">
                    <span className="category_title">
                      <em className="category_name">공연</em>
                      (뮤지컬/콘서트/연극/클래식/오페라/무용 등)
                    </span>
                    <span className="category_title">
                      <em className="category_name">전시</em>
                      (전시/레포츠/엑스포/축제)
                    </span>
                    <a href="mailto:dl_culture@nhnlink.co.kr;" className="inquiry_mail">
                      dl_culture@nhnlink.co.kr
                    </a>
                  </li>
                  <li className="category">
                    <span className="category_title">
                      <em className="category_name">스포츠</em>
                    </span>
                    <a href="mailto:dl_sportsbiz@nhnlink.co.kr;" className="inquiry_mail">
                      dl_sportsbiz@nhnlink.co.kr
                    </a>
                  </li>
                </ul>
              </div>
              <div className="partner_down">
                <ul>
                  <li className="pdown1">
                    <a
                      href="//tketlink.dn.toastoven.net/web/partner/티켓링크_공연_판매대행_의뢰서_(기획사명).xlsx"
                      className="btn btn_full"
                    >
                      공연 등록의뢰서 다운로드
                    </a>
                  </li>
                  <li className="pdown2">
                    <a
                      href="//tketlink.dn.toastoven.net/ticketlink-cdn/web/partner/티켓링크_전시_판매대행_의뢰서_(업체명).xlsx"
                      className="btn btn_full"
                    >
                      전시 등록의뢰서 다운로드
                    </a>
                  </li>
                  <li className="pdown3">
                    <a
                      href="//tketlink.dn.toastoven.net/ticketlink-cdn/web/partner/티켓링크_스포츠_판매대행_의뢰서_(업체명).xlsx"
                      className="btn btn_full"
                    >
                      스포츠 등록의뢰서 다운로드
                    </a>
                  </li>
                  <li className="pdown4">
                    <a
                      href="//tketlink.dn.toastoven.net/ticketlink-cdn/web/partner/LINK_BI.zip"
                      className="btn btn_full"
                    >
                      티켓링크 BI 다운로드
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Partner;
