import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';

const PlanningHeader: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <OgTag />
      <Helmet key={location.key}>
        <script type="text/javascript" src="https://developers.kakao.com/sdk/js/kakao.min.js"></script>
      </Helmet>
      <GoogleAnalytics />
      {children}
    </>
  );
};

export default PlanningHeader;
