import { APP_SCHEME, appScheme } from '@tkl-packages/constants/appScheme';
import { CategoryListType } from '@tkl-packages/models';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import dayjs from 'dayjs';

const getIsAllOpenSoon = (categoryListData: CategoryListType[], today: string): boolean => {
  return categoryListData?.every((item) => {
    const planningEventStartDate = dayjs(item.planningEventDate.startDate);
    const exposureStartDate = dayjs(item.exposureDate.startDate);
    return planningEventStartDate.isAfter(exposureStartDate) && planningEventStartDate.isAfter(dayjs(today));
  });
};
const getEarliestExposurePlanning = (data?: CategoryListType[]) => {
  if (data?.length === 0) return null; // 데이터가 비어있는 경우 null 반환

  // data 배열을 순회하면서 노출기간 시작일이 가장 빠른 기획전을 찾음
  let earliestPlanning = data?.reduce((earliest, current) => {
    return current.exposureDate.startDate < earliest.exposureDate.startDate ? current : earliest;
  });

  // 노출기간 시작일이 가장 빠른 기획전명과 기획전기간 시작일시를 반환
  return {
    planningEventName: earliestPlanning?.planningEventName,
    planningEventDate: dayjs(earliestPlanning?.planningEventDate?.startDate).format('YYYY.MM.DD.(ddd) HH:mm')
  };
};

const getFirstPlanning = (categoryListData: CategoryListType[]) => {
  const today = new Date().getTime();
  const [firstPlanning] = (categoryListData || []).filter((item) => {
    const exposureStartDate = item.exposureDate.startDate;
    const planningEventStartDate = item.planningEventDate.startDate;
    return exposureStartDate < today && planningEventStartDate < today;
  });

  return firstPlanning;
};

const getFirstExhibitionPlanningUrl = (categoryListData: CategoryListType[]) => {
  const firstPlanningId = getFirstPlanning(categoryListData)?.planningEventId;

  return `/planning-event${firstPlanningId ? `/${firstPlanningId}` : ''}`;
};

const getFirstSportsPlanningUrl = (categoryListData: CategoryListType[], catergoryName: string) => {
  const firstPlanningId = getFirstPlanning(categoryListData)?.planningEventId;

  return `/sports/${catergoryName}/planning-event${firstPlanningId ? `/${firstPlanningId}` : ''}`;
};

/**
 * getBgColor 함수
 * 상단, 하단 배너가 분리형이 아니면 backgroundColor를 리턴
 * @param isSeparateType
 * @param bgColorCode
 */
const getBgColor = (isSeparateType: boolean, bgColorCode?: string) => {
  if (!isSeparateType) return { backgroundColor: bgColorCode };
  return undefined;
};

const getMobileBannerLinkInfo = ({
  appTarget,
  appUrl,
  webTarget,
  webUrl
}: {
  appTarget: 'MOBILE' | 'WEB';
  appUrl: string;
  webTarget: 'DEFAULT' | 'NEW';
  webUrl: string;
}) => {
  return {
    url: isAppWeb()
      ? appScheme(APP_SCHEME.MOVE_BANNER, {
          type: appTarget === 'MOBILE' ? 'WEB_VIEW' : 'WEB_BROWSER',
          linkUrl: appUrl
        })
      : webUrl,
    target: isAppWeb() ? '_self' : webTarget === 'DEFAULT' ? '_self' : '_blank'
  };
};

export {
  getIsAllOpenSoon,
  getEarliestExposurePlanning,
  getFirstPlanning,
  getFirstExhibitionPlanningUrl,
  getFirstSportsPlanningUrl,
  getBgColor,
  getMobileBannerLinkInfo
};
