import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { PerformanceMobile } from '../../pages/mobile';
import { Performance } from '../../pages/pc';

const PerformanceRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<Performance />} mobile={<PerformanceMobile />} />
          </NormalHeader>
        }
      />
      <Route
        path=":category"
        element={
          <NormalHeader>
            <Channel pc={<Performance />} mobile={<PerformanceMobile />} />
          </NormalHeader>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default PerformanceRoute;
