import { forwardRef, memo } from 'react';
import { MatchCardMobile } from '@tkl-apps/ticketlink/src/components/Sports';
import useDeviceAuthStatus from '@tkl-apps/ticketlink/src/hooks/user/useDeviceAuthStatus';
import { ScheduleMatch } from '@tkl-packages/models';

interface Props {
  matchList: ScheduleMatch[];
  setRef: (el: HTMLLIElement, date: string) => void;
  initRef?: () => void;
}

const MatchListMobile = forwardRef<HTMLUListElement, Props>(({ matchList, setRef, initRef }, ref) => {
  initRef && initRef();

  const hasAuthReinforced = matchList.filter((m) => m.authReinforceYn).length > 1;
  const { certifiedDeviceStatusCode } = useDeviceAuthStatus(hasAuthReinforced);

  return (
    <ul className="match_card_list" ref={ref}>
      {matchList.map((match, i) => (
        <li
          className="match_card_item"
          ref={(el: HTMLLIElement) => {
            setRef(el, match.scheduleDate);
          }}
          key={i}
        >
          <MatchCardMobile match={match} certifiedDeviceStatusCode={certifiedDeviceStatusCode} />
        </li>
      ))}
    </ul>
  );
});
MatchListMobile.displayName = 'MatchListMobile';

export default memo(MatchListMobile);
