import { SERVICE_TYPE, ServiceTypeUnion } from '@tkl-packages/constants/services';

export function getCurrentService(): ServiceTypeUnion {
  const { hostname } = location;

  if (hostname.includes('tour')) {
    return SERVICE_TYPE.DRTOUR;
  }
  return SERVICE_TYPE.TICKETLINK;
}
