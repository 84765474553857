import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import { isFacility } from '@tkl-packages/sdk/src/validations';
import Cookies from 'js-cookie';

const GoogleAnalytics = () => {
  const userId = isFacility()
    ? Cookies.get(`${COOKIE_KEY.FACILITY_ID}`)
    : Cookies.get(`${COOKIE_KEY.TKLINK_ID}`)?.replaceAll('"', '');
  const location = useLocation();

  return (
    <Helmet key={location.key}>
      <script>{`var dataLayer = [];dataLayer.push({'user': {'user_id': '${userId || ''}' }});`}</script>
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TBTVJQX');`}
      </script>
    </Helmet>
  );
};

export default GoogleAnalytics;
