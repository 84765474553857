import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import PolicyHeader from '../../components/HTMLHeader/PolicyHeader';
import {
  MarketingPolicyMobile,
  PrivacyFacilityPolicyUsageMobile,
  PrivacyPolicyMobile,
  PrivacyPolicyUsageMobile,
  PromotionalPolicyMobile,
  UsagePolicyMobile,
  YouthPolicyMobile,
  PrivacyFacilityThirdPartyMobile
} from '../../pages/mobile';
import {
  GiftCardPolicy,
  MarketingPolicy,
  NotFound,
  PrivacyFacilityPolicyUsage,
  PrivacyPolicy,
  PrivacyPolicyUsage,
  PromotionalPolicy,
  UsagePolicy,
  YouthPolicy,
  PrivacyFacilityThirdParty
} from '../../pages/pc';

const PolicyRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <NotFound />
          </NormalHeader>
        }
      />
      {/* 시설 개인정보 제 3자 제공 동의 */}
      <Route
        path="facility/thirdParty"
        element={
          <PolicyHeader channel={'mo'}>
            <Channel pc={<PrivacyFacilityThirdParty />} mobile={<PrivacyFacilityThirdPartyMobile />} />
          </PolicyHeader>
        }
      />
      {/* 시설 개인정보 수집/이용동의 */}
      <Route
        path="facility/privacyUsage"
        element={
          <PolicyHeader channel={'mo'}>
            <Channel pc={<PrivacyFacilityPolicyUsage />} mobile={<PrivacyFacilityPolicyUsageMobile />} />
          </PolicyHeader>
        }
      />
      {/* 개인정보 수집/이용동의 */}
      <Route
        path="privacyUsage"
        element={
          <PolicyHeader channel={'mo'}>
            <Channel pc={<PrivacyPolicyUsage />} mobile={<PrivacyPolicyUsageMobile />} />
          </PolicyHeader>
        }
      />
      {/* 개인정보 처리방침 */}
      <Route
        path="privacy"
        element={
          <PolicyHeader>
            <Channel pc={<PrivacyPolicy />} mobile={<PrivacyPolicyMobile />} />
          </PolicyHeader>
        }
      />
      {/* 이용약관 */}
      <Route
        path="usage"
        element={
          <PolicyHeader>
            <Channel pc={<UsagePolicy />} mobile={<UsagePolicyMobile />} />
          </PolicyHeader>
        }
      />
      {/**
       상품권 이용약관
       @desc MO는 아직 필요 없음, PC에만 존재함
       */}
      <Route
        path="gift-card"
        element={
          <PolicyHeader>
            <GiftCardPolicy />
          </PolicyHeader>
        }
      />
      <Route
        path="marketing"
        element={
          <PolicyHeader channel={'mo'}>
            <Channel pc={<MarketingPolicy />} mobile={<MarketingPolicyMobile />} />
          </PolicyHeader>
        }
      />
      <Route
        path="youth"
        element={
          <PolicyHeader>
            <Channel pc={<YouthPolicy />} mobile={<YouthPolicyMobile />} />
          </PolicyHeader>
        }
      />
      <Route
        path="promotional"
        element={
          <PolicyHeader channel={'mo'}>
            <Channel pc={<PromotionalPolicy />} mobile={<PromotionalPolicyMobile />} />
          </PolicyHeader>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default PolicyRoute;
