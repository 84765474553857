import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../../components/HTMLHeader/NormalHeader';
import ESports from '../../../pages/global/ESports';

const ESportsRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<ESports />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default ESportsRoute;
