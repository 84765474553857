import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { isFacility } from '../validations';
import { COOKIE_KEY } from './constants';

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

let baseURL = setTicketlinkBaseUrl();
console.log('baseURL', baseURL);

// https://localhost.ticketlink.co.kr/
// https://www.ticketlink.co.kr/

export function setTicketlinkBaseUrl() {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const isDevServer = process.env.DEV_SERVER === 'true';
  let url = 'https://www.ticketlink.co.kr/';

  // if (isDrTour()) {
  //   if (isDevDrTour()) {
  //     return 'https://new-mapi.ticketlink.co.kr/mapi/';
  //   }
  //   return url;
  // }

  if ((isDevelopment && isDevServer) || typeof window === 'undefined') {
    url = 'https://alpha.ticketlink.co.kr/';
  } else {
    const { hostname } = location;
    const firstHost = hostname.replace('.ticketlink.co.kr', '');
    const phase = firstHost.replace('-m', '').replace('-appweb', '').replace('-facility', '');
    const targets = ['localhost', 'local', 'new', 'alpha', 'alpha2', 'beta', 'beta2', 'stage'];
    if (targets.includes(phase)) {
      url = `https://${firstHost}.ticketlink.co.kr/`;
    }
  }

  return url;
}

// function isDrTour() {
//   if (typeof window === 'undefined') {
//     return false;
//   }
//   const { hostname } = location;
//   return hostname.includes('tour');
// }

// function isDevDrTour() {
//   if (typeof window === 'undefined') {
//     return false;
//   }
//   const { hostname } = location;
//   return hostname.includes('dev-');
// }

const axiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 10000
});

axiosInstance.interceptors.request.use((request) => {
  const token = Cookies.get(isFacility() ? COOKIE_KEY.API_FACILITY_TOKEN : COOKIE_KEY.API_TOKEN);
  if (token && request?.headers) {
    request.headers['x-access-token'] = token;
  }
  return request;
});

const requests = {
  get: <T, D>(url: string, params?: D, timeout?: number) =>
    axiosInstance.get<T>(url, { params, timeout }).then(responseBody),
  post: <T, D>(url: string, body: D, config?: AxiosRequestConfig) =>
    axiosInstance.post<T>(url, body, config).then(responseBody),
  put: <T, D>(url: string, body: D) => axiosInstance.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axiosInstance.delete<T>(url).then(responseBody)
};

export { requests };
