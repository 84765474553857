import {
  alphaAppUrl,
  alphaBaseMoUrl,
  alphaBaseUrl,
  alphaTourAppUrl,
  alphaTourUrl,
  baseAppUrl,
  baseMoUrl,
  baseUrl,
  tourAppUrl,
  tourMoUrl,
  tourUrl
} from '@tkl-apps/drtour/src/utils/constatns';
import { isAppWeb, isMobile } from '@tkl-packages/sdk/src/validations';

export const wrapBaseURL = (url: string): string => {
  if (isAppWeb()) {
    if (window?.tklink?.isDev) {
      return alphaAppUrl + url;
    }
    return baseAppUrl + url;
  }

  if (isMobile()) {
    if (window?.tklink?.isDev) {
      return alphaBaseMoUrl + url;
    }

    return baseMoUrl + url;
  }

  if (window?.tklink?.isDev) {
    return alphaBaseUrl + url;
  }
  return baseUrl + url;
};

export const wrapTourURL = (url?: string): string => {
  if (isAppWeb()) {
    if (window?.tklink?.isDev) {
      return alphaTourAppUrl + url;
    }

    return tourAppUrl + url;
  }

  if (isMobile()) {
    if (window?.tklink?.isDev) {
      return alphaTourAppUrl + url;
    }

    return tourMoUrl + url;
  }

  if (window?.tklink?.isDev) {
    return alphaTourUrl + url;
  }

  return tourUrl + url;
};
