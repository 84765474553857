import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';

const ProductHeader: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <OgTag />
      <Channel
        pc={
          <Helmet key={location.key}>
            <script
              type="text/javascript"
              src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=ebd2punvyx"
            ></script>
            <script type="text/javascript" src="https://developers.kakao.com/sdk/js/kakao.min.js"></script>
          </Helmet>
        }
        mobile={
          <Helmet key={location.key}>
            <script
              type="text/javascript"
              src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=ebd2punvyx"
            ></script>
            <script type="text/javascript" src="https://developers.kakao.com/sdk/js/kakao.min.js"></script>
          </Helmet>
        }
      />
      <GoogleAnalytics />
      {children}
    </>
  );
};

export default ProductHeader;
