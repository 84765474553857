const KAKAO_JAVASCRIPT_KEY = '32a223646444b8ebb807eb2893def9f8';

const SHARE_TYPE = {
  KAKAO: 'KAKAO',
  LINE: 'LINE',
  BAND: 'BAND',
  FACEBOOK: 'FACEBOOK',
  TWITTER: 'TWITTER',
  URL: 'URL'
} as const;

const SHARE_TYPES = [
  {
    code: 'KAKAO',
    name: '카카오',
    class: 'kakao',
    usedAllChannel: false
  },
  {
    code: 'LINE',
    name: '라인',
    class: 'line',
    usedAllChannel: false
  },
  {
    code: 'BAND',
    name: '밴드',
    class: 'band',
    usedAllChannel: false
  },
  {
    code: 'FACEBOOK',
    name: '페이스북',
    class: 'facebook',
    usedAllChannel: true
  },
  {
    code: 'TWITTER',
    name: '트위터',
    class: 'twitter',
    usedAllChannel: true
  },
  {
    code: 'URL',
    name: 'URL복사',
    class: 'url',
    usedAllChannel: true
  }
] as const;

export { KAKAO_JAVASCRIPT_KEY, SHARE_TYPE, SHARE_TYPES };
