import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLanguageChange } from '@tkl-apps/ticketlink/src/routes/Route.global';
import LayoutGlobal from '@tkl-packages/components/src/ticketlink/Layout/Layout.global';

const Main = () => {
  const { t } = useTranslation();
  const onChange = useLanguageChange();
  const { locale } = useParams<{ locale: string }>();

  return (
    <LayoutGlobal>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: '100vh'
        }}
      >
        <h2>{t('글로벌 메인')}</h2>
        <div>
          <select defaultValue={locale} onChange={onChange}>
            <option value="ko">ko</option>
            <option value="en">en</option>
            <option value="ja">ja</option>
            <option value="zh">zh</option>
          </select>
        </div>
      </div>
    </LayoutGlobal>
  );
};

export default Main;
