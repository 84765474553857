import { ReactNode, useContext, useMemo } from 'react';
import { ModalDispatchContext } from '../../../apps/ticketlink/src/components/Context/Modal';

const useModal = (key: string) => {
  const { open, close } = useContext(ModalDispatchContext);

  return useMemo(() => {
    return { open: (Modal: ReactNode) => open({ key, Modal }), close: () => close(key) };
  }, [close, key, open]);
};

export default useModal;
