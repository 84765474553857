import { useLocation } from 'react-router-dom';
import { useQueries } from '@tanstack/react-query';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { isSports } from '@tkl-packages/sdk/src/validations';
import { getTodayDateApi } from '@tkl-packages/services/src/mapi';
import { getSpeechBubblersApi } from '@tkl-packages/services/src/mapi/planning/exhibition/speechBubblerApi';
import dayjs from 'dayjs';

export const useGetSpeechBubblers = () => {
  const { pathname } = useLocation();

  return useQueries({
    queries: [
      {
        queryKey: [QUERY_KEY.TODAY],
        queryFn: () => getTodayDateApi().then((res) => res?.data),
        staleTime: 150
      },
      {
        queryKey: [QUERY_KEY.PLANNING_SPEECH_BUBBLERS],
        queryFn: getSpeechBubblersApi,
        enabled: !isSports(pathname),
        staleTime: 150
      }
    ],
    combine: ([today, bubbles]) => {
      const now: number = today?.data || new Date().getTime();
      const validBubbles =
        bubbles?.data?.filter((bubble) => {
          const startDate = bubble.exposureDate.startDate;
          const endDate = bubble.exposureDate.endDate;
          const is시작됨 = dayjs(now).isAfter(startDate);
          const is안지남 = dayjs(now).isBefore(endDate);

          return is시작됨 && is안지남;
        }) || [];

      const randomIndex = Math.floor(Math.random() * validBubbles.length);

      return validBubbles?.[randomIndex];
    }
  });
};
