import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCategory } from '@tkl-apps/ticketlink/src/stores/categoryStore/categoryStore';
import { link } from '@tkl-apps/ticketlink/src/utils/category';
import { GNB_EXPOSURE_TYPE, TARGET_TYPE } from '@tkl-packages/constants/common';
import { SPORTS_SEASON_SETTING_TYPE } from '@tkl-packages/constants/sports';
import { SUB_HEADER_TYPE } from '@tkl-packages/constants/user';
import { useCategoryId } from '@tkl-packages/hooks/src/useCategoryId';
import { GnbCategory } from '@tkl-packages/models';
import { SubHeaderType } from '@tkl-packages/models';

import isValidUrl from '@tkl-packages/sdk/src/utils/isValidUrl';
import get from 'lodash/get';
import GnbEventList from './GnbEventList';

interface HeaderGnbSportsProps {
  pathname: string;
  isProductDetailPage: (type: SubHeaderType) => 'page' | false;
}

const getAriaCurrent = ({
  url,
  pathname,
  categoryId,
  categoryName
}: {
  url: string;
  pathname: string;
  categoryId: string;
  categoryName: string;
}) => {
  const idInPathName = get(pathname.split('/'), 2);

  if (!categoryId || !idInPathName) {
    return false;
  }

  if (isValidUrl(url) && url.startsWith('/')) {
    return idInPathName === categoryId || idInPathName === categoryName;
  }

  return false;
};

const getMenuClassName = ({ seasonSettingType, gnbExposureTypeCode }: GnbCategory) => {
  if (gnbExposureTypeCode === GNB_EXPOSURE_TYPE.EVENT || seasonSettingType === SPORTS_SEASON_SETTING_TYPE.SEASON) {
    return `header_gnb_link header_gnb_link_season`;
  }

  return 'header_gnb_link';
};

const HeaderGnbSports = ({ pathname, isProductDetailPage }: HeaderGnbSportsProps) => {
  const [isActiveEvent, setIsActiveEvent] = useState<boolean>(false);

  const onMouseOver = useCallback(() => {
    setIsActiveEvent(true);
  }, [setIsActiveEvent]);

  const onMouseLeave = useCallback(() => {
    setIsActiveEvent(false);
  }, [setIsActiveEvent]);

  const { sportsSubCategories } = useCategory();
  useCategoryId();

  return (
    <nav className="header_gnb_area">
      <ul className="header_gnb_list">
        <li className="header_gnb_item">
          {/* <!-- [D] 현재 위치하고 있는 페이지 : .header_gnb_link에 aria-current="page" 추가 --> */}
          <Link to="/sports" className="header_gnb_link" aria-current={pathname === '/sports' ? 'page' : false}>
            홈
          </Link>
        </li>
        {sportsSubCategories.map((category: GnbCategory) => {
          const { url, target } = link(category);

          return (
            <li className="header_gnb_item" key={category.categoryId}>
              <a
                href={url}
                className={getMenuClassName(category)}
                aria-current={
                  getAriaCurrent({
                    pathname,
                    url,
                    categoryId: `${category.categoryId}`,
                    categoryName: `${category.categoryNameEng}`
                  })
                    ? 'page'
                    : false
                }
                target={target === TARGET_TYPE.BLANK ? '_blank' : '_self'}
                rel="noreferrer"
              >
                {category.categoryNameKor}
              </a>
            </li>
          );
        })}
        <li className="header_gnb_item">
          <a
            href="/esports/main"
            className="header_gnb_link"
            aria-current={isProductDetailPage && isProductDetailPage(SUB_HEADER_TYPE.ESPORTS)}
          >
            e스포츠
          </a>
        </li>
      </ul>
      <ul className="header_gnb_list">
        <GnbEventList
          isActiveEvent={isActiveEvent}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          isExhibition={false}
        />
      </ul>
    </nav>
  );
};

export default HeaderGnbSports;
