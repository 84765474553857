import {
  CertifiedDeviceStatus,
  DeviceAuthRequest,
  Home,
  MarketingAgreement,
  PushAgreement,
  WithdrawalResponse
} from '@tkl-packages/models';
import { Response } from '@tkl-packages/sdk/src/axios/axios.type';
import { requests } from '@tkl-packages/sdk/src/axios/mapiInterface';

const getSmartTicketsApi = (deviceId?: string): Promise<Response> => requests.get(`smartTicket?deviceId=${deviceId}`);
const getAdvanceTicketsApi = (params: { page: number; advanceTicketState: string }): Promise<Response> =>
  requests.get('advanceTicket', params);
const addAdvanceTicketApi = (advanceTicketNo: string): Promise<Response> =>
  requests.get(`advanceTicket/submit?advanceTicketNo=${advanceTicketNo}`);
const getAdultAuthStatusApi = (): Promise<Response> => requests.get('adult/auth/check');
const getAuthTokenValidityApi = (): Promise<Response> => requests.get('auth/validate/token');
const getUserInfoApi = (): Promise<Response> => requests.get('my/info');
const getHomeTypeApi = (): Promise<Response> => requests.get('my/homeSetting');
const setHomeTypeApi = (type: Home): Promise<Response> => requests.post(`my/homeSetting?homeSettingType=${type}`, {});
const getWithdrawalApi = (): Promise<Response<WithdrawalResponse>> => requests.get(`my/withdrawal`);
const deleteWithdrawalApi = (): Promise<Response> => requests.delete(`my/withdrawal`);
const getCertifiedIdentityApi = (serviceType: string): Promise<Response> =>
  requests.get(`auth/member/identity?serviceType=${serviceType}`);
const getFaceInfoApi = (): Promise<Response> => requests.get('my/face/ticket/status/info');
const setFaceInfoApi = (data: any): Promise<Response> => requests.post('biometrics/face', data);
const deleteFaceInfoApi = (): Promise<Response> => requests.delete('biometrics/face');
const deleteFaceInfoByScheduleIdApi = (scheduleId: string): Promise<Response> =>
  requests.delete(`biometrics/face/${scheduleId}`);
const setAgreeFaceEntranceTermsApi = (): Promise<Response> =>
  requests.put('biometrics/face/additional/terms/agree', {});
const getAppPushNotificationsApi = (): Promise<Response> => requests.get('push');
const getAppPushAgreementApi = (): Promise<Response> => requests.get('push/agreement');
const updateAppPushAgreementApi = (params: PushAgreement): Promise<Response> => requests.post(`push/agreement`, params);
const setMarketingReceiveAgreementApi = (params: MarketingAgreement): Promise<Response> =>
  requests.post(`my/marketing/receive/agree`, params);
const getDeviceAuthApi = (): Promise<Response> => requests.get('device-auth');
const getDeviceAuthStatusApi = (deviceId: string): Promise<Response<CertifiedDeviceStatus>> =>
  requests.get(`device-auth/status?deviceId=${deviceId}`);
const setDeviceAuthApi = (params: DeviceAuthRequest): Promise<Response> => requests.post('device-auth', params);
const deleteDeviceAuthApi = (): Promise<Response> => requests.delete('device-auth/delete');

export {
  getSmartTicketsApi,
  getAdvanceTicketsApi,
  addAdvanceTicketApi,
  getAuthTokenValidityApi,
  getAdultAuthStatusApi,
  getHomeTypeApi,
  setHomeTypeApi,
  getUserInfoApi,
  getWithdrawalApi,
  deleteWithdrawalApi,
  getCertifiedIdentityApi,
  getFaceInfoApi,
  setFaceInfoApi,
  setAgreeFaceEntranceTermsApi,
  deleteFaceInfoApi,
  deleteFaceInfoByScheduleIdApi,
  getAppPushNotificationsApi,
  getAppPushAgreementApi,
  updateAppPushAgreementApi,
  setMarketingReceiveAgreementApi,
  getDeviceAuthApi,
  getDeviceAuthStatusApi,
  setDeviceAuthApi,
  deleteDeviceAuthApi
};
