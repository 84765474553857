import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../../components/HTMLHeader/NormalHeader';
import Performance from '../../../pages/global/Performance';

const PerformanceRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<Performance />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default PerformanceRoute;
