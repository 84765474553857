import { forwardRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import useProductDetailRoute from '@tkl-apps/ticketlink/src/hooks/product/useProductDetailRoute';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { Product, SearchProduct } from '@tkl-packages/models';
import { isMobile } from '@tkl-packages/sdk/src/validations';
import { getSearchKeywordsApi } from '@tkl-packages/services/src/mapi/searchApi';

interface Props {
  keyword: string;
}

const AutoComplete = forwardRef<any, Props>(({ keyword }, ref?) => {
  const { data: products = [] } = useQuery({
    queryKey: [QUERY_KEY.AUTO_COMPLETE, keyword],
    queryFn: () =>
      getSearchKeywordsApi({
        searchConditionType: 'GENERAL',
        channelCode: 'TKL_WEB',
        coexPageNo: 1,
        link_onPageNo: 1,
        languageType: 'KO',
        query: keyword
      }).then((res) => res?.data?.searchSuggestResults),
    enabled: !!keyword
  });

  if (products.length === 0) return null;

  return isMobile() ? (
    <div className="header_search_layer">
      <div className="dimmed"></div>
      <div className="header_search_suggest">
        <ul className="search_suggest_list">
          {products.map((product: SearchProduct) => (
            <AutoCompleteItem product={product} keyword={keyword} key={product.productId} />
          ))}
        </ul>
      </div>
    </div>
  ) : (
    <div className="header_search_layer" ref={ref}>
      <ul className="search_suggest_list">
        {products.map((product: SearchProduct) => (
          <AutoCompleteItem product={product} keyword={keyword} key={product.productId} />
        ))}
      </ul>
    </div>
  );
});

AutoComplete.displayName = 'AutoComplete';

interface autoCompleteItemProps {
  product: SearchProduct;
  keyword: string;
}

const AutoCompleteItem = ({ product, keyword }: autoCompleteItemProps) => {
  const { goProductDetail } = useProductDetailRoute(product as Product);
  return (
    <li className="search_suggest_item" key={product.productId}>
      <a onClick={goProductDetail} className="search_suggest_link">
        <HighlightKeyword productName={product.productName} keyword={keyword} />
      </a>
    </li>
  );
};

interface keywordProps {
  productName: string;
  keyword: string;
}

const HighlightKeyword = ({ productName, keyword }: keywordProps) => {
  // 공백제거
  const name = productName.replace(/ +/g, '');
  const q = keyword.replace(/ +/g, '');

  // 대소문자 비교
  const sIndex = name.toLowerCase().indexOf(q.toLowerCase());
  const eIndex = sIndex + q.length - 1;

  // highlights
  let result = name.split('');
  result[sIndex] = '<em class="search_suggest_keyword">' + result[sIndex];
  result[eIndex] = result[eIndex] + '</em>';

  // 공백, highlights 포함
  const productNameChars = productName.split('').map(function (text) {
    if (text !== ' ') {
      return result.shift();
    }
    return text;
  });

  return <span className="search_suggest_text" dangerouslySetInnerHTML={{ __html: productNameChars.join('') }}></span>;
};

export default AutoComplete;
