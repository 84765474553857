import { CSSProperties } from 'react';
import loading from '@tkl-packages/assets/loading.json';
import LottieReact from 'lottie-react';

interface Props {
  useBackground?: boolean;
}

const getLoadingWrapStyle = (useBackground: boolean): CSSProperties => {
  if (useBackground) {
    return { backgroundColor: 'white' };
  }

  return {};
};

const PageLoading = ({ useBackground = true }: Props) => {
  return (
    <div className="loading_wrap" style={getLoadingWrapStyle(useBackground)}>
      <LottieReact as="div" className="loading" autoplay loop={false} animationData={loading} />
    </div>
  );
};

export default PageLoading;
