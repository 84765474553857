import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../../components/HTMLHeader/NormalHeader';
import Exhibition from '../../../pages/global/Exhibition';

const ExhibitionRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<Exhibition />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default ExhibitionRoute;
