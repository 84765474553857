import { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import { isFacility } from '@tkl-packages/sdk/src/validations';

const WithLoginOutlet = () => {
  const { isFetchedAfterMount, isLoggedIn, goLogin, refetch } = useContext(LoginContext);
  const { pathname } = useLocation();

  useEffect(() => {
    if (isFetchedAfterMount && !isLoggedIn) {
      alert('로그인이 필요합니다');
      goLogin();
    }
  }, [goLogin, isFetchedAfterMount, isLoggedIn]);

  useEffect(() => {
    refetch();
  }, [pathname, refetch]);

  if (isFacility()) {
    return <></>;
  }

  if (isLoggedIn) {
    return <Outlet />;
  }

  return <></>;
};

export default WithLoginOutlet;
