import { ReactNode } from 'react';

export interface BaseProps {
  title: string;
  children: ReactNode;
}

export type Props = BaseProps;

const QuickMenu = (props: Props) => {
  const { title, children } = props;

  return (
    <div className="quick_menu quick_menu_wrap">
      <div className="title">
        <h2 className="title_txt">{title}</h2>
      </div>
      <ul className="quick_menu_list">{children}</ul>
    </div>
  );
};

export default QuickMenu;
