import { useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import useScroll from '../../../../hooks/src/useScroll';

type ToolbarVisibilityProps = {
  isHide?: boolean;
  setIsHide?: (value: boolean) => void;
};

const useStuckOnTop = ({ isHide = false, setIsHide = () => {} }: ToolbarVisibilityProps) => {
  const [isShowScroll, setIsShowScroll] = useState(false);
  const scrollValueRef = useRef<number>(0);
  const onScroll = throttle(() => {
    let scroll = Math.ceil(window.scrollY);
    let isHideNow = false;
    let isShowScrollNow = scroll > 0;

    if (scroll > scrollValueRef.current && scroll + window.innerHeight < document.body.scrollHeight && scroll > 0) {
      isHideNow = true;
    }

    if (isShowScrollNow !== isShowScroll) {
      setIsShowScroll(isShowScrollNow);
    }

    if (isHide !== isHideNow) {
      setIsHide(isHideNow);
    }

    scrollValueRef.current = scroll;
  }, 200);

  useScroll(onScroll);

  return isShowScroll;
};

export default useStuckOnTop;
