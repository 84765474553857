import { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const defaultClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

const ReactQuery: FC<PropsWithChildren<{ isShowDevTools?: boolean; client?: QueryClient }>> = ({
  children,
  client = defaultClient,
  isShowDevTools = process.env.NODE_ENV === 'development'
} = {}) => {
  return (
    <QueryClientProvider client={client}>
      {children}
      {isShowDevTools && <ReactQueryDevtools initialIsOpen position="top" />}
    </QueryClientProvider>
  );
};

export default ReactQuery;
