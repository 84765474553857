import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { BugsMobile } from '../../pages/mobile';
import { Bugs } from '../../pages/pc';

const BugsRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<Bugs />} mobile={<BugsMobile />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default BugsRoute;
