import { CSSProperties } from 'react';
import { isSports } from '@tkl-packages/sdk/src/validations';
import ReloadButton from './ReloadButton';
import ScrollTopButton from './ScrollTopButton';
import useStuckOnTop from './useStuckOnTop';

interface Props {
  isHide?: boolean;
  setIsHide?: (value: boolean) => void;
  style?: CSSProperties;
}

const ToolBarUtil = (props: Props) => {
  const isShowScroll = useStuckOnTop(props);

  if (isShowScroll) {
    return (
      <div className="toolbar_util" style={props.style}>
        {isSports() && <ReloadButton />}
        <ScrollTopButton />
      </div>
    );
  }

  return;
};

export default ToolBarUtil;
