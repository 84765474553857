import { lazy } from 'react';

const HelpMobile = lazy(() => import(/* webpackChunkName: "help-m" */ './Help/Help'));
const ExhibitionMobile = lazy(() => import(/* webpackChunkName: "exhibition-m" */ './Product/Exhibition'));
const LocalMobile = lazy(() => import(/* webpackChunkName: "local-m" */ './Product/Local'));
const PerformanceMobile = lazy(() => import(/* webpackChunkName: "performance-m" */ './Product/Performance'));
const PlanMobile = lazy(() => import(/* webpackChunkName: "plan-m" */ './Product/Plan'));

const PlanningMobile = lazy(() => import(/* webpackChunkName: "planning-m" */ './Planning/Planning'));

const ProductMobile = lazy(() => import(/* webpackChunkName: "product-m" */ './Product/Product'));
const RankingMobile = lazy(() => import(/* webpackChunkName: "ranking-m" */ './Product/Ranking'));
const BugsMobile = lazy(() => import(/* webpackChunkName: "bugs-m" */ './Product/Bugs'));
const SearchMobile = lazy(() => import(/* webpackChunkName: "search-m" */ './Search/Search'));
const SearchResultMobile = lazy(() => import(/* webpackChunkName: "search-result-m" */ './Search/Result'));
const SportsEventMobile = lazy(() => import(/* webpackChunkName: "sports-event-m" */ './Sports/Event'));
const SportsOffSeasonMobile = lazy(() => import(/* webpackChunkName: "sports-offseason-m" */ './Sports/OffSeason'));
const SportsMainMobile = lazy(() => import(/* webpackChunkName: "sports-main-m" */ './Sports/Main'));
const SportsTeamMainMobile = lazy(() => import(/* webpackChunkName: "sports-team-main-m" */ './Sports/TeamMain'));
const LoginMobile = lazy(() => import(/* webpackChunkName: "login-m" */ './User/Login'));
const MyMobile = lazy(() => import(/* webpackChunkName: "my-m" */ './User/My'));
const CertificationIdentityMobile = lazy(
  () => import(/* webpackChunkName: "certification-identity-m" */ './User/Certification/Identity')
);
const FaceEntranceMobile = lazy(() => import(/* webpackChunkName: "face-entrance-m" */ './User/Face/Entrance'));
const FaceRegisterMobile = lazy(() => import(/* webpackChunkName: "face-register-m" */ './User/Face/Register'));
const FaceTermsMobile = lazy(() => import(/* webpackChunkName: "face-register-m" */ './User/Face/Terms'));
const DeviceAuthMobile = lazy(() => import(/* webpackChunkName: "device-auth-m" */ './User/Device/Auth'));
const DeviceRegisterMobile = lazy(() => import(/* webpackChunkName: "device-register-m" */ './User/Device/Register'));
const TicketMobile = lazy(() => import(/* webpackChunkName: "ticket-m" */ './User/Ticket'));
const AdvanceTicketMobile = lazy(() => import(/* webpackChunkName: "advance-ticket-m" */ './User/AdvanceTicket'));
const CategoryMobile = lazy(() => import(/* webpackChunkName: "category-m" */ './Category'));
const MainMobile = lazy(() => import(/* webpackChunkName: "main-m" */ './Main'));
const NotFoundMobile = lazy(() => import(/* webpackChunkName: "not-found-m" */ './Error/NotFound'));

const PrivacyPolicyMobile = lazy(() => import(/* webpackChunkName: "privacy-policy-m" */ './Policy/PrivacyPolicy'));
const PrivacyPolicyUsageMobile = lazy(
  () => import(/* webpackChunkName: "privacy-info-collect-use-m" */ './Policy/PrivacyPolicyUsage')
);
const PrivacyFacilityPolicyUsageMobile = lazy(
  () => import(/* webpackChunkName: "privacy-facility-policy-usage-m" */ './Policy/PrivacyFacilityPolicyUsage')
);

const PrivacyFacilityThirdPartyMobile = lazy(
  () => import(/* webpackChunkName: "privacy-facility-policy-usage-m" */ './Policy/PrivacyFacilityThirdParty')
);

const UsagePolicyMobile = lazy(() => import(/* webpackChunkName: "usage-policy-m" */ './Policy/UsagePolicy'));
const PromotionalPolicyMobile = lazy(
  () => import(/* webpackChunkName: "promotional-policy-m" */ './Policy/PromotionalPolicy')
);
const MarketingPolicyMobile = lazy(
  () => import(/* webpackChunkName: "marketing-policy-m" */ './Policy/MarketingPolicy')
);
const YouthPolicyMobile = lazy(() => import(/* webpackChunkName: "marketing-policy-m" */ './Policy/YouthPolicy'));
const WithdrawalMobile = lazy(() => import(/* webpackChunkName: "withdrawal-m" */ './User/Withdrawal'));
const NotificationMobile = lazy(() => import(/* webpackChunkName: "notification-m" */ './User/Notification'));
const NotificationSettingMobile = lazy(
  () => import(/* webpackChunkName: "notification-setting-m" */ './User/NotificationSetting')
);
const RenewalTicketMobile4Sns = lazy(
  () => import(/* webpackChunkName: "RenewalTicketMobile" */ './Ticket/Sns/MobileTicket4Sns')
);
const RenewalTicketMobile4Email = lazy(
  () => import(/* webpackChunkName: "RenewalTicketMobile" */ './Ticket/Email/MobileTicket4Email')
);
const GuidePopupSmartTicketMobile = lazy(
  () => import(/* webpackChunkName: "guide-popup-smart-ticket" */ './Help/Guide/Popup/SmartTicket')
);
const GuideWaitingReservationMobile = lazy(
  () => import(/* webpackChunkName: "guide-waiting-reservation-m" */ './Help/Guide/WaitingReservation')
);

export {
  HelpMobile,
  ExhibitionMobile,
  LocalMobile,
  PerformanceMobile,
  PlanMobile,
  ProductMobile,
  BugsMobile,
  RankingMobile,
  SearchMobile,
  SearchResultMobile,
  SportsOffSeasonMobile,
  SportsEventMobile,
  SportsMainMobile,
  SportsTeamMainMobile,
  LoginMobile,
  MyMobile,
  CertificationIdentityMobile,
  DeviceAuthMobile,
  DeviceRegisterMobile,
  FaceEntranceMobile,
  FaceRegisterMobile,
  FaceTermsMobile,
  TicketMobile,
  AdvanceTicketMobile,
  CategoryMobile,
  MainMobile,
  NotFoundMobile,
  PrivacyPolicyMobile,
  PrivacyPolicyUsageMobile,
  PrivacyFacilityPolicyUsageMobile,
  UsagePolicyMobile,
  PromotionalPolicyMobile,
  MarketingPolicyMobile,
  YouthPolicyMobile,
  WithdrawalMobile,
  NotificationMobile,
  NotificationSettingMobile,
  RenewalTicketMobile4Sns,
  RenewalTicketMobile4Email,
  PlanningMobile,
  GuidePopupSmartTicketMobile,
  GuideWaitingReservationMobile,
  PrivacyFacilityThirdPartyMobile
};
