import { isAppWeb, isMobile } from '@tkl-packages/sdk/src/validations';

const tourUrl = 'https://tour.ticketlink.co.kr';
const tourMoUrl = 'https://mtour.ticketlink.co.kr';
const tourAppUrl = 'https://apptour.ticketlink.co.kr';

const alphaTourUrl = 'https://dev-tour.ticketlink.co.kr';
const alphaTourMoUrl = 'https://dev-mtour.ticketlink.co.kr';
const alphaTourAppUrl = 'https://dev-apptour.ticketlink.co.kr';

export const wrapTourURL = (url?: string): string => {
  if (!url) {
    url = '';
  }

  const { hostname } = location;
  const phase = hostname
    .replace('.ticketlink.co.kr', '')
    .replace('-m', '')
    .replace('-appweb', '')
    .replace('-facility', '');
  const targets = ['local', 'new', 'alpha' /* 'beta', 'stage' */];

  let isDev = false;
  if (targets.some((target) => phase.indexOf(target) > -1)) {
    isDev = true;
  }

  if (isAppWeb()) {
    const hostURL = `${isDev ? alphaTourAppUrl : tourAppUrl}`;
    return hostURL + url;
  }

  if (isMobile()) {
    const hostURL = `${isDev ? alphaTourMoUrl : tourMoUrl}`;
    return hostURL + url;
  }

  const hostURL = `${isDev ? alphaTourUrl : tourUrl}`;
  return hostURL + url;
};
