import { createContext, FC, PropsWithChildren, ReactElement, useMemo, useState } from 'react';

const ModalStateContext = createContext<ModalInfo[]>([]);

const ModalDispatchContext = createContext<modalDispatch>({
  open: () => {},
  close: () => {}
});

interface modalDispatch {
  open: ({ ...param }: any) => void;
  close: ({ ...param }: any) => void;
}

interface ModalInfo {
  key: string;
  Modal: ReactElement;
}

const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [openedModals, setOpenedModals] = useState<ModalInfo[]>([]);
  const open = ({ key, Modal }: ModalInfo) => {
    setOpenedModals((modals) => {
      return [...modals.filter((modal) => modal.key !== key), { key, Modal }];
    });
  };
  const close = (key: string) => {
    setOpenedModals((modals) => {
      return modals.filter((modal) => modal.key !== key);
    });
  };

  const dispatch = useMemo(() => ({ open, close }), []);

  return (
    <ModalStateContext.Provider value={openedModals}>
      <ModalDispatchContext.Provider value={dispatch}>{children}</ModalDispatchContext.Provider>
    </ModalStateContext.Provider>
  );
};

export { ModalProvider, ModalStateContext, ModalDispatchContext };
