import { useEffect } from 'react';

const useScroll = (onScroll: (e?: Event) => void) => {
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);
};

export default useScroll;
