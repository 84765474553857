import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import { SearchBox } from '@tkl-apps/ticketlink/src/components/Search';
import { wrapTourURL } from '@tkl-apps/ticketlink/src/utils/drtour';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { BANNER_CODE } from '@tkl-packages/constants/banner';
import { HEADER_TYPE, SESSION_KEY } from '@tkl-packages/constants/user';
import { SubHeaderType } from '@tkl-packages/models';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import { getHeaderBannerApi } from '@tkl-packages/services/src/ad/adApi';
import { getMainBannersApi } from '@tkl-packages/services/src/mapi/bannerApi';
import Cookies from 'js-cookie';
import useOutsideClick from '../../../../hooks/src/useOutsideClick';
import { GnbBanner } from '../Banner';
import HeaderGnbDrTour from './Gnb/HeaderGnbDrTour';
import HeaderGnbShow from './Gnb/HeaderGnbShow';
import HeaderGnbSports from './Gnb/HeaderGnbSports';

const Header = () => {
  const { pathname } = useLocation();
  const { isLoggedIn, goLogin, needLogin } = useContext(LoginContext);
  const isPaycoVIP = Cookies.get(`${COOKIE_KEY.PAYCO_VIP_FLAG}`) === 'true';
  const maskedEmailId = Cookies.get(`${COOKIE_KEY.MASKED_EMAIL_ID}`);

  const { data: gnbBanner } = useQuery({
    queryKey: [QUERY_KEY.GNB_BANNER],
    queryFn: () => getMainBannersApi(BANNER_CODE.GNB_BANNER).then((data) => data?.data[0])
  });

  const { data: adBanner } = useQuery({
    queryKey: [QUERY_KEY.HEADER_AD_BANNER],
    queryFn: () => getHeaderBannerApi().then((data) => data)
  });

  const [isShowLanguage, setIsShowLanguage] = useState(false);
  const [isShowRelativeCorp, setIsShowRelativeCorp] = useState(false);
  const [headerType, setHeaderType] = useState(sessionStorage.getItem(SESSION_KEY.HEADER_TYPE));

  const { ref: languageRef } = useOutsideClick(() => {
    setIsShowLanguage(false);
  });
  const { ref: relativeCorpRef } = useOutsideClick(() => {
    setIsShowRelativeCorp(false);
  });

  const onClickReserveConfirm = needLogin(() => {
    window.location.href = '/my/reserve/list';
  });

  const onClickJoin = () => {
    window.open(
      `/auth/join.nhn?nextURL=${document.URL}`,
      '회원가입',
      `toolbar=no, channelmode=no, location=no, directories=no, menubar=no, scrollbars=yes, width=630, height=550`
    );
  };

  const isProductDetailPage = useCallback(
    (type: SubHeaderType) => {
      return pathname.indexOf('/product/') > -1 && type === sessionStorage.getItem(SESSION_KEY.SUB_HEADER_TYPE)
        ? 'page'
        : false;
    },
    [pathname]
  );

  useEffect(() => {
    setHeaderType(sessionStorage.getItem(SESSION_KEY.HEADER_TYPE));
  }, [pathname]);

  const moveTour = useCallback(() => {
    window.location.href = wrapTourURL('/');
  }, []);

  return (
    <>
      {gnbBanner && <GnbBanner banner={gnbBanner} />}
      <header className="common_header">
        <div className="header_util">
          <div className="header_util_inner">
            <div className="header_util_left">
              {headerType === HEADER_TYPE.SHOW && (
                <ul className="header_util_list">
                  <li className="header_util_item">
                    <button
                      type="button"
                      className="btn_dropdown"
                      aria-expanded={isShowLanguage}
                      onClick={() => setIsShowLanguage(true)}
                    >
                      KOR
                    </button>
                    <div className="header_dropdown_content" ref={languageRef}>
                      <ul className="header_language_list">
                        <li className="header_language_item">
                          <a href="/global/en" className="header_language_link">
                            ENG
                          </a>
                        </li>
                        <li className="header_language_item">
                          <a href="global/ja" className="header_language_link">
                            JPN
                          </a>
                        </li>
                        <li className="header_language_item">
                          <a href="global/zh" className="header_language_link">
                            CHN
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              )}
            </div>
            <div className="header_util_right">
              <ul className="header_util_list">
                <li className="header_util_item">
                  {isLoggedIn ? (
                    <>
                      {isPaycoVIP && (
                        <a href="/vip/main" className="vip_badge">
                          VIP
                        </a>
                      )}
                      <a href="/my/memberInfo/info" className="header_util_link user_id">
                        {maskedEmailId}
                      </a>
                    </>
                  ) : (
                    <a href="#" className="header_util_link" onClick={goLogin}>
                      로그인
                    </a>
                  )}
                </li>
                <li className="header_util_item">
                  <a href="#" className="header_util_link" onClick={onClickReserveConfirm}>
                    예매확인/취소
                  </a>
                </li>
                <li className="header_util_item">
                  {isLoggedIn ? (
                    <a href="/auth/logout" className="header_util_link">
                      로그아웃
                    </a>
                  ) : (
                    <a href="#" className="header_util_link" onClick={onClickJoin}>
                      회원가입
                    </a>
                  )}
                </li>
                <li className="header_util_item">
                  <a href="/help/main" className="header_util_link">
                    고객센터
                  </a>
                </li>
                <li className="header_util_item">
                  <a href="https://www.payco.com/point/intro.nhn" className="header_util_link">
                    <span className="common_icon logo_payco">
                      <span className="blind">PAYCO</span>
                    </span>
                  </a>
                </li>
                <li className="header_util_item">
                  <button
                    type="button"
                    className="btn_dropdown"
                    aria-expanded={isShowRelativeCorp}
                    onClick={() => setIsShowRelativeCorp(true)}
                  >
                    관계사
                  </button>
                  <div className="header_dropdown_content" ref={relativeCorpRef}>
                    <ul className="header_affiliate_list">
                      <li className="header_affiliate_item">
                        <a
                          href="http://www.bugs.co.kr/"
                          target="_blank"
                          className="header_affiliate_link"
                          rel="noreferrer"
                        >
                          <span className="common_icon logo_bugs">
                            <span className="blind">Bugs</span>
                          </span>
                        </a>
                      </li>
                      <li className="header_affiliate_item">
                        <a
                          href="https://www.comico.kr/"
                          target="_blank"
                          className="header_affiliate_link"
                          rel="noreferrer"
                        >
                          <span className="common_icon logo_comico">
                            <span className="blind">COMICO</span>
                          </span>
                        </a>
                      </li>
                      <li className="header_affiliate_item">
                        <a
                          href="https://www.hangame.com/"
                          target="_blank"
                          className="header_affiliate_link"
                          rel="noreferrer"
                        >
                          <span className="common_icon logo_hangame">
                            <span className="blind">HANGAME</span>
                          </span>
                        </a>
                      </li>
                      <li className="header_affiliate_item">
                        <a
                          href="https://inside.nhn.com/br/index.nhn"
                          target="_blank"
                          className="header_affiliate_link"
                          rel="noreferrer"
                        >
                          <span className="common_icon logo_inside_nhn">
                            <span className="blind">INSIDE NHN</span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="header_inner">
          <h1 className="header_title">
            <TKLink to="home" className="header_title_link">
              <span className="common_icon logo_black_kr">
                <span className="blind">티켓링크</span>
              </span>
            </TKLink>
          </h1>
          <nav className="header_nav">
            <ul className="header_nav_list">
              <li className="header_nav_item">
                <Link to="/" className="header_nav_link" aria-current={headerType === HEADER_TYPE.SHOW}>
                  공연/전시
                </Link>
              </li>
              <li className="header_nav_item">
                <Link to="/sports" className="header_nav_link" aria-current={headerType === HEADER_TYPE.SPORTS}>
                  스포츠
                </Link>
              </li>
              <li className="header_nav_item">
                <a onClick={moveTour} className="header_nav_link">
                  여행
                </a>
              </li>
            </ul>
          </nav>
          {adBanner && (
            <div className="header_banner">
              <a href={adBanner.click} target="_blank" className="header_banner_link" rel="noreferrer">
                <img src={adBanner.image} alt="헤더 광고 배너" className="header_banner_img" />
              </a>
            </div>
          )}
        </div>
        <div className="header_gnb">
          <div className="header_gnb_inner">
            {headerType === HEADER_TYPE.SHOW && (
              <>
                <HeaderGnbShow pathname={pathname} isProductDetailPage={isProductDetailPage} isExhibition />
                <SearchBox />
              </>
            )}
            {headerType === HEADER_TYPE.SPORTS && (
              <>
                <HeaderGnbSports pathname={pathname} isProductDetailPage={isProductDetailPage} />
                <SearchBox />
              </>
            )}
            {headerType === HEADER_TYPE.DRTOUR && <HeaderGnbDrTour />}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
