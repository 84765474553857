import { SearchKeywordRequest, SearchProductRequest } from '@tkl-packages/models';
import { Response } from '@tkl-packages/sdk/src/axios/axios.type';
import { requests } from '@tkl-packages/sdk/src/axios/mapiInterface';

const getPopularSearchApi = (): Promise<Response> => requests.get('search/popular/list', undefined, 5000);
const getSearchKeywordsApi = (params: SearchKeywordRequest): Promise<Response> =>
  requests.get('search/suggestion', params);
const getSearchProductsApi = (params: SearchProductRequest): Promise<Response> => requests.get('search/list', params);

export { getPopularSearchApi, getSearchKeywordsApi, getSearchProductsApi };
