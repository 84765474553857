const number = (n: number | string) => Number(n).toLocaleString();

const phoneNumber = (n: number | string) => {
  const num = n?.toString() || '';
  var triple = {
    regexp: /(02|0[\d]{2})([\d]*)([\d]{4})/,
    format: '$1-$2-$3'
  };

  var double = {
    regexp: /([\d]*)([\d]{4})/,
    format: '$1-$2'
  };

  if (triple.regexp.test(num)) {
    return num.replace(triple.regexp, triple.format);
  }

  return num.replace(double.regexp, double.format);
};

export { number, phoneNumber };
