import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { footerVisibleAtom } from '@tkl-apps/ticketlink/src/stores/footerStore/footerStore';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import useFooterAnchor from '@tkl-packages/hooks/src/useFooterAnchor';
import useScroll from '@tkl-packages/hooks/src/useScroll';
import { useSetAtom } from 'jotai';

interface FooterProps {
  useWrapUrl?: boolean;
}

const Footer = ({ useWrapUrl = false }: FooterProps) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [isStuck, setIsStuck] = useState(false);
  const footerRef = useRef<HTMLElement>(null);
  const setIsFooterVisible = useSetAtom(footerVisibleAtom);

  const {
    moveCompany,
    movePersonalInformation,
    moveYouth,
    movePolicyUsage,
    moveHelp,
    movePartner,
    moveAd,
    moveBusinessInformation
  } = useFooterAnchor({
    useWrapUrl
  });

  const onScroll = useCallback(() => {
    const scrollHeight = Math.max(document.documentElement.scrollHeight, document.body.scrollHeight);
    const scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
    const scrollBottom = scrollHeight - window.innerHeight - scrollTop;
    let nowIsActive = false;
    let nowIsStuck = false;

    if (scrollTop !== 0) {
      nowIsActive = true;
    }

    if (footerRef.current && scrollBottom - footerRef.current.clientHeight < 20) {
      nowIsStuck = true;
    }

    if (nowIsActive !== isActive) {
      setIsActive(nowIsActive);
    }

    if (nowIsStuck !== isStuck) {
      setIsStuck(nowIsStuck);
    }

    scrollTop;
  }, [isActive, isStuck]);

  useScroll(onScroll);

  useEffect(() => {
    const footerElement = footerRef.current;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        setIsFooterVisible(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0
    });

    if (footerElement) {
      observer.observe(footerElement);
    }

    return () => {
      if (footerElement) {
        observer.unobserve(footerElement);
      }
    };
  }, [setIsFooterVisible]);

  return (
    <>
      <button
        type="button"
        className={`btn_move_top ${isActive ? 'is-active' : ''} ${isStuck ? 'is-stuck' : ''}`}
        onClick={() => window.scrollTo({ top: 0 })}
      >
        <span className="blind">맨 위로 이동하기</span>
      </button>
      <footer className="common_footer" ref={footerRef}>
        <div className="footer_menu">
          <ul className="footer_menu_list">
            <li className="footer_menu_item">
              <TKLink to={moveCompany} className="footer_menu_link" useNative={true}>
                {t('회사소개')}
              </TKLink>
            </li>
            <li className="footer_menu_item">
              <TKLink to={movePersonalInformation} className="footer_menu_link" useNative={useWrapUrl}>
                <span className="text_bold">{t('개인정보 처리방침')}</span>
              </TKLink>
            </li>
            <li className="footer_menu_item">
              <TKLink to={moveYouth} className="footer_menu_link" useNative={useWrapUrl}>
                {t('청소년 보호정책')}
              </TKLink>
            </li>
            <li className="footer_menu_item">
              <TKLink to={movePolicyUsage} className="footer_menu_link" useNative={useWrapUrl}>
                {t('이용약관')}
              </TKLink>
            </li>
            <li className="footer_menu_item">
              <TKLink to={moveHelp} className="footer_menu_link" useNative={true}>
                {t('고객센터')}
              </TKLink>
            </li>
            <li className="footer_menu_item">
              <TKLink to={movePartner} className="footer_menu_link" useNative={useWrapUrl}>
                {t('티켓판매안내')}
              </TKLink>
            </li>
            <li className="footer_menu_item">
              <TKLink to={moveAd} className="footer_menu_link" useNative={useWrapUrl}>
                {t('광고안내')}
              </TKLink>
            </li>
          </ul>
        </div>
        <div className="footer_inner">
          <span className="footer_corp_title">{t('엔에이치엔링크 주식회사')}</span>
          <address className="footer_address">
            <p>
              <span className="footer_address_item">{t('주소: 06043 서울특별시 강남구 강남대로 586, 제이빌딩')}</span>
              <span className="footer_address_item">{t('대표이사: 왕문주')}</span>
              <span className="footer_address_item">{t('사업자등록번호: 144-81-25090')}</span>
            </p>
            <p>
              <span className="footer_address_item">1588-7890</span>
              <span className="footer_address_item">membership@nhnlink.co.kr</span>
              <span className="footer_address_item">{t('통신판매업 신고번호: 제 2023-서울강남-04352호')}</span>
              <span className="footer_address_item">
                <a href={moveBusinessInformation} target="_blank" className="btn_hyperlink" rel="noreferrer">
                  {t('사업자정보확인')}
                </a>
              </span>
              <span className="footer_address_item">{t('개인정보보호 책임자: 서상훈')}</span>
            </p>
          </address>
          <span className="footer_copyright">Copyright © NHN LINK Corporation. All rights reserved.</span>
          <div className="footer_etc">
            <ul className="footer_sns">
              <li className="footer_sns_item">
                <a href="https://blog.naver.com/t_link" target="_blank" className="footer_sns_link" rel="noreferrer">
                  <span className="common_icon icon_naverblog_gray">
                    <span className="blind">네이버블로그</span>
                  </span>
                </a>
              </li>
              <li className="footer_sns_item">
                <a
                  href="https://www.youtube.com/@ticketlink012"
                  target="_blank"
                  className="footer_sns_link"
                  rel="noreferrer"
                >
                  <span className="common_icon icon_youtube_gray">
                    <span className="blind">유튜브</span>
                  </span>
                </a>
              </li>
              <li className="footer_sns_item">
                <a
                  href="https://www.facebook.com/ticketlink"
                  target="_blank"
                  className="footer_sns_link"
                  rel="noreferrer"
                >
                  <span className="common_icon icon_facebook_gray">
                    <span className="blind">페이스북</span>
                  </span>
                </a>
              </li>
              <li className="footer_sns_item">
                <a
                  href="https://www.instagram.com/ticketlink.co.kr/"
                  target="_blank"
                  className="footer_sns_link"
                  rel="noreferrer"
                >
                  <span className="common_icon icon_instagram_gray">
                    <span className="blind">인스타그램</span>
                  </span>
                </a>
              </li>
              <li className="footer_sns_item">
                <a
                  href="https://twitter.com/nhn_ticketlink"
                  target="_blank"
                  className="footer_sns_link "
                  rel="noreferrer"
                >
                  <span className="common_icon icon_twitter_gray">
                    <span className="blind">트위터</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
