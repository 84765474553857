import { useEffect, useRef } from 'react';

const useOutsideClick = (onClose: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  const onClickOutsideHandler = (e: MouseEvent) => {
    if (!ref.current?.contains(e.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', onClickOutsideHandler);
    return () => {
      window.removeEventListener('mousedown', onClickOutsideHandler);
    };
    // eslint-disable-next-line
  }, []);

  return { ref };
};

export default useOutsideClick;
