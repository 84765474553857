import { useRef } from 'react';
import LottieReact, { LottieRef } from 'lottie-react';

interface props {
  autoplay: boolean;
  animationData: Object;
  className: string;
}

const Lottie = ({ autoplay, animationData, className }: props) => {
  const lottieRef: LottieRef = useRef(null);

  return (
    <LottieReact
      as="span"
      lottieRef={lottieRef}
      loop={false}
      className={className}
      autoplay={autoplay}
      animationData={animationData}
      onClick={() => {
        if (lottieRef?.current) {
          lottieRef.current?.play();
        }
      }}
      onClickCapture={() => {
        if (lottieRef?.current) {
          lottieRef.current?.play();
        }
      }}
    />
  );
};

export default Lottie;
