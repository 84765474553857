import Barcode from 'react-jsbarcode';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import withLogin from '@tkl-apps/ticketlink/src/components/User/withLogin';
import { LayoutMobile } from '@tkl-packages/components/src/ticketlink/Layout';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import useClock from '@tkl-packages/hooks/src/useClock';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import { getFacilityMemberCardApi } from '@tkl-packages/services/src/mapi/facilityApi';
import dayjs from 'dayjs';

const MemberCardPage = () => {
  const navigate = useNavigate();
  const onClickBackBtn = () => {
    if (isAppWeb()) {
      callAppScheme(APP_SCHEME.CLOSE_WEB_VIEW);
    } else {
      navigate(-1);
    }
  };

  const { data: userData } = useQuery({
    queryKey: [QUERY_KEY.FACILITY_MEMBER_CARD],
    queryFn: () => getFacilityMemberCardApi().then((res) => res?.data)
  });

  return (
    <LayoutMobile title="모바일 회원카드" footer={<></>} onClickBackBtn={onClickBackBtn}>
      <main id="m_content" className="m_container page_member_card" role="main">
        <section className="m_section section_member_card">
          <div className="member_card">
            <div className="member_card_heading">
              <div className="card_emblem_imgbox">
                <img src={userData?.emblemUrl} alt="엠블럼 이미지" className="card_emblem_imgbox" />
              </div>
              <h2 className="member_card_title">{userData?.title}</h2>
            </div>
            <div className="member_card_info">
              <ul className="card_info_list">
                <li className="card_info_item">
                  <span className="card_info_title">회원명</span>
                  <div className="card_info_desc">{userData?.memberName}</div>
                </li>
                <li className="card_info_item">
                  <span className="card_info_title">구단회원번호</span>
                  <div className="card_info_desc">{userData?.memberNumber}</div>
                </li>
              </ul>
            </div>
            <div className="member_card_barcode">
              <div className="card_barcode_time">
                <span className="blind">현재시각</span>
                <BarcodeTime />
              </div>
              <div className="payco_live_band"></div>
              <div className="card_barcode">
                <div className="card_barcode_imgbox">
                  <Barcode
                    className="card_barcode_img"
                    value={userData?.memberNumber}
                    options={{
                      height: 120,
                      fontSize: 0
                    }}
                  />
                </div>
                <span className="card_barcode_name">{userData?.memberNumber}</span>
              </div>
            </div>
          </div>
        </section>
      </main>
    </LayoutMobile>
  );
};

const BarcodeTime = () => {
  const now = useClock();
  return <em className="barcode_time">{dayjs(now).tz().format('YY/MM/DD HH:mm:ss')}</em>;
};

export default withLogin(MemberCardPage);
