import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import datePickerHeader from '@tkl-packages/components/src/ticketlink/Calendar/calendarHeader';
import en from 'date-fns/locale/en-US';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import zh from 'date-fns/locale/zh-CN';

const getCalendarLocale = (language: string) => {
  if (language === 'en') return en;
  if (language === 'ja') return ja;
  if (language === 'zh') return zh;
  return ko;
};

interface props {
  selected: Date;
  onChange: (date: Date) => void;
  includeDates?: Date[];
  minDate?: Date;
  maxDate?: Date;
}

const Calendar = ({ selected, onChange, includeDates, minDate, maxDate }: props) => {
  const { i18n } = useTranslation();

  const CustomHeader = datePickerHeader({ calendarType: 'day', minDate, maxDate });

  return (
    <DatePicker
      locale={getCalendarLocale(i18n.language)}
      dateFormatCalendar="yyyy.MM"
      selected={selected}
      onChange={(date) => onChange(date as Date)}
      includeDates={includeDates}
      minDate={minDate}
      maxDate={maxDate}
      renderCustomHeader={CustomHeader}
      inline
      disabledKeyboardNavigation
    />
  );
};

export default Calendar;
