import { useQuery } from '@tanstack/react-query';
import { hasContent } from '@tkl-apps/ticketlink/src/utils/product';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { getTeamGuideApi } from '@tkl-packages/services/src/mapi/sportsApi';

interface props {
  teamId: string;
}

const TeamInformationMobile = ({ teamId }: props) => {
  const { data } = useQuery({
    queryKey: [QUERY_KEY.TEAM_INFORMATION, teamId],
    queryFn: () => getTeamGuideApi(teamId).then((res) => res?.data)
  });

  return (
    <>
      {hasContent(data) ? (
        <div className="team_guide" dangerouslySetInnerHTML={{ __html: data }}></div>
      ) : (
        <div className="common_data_none">
          <p className="text">구단안내 내용이 없습니다.</p>
        </div>
      )}
    </>
  );
};

export default TeamInformationMobile;
