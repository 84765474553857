import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import { SearchBox } from '@tkl-apps/ticketlink/src/components/Search';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import { HEADER_TYPE, SESSION_KEY } from '@tkl-packages/constants/user';
import useOutsideClick from '@tkl-packages/hooks/src/useOutsideClick';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import Cookies from 'js-cookie';

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isLoggedIn, goLogin, needLogin } = useContext(LoginContext);
  const maskedEmailId = Cookies.get(`${COOKIE_KEY.MASKED_EMAIL_ID}`);

  const [isShowLanguage, setIsShowLanguage] = useState(false);
  const [headerType, setHeaderType] = useState(sessionStorage.getItem(SESSION_KEY.HEADER_TYPE));

  const { ref: languageRef } = useOutsideClick(() => {
    setIsShowLanguage(false);
  });

  const onClickReserveConfirm = needLogin(() => {
    window.location.href = '/my/reserve/list';
  });

  const onClickJoin = () => {
    window.open(
      `/auth/join.nhn?nextURL=${document.URL}`,
      '회원가입',
      `toolbar=no, channelmode=no, location=no, directories=no, menubar=no, scrollbars=yes, width=630, height=550`
    );
  };

  const getAriaCurrent = useCallback(
    (path: string) => {
      return pathname.indexOf(path) > -1 ? 'page' : false;
    },
    [pathname]
  );

  useEffect(() => {
    setHeaderType(sessionStorage.getItem(SESSION_KEY.HEADER_TYPE));
  }, [pathname]);

  return (
    <>
      <header className="common_header">
        <div className="header_util">
          <div className="header_util_inner">
            <div className="header_util_left">
              {headerType === HEADER_TYPE.SHOW && (
                <ul className="header_util_list">
                  <li className="header_util_item">
                    <button
                      type="button"
                      className="btn_dropdown"
                      aria-expanded={isShowLanguage}
                      onClick={() => setIsShowLanguage(true)}
                    >
                      KOR
                    </button>
                    <div className="header_dropdown_content" style={{ zIndex: 100 }} ref={languageRef}>
                      <ul className="header_language_list">
                        <li className="header_language_item">
                          <a href="/global/en" className="header_language_link">
                            ENG
                          </a>
                        </li>
                        <li className="header_language_item">
                          <a href="/global/ja" className="header_language_link">
                            JPN
                          </a>
                        </li>
                        <li className="header_language_item">
                          <a href="/global/zh" className="header_language_link">
                            CHN
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              )}
            </div>
            <div className="header_util_right">
              <ul className="header_util_list">
                <li className="header_util_item">
                  {isLoggedIn ? (
                    <a href="/my/memberInfo/info" className="header_util_link user_id">
                      {maskedEmailId}
                    </a>
                  ) : (
                    <a href="#" className="header_util_link" onClick={goLogin}>
                      {t('로그인')}
                    </a>
                  )}
                </li>
                <li className="header_util_item">
                  <a href="#" className="header_util_link" onClick={onClickReserveConfirm}>
                    {t('예매확인/취소')}
                  </a>
                </li>
                <li className="header_util_item">
                  {isLoggedIn ? (
                    <a href="/auth/logout" className="header_util_link">
                      {t('로그아웃')}
                    </a>
                  ) : (
                    <a href="#" className="header_util_link" onClick={onClickJoin}>
                      {t('회원가입')}
                    </a>
                  )}
                </li>
                <li className="header_util_item">
                  <a href="/help/main" className="header_util_link">
                    {t('고객센터')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="header_inner">
          <h1 className="header_title">
            <TKLink to="home" className="header_title_link">
              {/*TODO: [글로벌개편] 언어에 따라 다른 로고?*/}
              <span className="common_icon logo_black_kr">
                <span className="blind">티켓링크</span>
              </span>
            </TKLink>
          </h1>
        </div>
        <div className="header_gnb">
          <div className="header_gnb_inner">
            <nav className="header_gnb_area">
              <ul className="header_gnb_list">
                {/* <!-- [D] hover, focus 시 .header_gnb_item에 is-active 클래스 추가 --> */}
                <li className="header_gnb_item">
                  <TKLink
                    to="home"
                    className="header_gnb_link"
                    aria-current={pathname.includes('/home') ? 'page' : false}
                  >
                    {t('홈')}
                  </TKLink>
                </li>
                <li className="header_gnb_item">
                  <TKLink to="performance" className="header_gnb_link" aria-current={getAriaCurrent('/performance')}>
                    {t('공연')}
                  </TKLink>
                </li>
                <li className="header_gnb_item">
                  <TKLink to="exhibition" className="header_gnb_link" aria-current={getAriaCurrent('/exhibition')}>
                    {t('전시')}
                  </TKLink>
                </li>
                <li className="header_gnb_item">
                  <TKLink to="esports" className="header_gnb_link" aria-current={getAriaCurrent('/esports')}>
                    {t('e스포츠')}
                  </TKLink>
                </li>
                <li className="header_gnb_item">
                  <TKLink
                    to="planning-event"
                    className="header_gnb_link"
                    aria-current={getAriaCurrent('/planning-event')}
                  >
                    {t('기획전')}
                  </TKLink>
                </li>
              </ul>
            </nav>
            <SearchBox />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
