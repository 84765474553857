import { useParams } from 'react-router-dom';
import { useQueries } from '@tanstack/react-query';
import { getFirstExhibitionPlanningUrl, getFirstSportsPlanningUrl } from '@tkl-apps/ticketlink/src/utils/planning';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { CategoryListType } from '@tkl-packages/models';
import { isFacility, isSports } from '@tkl-packages/sdk/src/validations';
import {
  getCategoryListApi,
  getFacilityCategoryListApi,
  getSportCategoryListByIdApi
} from '@tkl-packages/services/src/mapi';

export const useGetFirstPlanningUrl = (shouldFetch = true) => {
  const { categoryId } = useParams();

  const [exhibitionData, sportsData] = useQueries({
    queries: [
      {
        queryKey: [QUERY_KEY.PLANNING_EXHIBITION_CATEGORY_LIST],
        queryFn: getCategoryListApi,
        select: (data: CategoryListType[]) => {
          return getFirstExhibitionPlanningUrl(data);
        },
        enabled: !isSports()
      },
      {
        queryKey: [QUERY_KEY.PLANNING_SPORTS_CATEGORY_LIST, categoryId],
        queryFn: () => {
          if (isFacility()) {
            return getFacilityCategoryListApi();
          }

          return getSportCategoryListByIdApi(categoryId!);
        },
        select: (data: CategoryListType[]) => {
          return getFirstSportsPlanningUrl(data, categoryId!);
        },
        enabled: shouldFetch && (isFacility() || isSports())
      }
    ]
  });

  if (isSports()) {
    return sportsData;
  }

  return exhibitionData;
};
