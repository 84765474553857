import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import { LayoutMobile } from '@tkl-packages/components/src/ticketlink/Layout';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { APP_SCHEME, appScheme, callAppScheme } from '@tkl-packages/constants/appScheme';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import { number } from '@tkl-packages/sdk/src/utils/number';
import { getUserInfoApi } from '@tkl-packages/services/src/mapi/userApi';

import Cookies from 'js-cookie';

const Category = () => {
  const appVersion = Cookies.get(COOKIE_KEY.APP_VERSION);
  const isFacilityLoggedIn = Cookies.get(COOKIE_KEY.FACILITY_LOGIN_TOKEN);

  const { goLogin } = useContext(LoginContext);
  const { data: userInfo, isFetchedAfterMount } = useQuery({
    queryKey: [QUERY_KEY.USER_INFO],
    queryFn: () => getUserInfoApi().then((res) => res?.data),
    enabled: !!isFacilityLoggedIn
  });

  const onToggleLogin = () => {
    if (isFacilityLoggedIn) {
      callAppScheme(APP_SCHEME.FACILITY_LOGOUT);
    } else {
      goLogin();
    }
  };
  return (
    <LayoutMobile header={<></>} footer={<></>}>
      {isFetchedAfterMount && (
        <main id="m_content" className="m_container page_mypage" role="main">
          <h1 className="blind">카테고리</h1>
          <section className="m_section section_mypage_login">
            <h2 className="blind">로그인/로그아웃</h2>
            <div className="mypage_login">
              <span className="mypage_login_text">{userInfo?.maskingName}</span>

              <button type="button" className="common_btn btn_ghost btn_small" onClick={onToggleLogin}>
                로그아웃
              </button>
            </div>
          </section>
          <section className="m_section section_mypage_own">
            <h2 className="blind">보유 내역</h2>
            <ul className="mypage_own_list">
              <li className="mypage_own_item" onClick={() => (window.location.href = '/my/discount/coupon')}>
                <span className="mypage_own_text">쿠폰</span>
                <span className="mypage_own_number">{number(userInfo?.couponCount || 0)}</span>
              </li>
              <li className="mypage_own_item" onClick={() => (window.location.href = '/my/advanceTicket')}>
                <span className="mypage_own_text">예매권</span>
                <span className="mypage_own_number">{number(userInfo?.advancedTicketCount || 0)}</span>
              </li>
            </ul>
          </section>
          <section className="m_section section_mypage_info">
            <h2 className="blind">나의 정보</h2>
            <div className="mypage_group">
              <h3 className="blind">나의 활동내역</h3>
              <ul className="mypage_list">
                <li className="mypage_item">
                  <a href="/my/reserve/list" className="mypage_link">
                    예매/취소내역
                    <span className="count">{number(userInfo?.reserveCount || 0)}</span>
                  </a>
                </li>
                <li className="mypage_item">
                  <a href="/ticket" className="mypage_link">
                    스마트 티켓
                  </a>
                </li>
                <li className="mypage_item">
                  <Link to="/mobile/member-card" className="mypage_link">
                    모바일 회원카드
                  </Link>
                </li>
                <li className="mypage_item">
                  <a href="/mobile/season-card" className="mypage_link">
                    모바일 시즌카드
                  </a>
                </li>
              </ul>
            </div>
            <div className="mypage_group">
              <h3 className="blind">나의 정보관리</h3>
              <ul className="mypage_list">
                <li className="mypage_item">
                  <a href="/my/memberInfo/refundAccount" className="mypage_link">
                    환불계좌관리
                  </a>
                </li>
              </ul>
            </div>
            <div className="mypage_group">
              <h3 className="blind">기타 주요 서비스</h3>
              <ul className="mypage_list">
                <li className="mypage_item">
                  <a href={appScheme(APP_SCHEME.COUPON)} className="mypage_link">
                    쿠폰
                  </a>
                </li>
                <li className="mypage_item">
                  <a href={appScheme(APP_SCHEME.HELP)} className="mypage_link">
                    고객센터
                  </a>
                </li>
                <li className="mypage_item">
                  <a href={appScheme(APP_SCHEME.NOTICE)} className="mypage_link">
                    공지사항
                  </a>
                </li>
              </ul>
            </div>
            <div className="mypage_group">
              <h3 className="blind">앱 정보</h3>
              <ul className="mypage_list">
                <li className="mypage_item">
                  <a href={appScheme(APP_SCHEME.OPEN_SOURCE_LICENSE)} className="mypage_link">
                    Open Source License
                  </a>
                </li>
                <li className="mypage_item">
                  <div className="mypage_item_box">
                    <span className="mypage_item_title">버전정보</span>
                    <span className="mypage_item_desc">{appVersion}</span>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </main>
      )}
    </LayoutMobile>
  );
};

export default Category;
