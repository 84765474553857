import { Fragment, useContext } from 'react';
import { ModalStateContext } from '@tkl-apps/ticketlink/src/components/Context/Modal';

const Modals = () => {
  const openedModals = useContext(ModalStateContext);

  return (
    <>
      {openedModals.map((modalInfo) => {
        const { key, Modal } = modalInfo;
        return <Fragment key={`${key}`}>{Modal}</Fragment>;
      })}
    </>
  );
};

export default Modals;
