import { Enum } from '@tkl-packages/models';

const CATEGORY: Enum = {
  PERFORMANCE: 10,
  EXHIBITION: 11,
  SPORTS: 136
} as const;

const PERFORMANCE = '10'; // 공연
const MUSICAL = '16'; //뮤지컬
const PLAY = '15'; //연극
const CLASSIC = '18'; //클래식
const CONCERT = '14'; //콘서트
const DAEHAKRO = '84'; // 대학로
const FAMILY = '85'; // 아동/가족

const EXHIBITION = '11'; // 전시
const FESTIVAL = '24'; // 전시/축제
const LEISURE = '65'; // 레저/체험

export { CATEGORY, PERFORMANCE, MUSICAL, PLAY, CLASSIC, CONCERT, DAEHAKRO, FAMILY, EXHIBITION, FESTIVAL, LEISURE };
