import { CSSProperties } from 'react';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';

export const getPoductToolbarUtilStyle = (): CSSProperties => {
  if (isAppWeb() && !/\/product\/[0-9]/.test(location.pathname)) {
    return {
      bottom: 20
    };
  }

  return {};
};
