import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { CERTIFIED_DEVICE_STATUS } from '@tkl-packages/constants/user';
import { CertifiedDeviceStatus } from '@tkl-packages/models';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import { getDeviceAuthStatusApi } from '@tkl-packages/services/src/mapi';
import Cookies from 'js-cookie';

const useDeviceAuthStatus = (hasAuthReinforced: boolean): { certifiedDeviceStatusCode: CertifiedDeviceStatus } => {
  const deviceId = Cookies.get(COOKIE_KEY.DEVICE_ID) || '';
  const { data: certifiedDeviceStatusCode = CERTIFIED_DEVICE_STATUS.DEVICE_UNCERTIFIED } = useQuery({
    queryKey: [QUERY_KEY.DEVICE_AUTH_STATUS],
    queryFn: () => getDeviceAuthStatusApi(deviceId).then((res) => res?.data),
    enabled: hasAuthReinforced && isAppWeb() && !!deviceId
  });

  return { certifiedDeviceStatusCode };
};
export default useDeviceAuthStatus;
