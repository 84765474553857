import { useEffect } from 'react';

interface props {
  text: string;
  onClose: () => void;
}

const ToastModalMobile = ({ text, onClose }: props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 1500);

    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="toast_wrap" role="status">
      <div className="toast">
        <span className="toast_text">{text}</span>
      </div>
    </div>
  );
};

export default ToastModalMobile;
