type WindowTargetType = '_blank' | '_self' | '_parent' | '_top' | string;

type WindowFeaturesType = {
  width?: number;
  height?: number;
  left?: number;
  top?: number;
  scrollbars?: boolean;
  menubar?: boolean;
  toolbar?: boolean;
  location?: boolean;
  status?: boolean;
  resizable?: boolean;
  [key: string]: number | boolean | undefined;
};

export default function openWindow(
  url: string,
  target: WindowTargetType = '_blank',
  features: WindowFeaturesType = {}
): Window | null {
  const featuresStr = buildWindowFeatures(features);

  let newWindow = window.open(url, target, featuresStr);

  if (newWindow) {
    newWindow.focus();
  }

  return newWindow;
}

function buildWindowFeatures(features: WindowFeaturesType): string {
  return Object.entries(features)
    .map(([key, value]) => (typeof value === 'boolean' ? `${key}=${value ? 'yes' : 'no'}` : `${key}=${value}`))
    .join(',');
}
