import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import ProductHeader from '../../components/HTMLHeader/ProductHeader';
import { ProductMobile } from '../../pages/mobile';
import { Product } from '../../pages/pc';

const ProductRoute = () => {
  return (
    <Routes>
      <Route
        path=":productId"
        element={
          <ProductHeader>
            <Channel pc={<Product />} mobile={<ProductMobile />} />
          </ProductHeader>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default ProductRoute;
