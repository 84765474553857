import { useState } from 'react';
import useScroll from '../../../../hooks/src/useScroll';

interface props {
  title?: string;
  useTransparency?: boolean;
  useTransparencyTitle?: boolean;
  useShareBtn?: boolean;
  onClickBackBtn?(): void;
  onClickCloseBtn?(): void;
  onClickShareBtn?(): void;
}

const HeaderMobile = ({
  title,
  useTransparency = false,
  useTransparencyTitle = false,
  useShareBtn = false,
  onClickBackBtn,
  onClickCloseBtn,
  onClickShareBtn
}: props) => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const onScroll = () => {
    if (useTransparency) {
      setIsScrolled(window.scrollY > 0);
    }
  };

  useScroll(onScroll);

  return (
    <header className={`m_header ${useTransparency && !isScrolled ? 'type_transparent' : ''}`}>
      <div className="header_inner">
        <h1 className={`header_title ${useTransparency && useTransparencyTitle && !isScrolled ? 'blind' : ''}`}>
          {title}
        </h1>
        <div className="header_util_left">
          {onClickBackBtn && (
            <button type="button" className="header_button button_back" onClick={onClickBackBtn}>
              <span className="blind">이전 페이지</span>
            </button>
          )}
        </div>
        <div className="header_util_right">
          {onClickCloseBtn && (
            <button type="button" className="header_button button_close" onClick={onClickCloseBtn}>
              <span className="blind">닫기</span>
            </button>
          )}
          {useShareBtn && (
            <button type="button" className="header_button button_share" onClick={onClickShareBtn}>
              <span className="blind">공유하기</span>
            </button>
          )}
        </div>
      </div>
    </header>
  );
};

export default HeaderMobile;
