import { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import { GoogleAnalytics } from '@tkl-packages/components/src/ticketlink/GA';
import { OgTag } from '@tkl-packages/components/src/ticketlink/OgTag';

const SportsOffseasonHeader: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <OgTag />
      <Channel
        pc={
          <Helmet key={location.key}>
            <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/sports.css`} />
            <link rel="stylesheet" type="text/css" href={`${process.env.CSS_URL}/popup.css`} />
          </Helmet>
        }
      />
      <GoogleAnalytics />
      {children}
    </>
  );
};

export default SportsOffseasonHeader;
