import { Route, Routes } from 'react-router-dom';
import NormalHeader from '@tkl-apps/ticketlink/src/components/HTMLHeader/NormalHeader';
import Main from '@tkl-apps/ticketlink/src/pages/global/Main';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';

const HomeRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<Main />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default HomeRoute;
