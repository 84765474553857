import { useState } from 'react';
import TKLink from '@tkl-packages/components/src/atoms/TKLink';
import useFooterAnchor from '@tkl-packages/hooks/src/useFooterAnchor';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';

interface FooterMobileProps {
  useWrapUrl?: boolean;
}

const FooterMobile = ({ useWrapUrl = false }: FooterMobileProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const { movePersonalInformation, moveYouth, movePolicyUsage, moveBusinessInformation } = useFooterAnchor({
    useWrapUrl
  });

  return (
    <footer className="m_footer ">
      <div className="footer_inner">
        <div className="footer_toggle">
          <button
            type="button"
            className="footer_toggle_btn"
            onClick={() => setIsOpened((prev) => !prev)}
            aria-expanded={isOpened}
          >
            <span className="text">엔에이치엔링크 주식회사</span>
            <span className="blind">사업자 정보 자세히 보기</span>
          </button>
          <div className="footer_toggle_cont">
            <address>
              대표이사 : 왕문주
              <br />
              06043, 서울특별시 강남구 강남대로 586, 제이빌딩
              <br />
              사업자등록번호 : 144 81 25090
              <br />
              통신판매업 신고번호 : 제 2023 서울강남 04352 호<br />
              고객센터 : 1588 7890 membership@nhnlink.co.kr
            </address>
          </div>
        </div>
        <ul className="footer_term_list">
          <li className="footer_term_item">
            <TKLink
              to={movePolicyUsage}
              className="footer_term_link"
              target={isAppWeb() ? '_self' : '_blank'}
              rel="noreferrer"
              useNative={useWrapUrl}
            >
              이용약관
            </TKLink>
          </li>
          <li className="footer_term_item">
            <TKLink
              to={movePersonalInformation}
              className="footer_term_link"
              target={isAppWeb() ? '_self' : '_blank'}
              rel="noreferrer"
              useNative={useWrapUrl}
            >
              <span className="text_bold" style={{ fontWeight: 'bold' }}>
                개인정보 처리방침
              </span>
            </TKLink>
          </li>
          <li className="footer_term_item">
            <TKLink
              to={moveYouth}
              className="footer_term_link"
              target={isAppWeb() ? '_self' : '_blank'}
              rel="noreferrer"
              useNative={useWrapUrl}
            >
              청소년 보호정책
            </TKLink>
          </li>
          <li className="footer_term_item">
            <TKLink to={moveBusinessInformation} className="footer_term_link" useNative={useWrapUrl}>
              사업자정보
            </TKLink>
          </li>
        </ul>
        <span className="footer_copyright">Copyright © NHN LINK Corp. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default FooterMobile;
