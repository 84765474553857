import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { LoginMobile } from '../../pages/mobile';

const LoginRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel mobile={<LoginMobile />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default LoginRoute;
