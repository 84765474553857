export const TOUR_COOKIE = 'DRTOUR';
export const ALPHA_NAPI_URL = 'https://alpha-napi.ticketlink.co.kr';
export const NAPI_URL = 'https://napi.ticketlink.co.kr';

const TARGET_TYPE = {
  BLANK: 'NEW',
  SELF: 'DEFAULT'
} as const;

const APP_TARGET_TYPE = {
  WEB_VIEW: 'WEB_VIEW',
  WEB_BROWSER: 'WEB_BROWSER'
} as const;

const GNB_EXPOSURE_TYPE = {
  GENERAL: 'GENERAL',
  EVENT: 'EVENT'
} as const;

export { TARGET_TYPE, APP_TARGET_TYPE, GNB_EXPOSURE_TYPE };
