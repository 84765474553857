import { useRef, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { isMobile } from '@tkl-packages/sdk/src/validations';
import debounce from 'lodash/debounce';

interface props {
  query?: string;
  path: string;
}

const useSearch = ({ query, path }: props) => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState(query || '');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isShowAutoComplete, setIsShowAutoComplete] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const setDebounceSearchKeyword = useRef(debounce((k) => setSearchKeyword(k), 500)).current;

  const onChangeKeyword: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setKeyword(e.target.value);
    setDebounceSearchKeyword(e.target.value);
    if (!isShowAutoComplete) {
      setIsShowAutoComplete(true);
    }
  };

  const onDeleteKeyword = () => {
    setKeyword('');
    setSearchKeyword('');
    inputRef.current?.focus();
  };

  const onSearch = () => {
    if (isShowAutoComplete) {
      setIsShowAutoComplete(false);
    }

    if (isMobile()) {
      navigate({
        pathname: path,
        search: `?${createSearchParams({ query: keyword })}`
      });
    } else {
      window.location.href = `${path}?${createSearchParams({ query: keyword })}`;
    }
  };

  return {
    keyword,
    searchKeyword,
    isShowAutoComplete,
    setIsShowAutoComplete,
    inputRef,
    onChangeKeyword,
    onDeleteKeyword,
    onSearch
  };
};

export default useSearch;
