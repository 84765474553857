import { Navigate, Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { LocalMobile } from '../../pages/mobile';
import { Local } from '../../pages/pc';

const LocalRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<Local />} mobile={<LocalMobile />} />
          </NormalHeader>
        }
      />
      <Route
        path=":category"
        element={
          <NormalHeader>
            <Channel pc={<Local />} mobile={<LocalMobile />} />
          </NormalHeader>
        }
      />
      <Route path="*" element={<Navigate to=".." />} />
    </Routes>
  );
};

export default LocalRoute;
