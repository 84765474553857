import { useMemo } from 'react';
import { useCategory } from '@tkl-apps/ticketlink/src/stores/categoryStore/categoryStore';
import { GnbCategory } from '@tkl-packages/models';

const getCategoryId = (categories: GnbCategory[], sportsName?: string) => {
  const category = categories.find((cat) => cat.categoryNameEng === sportsName);
  return category ? category.categoryId : null;
};

export const useCategoryId = (sportsName?: string) => {
  const { sportsSubCategories } = useCategory();

  const categoryId = useMemo(() => getCategoryId(sportsSubCategories, sportsName), [sportsSubCategories, sportsName]);

  return categoryId;
};
