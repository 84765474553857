import { forwardRef } from 'react';
import { ToastModalMobile } from '@tkl-packages/components/src/ticketlink/Modal';
import { MODAL_KEY } from '@tkl-packages/constants/modal';
import { SCHEDULE } from '@tkl-packages/constants/sports';
import useModal from '@tkl-packages/hooks/src/useModal';
import { SportsScheduleDate, ScheduleValues } from '@tkl-packages/models';
import dayjs from 'dayjs';

interface Props {
  dateList: SportsScheduleDate[];
  type: ScheduleValues;
  setRef: (el: HTMLElement, date: string) => void;
  activeDate: string;
  onClickDate: (date: string) => void;
}

interface calendarItemProps {
  dateInfo: SportsScheduleDate;
  activeDate: string;
  onClickDate: (date: string) => void;
}

const SpreadCalendarMobile = forwardRef<any, Props>(
  ({ dateList, activeDate, onClickDate, type, setRef }, scrollRef) => {
    return (
      <div className="spread_calendar" ref={type === SCHEDULE.MAIN ? scrollRef : undefined}>
        <ul className="spread_calendar_list" ref={type === SCHEDULE.TEAM ? scrollRef : undefined}>
          {dateList.map((dateInfo, i) => (
            <CalendarItem
              ref={(el: HTMLLIElement) => {
                setRef(el, dateInfo.date);
              }}
              dateInfo={dateInfo}
              key={i}
              activeDate={activeDate}
              onClickDate={onClickDate}
            />
          ))}
        </ul>
      </div>
    );
  }
);
SpreadCalendarMobile.displayName = 'SpreadCalendarMobile';

const CalendarItem = forwardRef(
  ({ dateInfo, activeDate, onClickDate }: calendarItemProps, ref: React.ForwardedRef<HTMLLIElement>) => {
    const { open, close } = useModal(MODAL_KEY.NO_MATCH_TOAST);
    const date = dayjs(dateInfo.date, 'YYYY/MM/DD').tz();
    return (
      <li
        className="spread_calendar_item"
        ref={ref}
        onClick={() => {
          if (dateInfo.isEmpty) {
            open(<ToastModalMobile text="선택한 날짜에 예매 가능한 경기가 없습니다." onClose={close} />);
          } else {
            onClickDate(dateInfo.date);
          }
        }}
      >
        <button
          type="button"
          className={`spread_calendar_btn ${activeDate === dateInfo.date ? 'is-active' : ''} ${
            date.day() === 6 ? 'type_sat' : date.day() === 0 ? 'type_sun' : ''
          }`}
          disabled={dateInfo.isEmpty}
        >
          <span className="date">{date.date()}</span>
          <span className="day">{date.format('dd')}</span>
        </button>
      </li>
    );
  }
);
CalendarItem.displayName = 'CalendarItem';

export default SpreadCalendarMobile;
