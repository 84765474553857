import { ShareParams } from '@tkl-apps/ticketlink/src/hooks/product/convertShareParams';
import useShare from '@tkl-apps/ticketlink/src/hooks/product/useShare';
import { SHARE_TYPE } from '@tkl-packages/constants/share';
import { ShareType } from '@tkl-packages/models';

interface props {
  type: ShareType;
  params: ShareParams;
}

const IconMobile = ({ type, params }: props) => {
  const { onShare } = useShare(type.code, params);

  return (
    <li className={`share_item ${type.class}`} data-clipboard-text={type.code === SHARE_TYPE.URL ? params.url : ''}>
      <a className="share_link" onClick={onShare}>
        <span className="sp">{type.name}</span>
      </a>
    </li>
  );
};

export default IconMobile;
