import { useNavigate } from 'react-router-dom';
import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { PRODUCT_CHANNEL } from '@tkl-packages/constants/product';
import { Product, RankingProduct } from '@tkl-packages/models';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';

const useProductDetailRoute = ({ searchType, productId }: Product | RankingProduct) => {
  const navigate = useNavigate();
  const goProductDetail = () => {
    const isLinkon = searchType === PRODUCT_CHANNEL.ONLINE;

    if (isAppWeb()) {
      callAppScheme(isLinkon ? APP_SCHEME.LINK_ON : APP_SCHEME.PRODUCT, { productId });
    } else if (isLinkon) {
      window.location.href = `/linkon/product/${productId}`;
    } else {
      navigate(`/product/${productId}`);
    }
  };

  return { goProductDetail };
};

export default useProductDetailRoute;
