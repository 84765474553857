import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import { getFacilityCategoryListApi } from '@tkl-packages/services/src/mapi/planning/sport/eventListApi';

export const useGetFacilityPlanningEventList = () => {
  const { data: events = [], ...queryInfo } = useQuery({
    queryKey: [QUERY_KEY.PLANNING_FACILITY_CATEGORY_LIST],
    queryFn: () => getFacilityCategoryListApi(),
    select: (data) =>
      data.map((e) => ({
        eventId: e.planningEventId,
        imagePath: e.imageUrl,
        title: e.planningEventName
      }))
  });

  return { events, ...queryInfo };
};
