import { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  TeamScheduleMobile,
  TeamBannerMobile,
  TeamNoticeMobile,
  TeamSeatMapMobile,
  TeamPriceGuideMobile,
  TeamInformationMobile,
  EventsMobile
} from '@tkl-apps/ticketlink/src/components/Sports';
import { useGetFacilityPlanningEventList } from '@tkl-apps/ticketlink/src/hooks/planning/useGetFacilityPlanningEventList';
import { LayoutMobile } from '@tkl-packages/components/src/ticketlink/Layout';
import { Modal, FullScreenModalMobile, BottomModalMobile } from '@tkl-packages/components/src/ticketlink/Modal';
import { QUERY_KEY } from '@tkl-packages/constants/api';
import useAndroidAppBlockSwipeRefresh from '@tkl-packages/hooks/src/useAndroidAppBlockSwipeRefresh';
import { SportsPopupBanner, Notice } from '@tkl-packages/models';
import { COOKIE_KEY } from '@tkl-packages/sdk/src/axios/constants';
import { getTeamBannersApi } from '@tkl-packages/services/src/mapi/sportsApi';
import {
  getTeamPopupNoticeApi,
  getTeamPopupBannerApi,
  getFacilityTeamApi
} from '@tkl-packages/services/src/mapi/sportsApi';
import Cookies from 'js-cookie';
import { getMobileBannerLinkInfo } from '../../utils/banner';

const TeamMainMobile: FC = () => {
  const [teamId, setTeamId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const { data: team = {}, isSuccess } = useQuery({
    queryKey: [QUERY_KEY.TEAM_DETAIL],
    queryFn: () => getFacilityTeamApi().then((res) => res?.data)
  });

  useEffect(() => {
    if (isSuccess && team) {
      setTeamId(team.teamId.toString());
      setCategoryId(team.categoryId);
    }
  }, [isSuccess, team]);

  const { data: banners = [] } = useQuery({
    queryKey: [QUERY_KEY.SPORTS_TEAM_BANNERS, teamId],
    queryFn: () => getTeamBannersApi(teamId!).then((res) => res?.data),
    enabled: !!teamId
  });

  const [isShowCoupon, setIsShowCoupon] = useState(false);

  const [isShowPopupNoticeModal, setIsShowPopupNoticeModal] = useState(false);
  const [notice, setNotice] = useState<Notice>();
  const { data: popupNotice = [], isSuccess: popupNoticeSucess } = useQuery({
    queryKey: [QUERY_KEY.TEAM_POPUP_NOTICE, teamId],
    queryFn: () =>
      getTeamPopupNoticeApi(teamId!).then((res) =>
        res?.data?.filter((n: Notice) => !Cookies.get(`${COOKIE_KEY.SPORTS_TEAM_NOTICE_SHOW}_${n.noticeId}`))
      ),
    enabled: !!teamId
  });

  useEffect(() => {
    if (popupNoticeSucess && popupNotice.length > 0) {
      setNotice(popupNotice[0]); // 첫 번째 공지를 설정합니다.
      setIsShowPopupNoticeModal(true);
    }
  }, [popupNoticeSucess, popupNotice]);

  const onClosePopupNotice = () => {
    setIsShowPopupNoticeModal(popupNotice.length > 0);
    setNotice(popupNotice.shift());
  };

  const [isShowPopupBannerModal, setIsShowPopupBannerModal] = useState(false);
  const {
    data: popupBanners,
    isSuccess: popupBannersSuccess,
    isFetchedAfterMount
  } = useQuery({
    queryKey: [QUERY_KEY.TEAM_POPUP_BANNERS, teamId],
    queryFn: () => getTeamPopupBannerApi(teamId!).then((res) => res?.data),
    enabled: !!teamId
  });

  useEffect(() => {
    if (popupBannersSuccess) {
      setIsShowPopupBannerModal(!!popupBanners);
    }
  }, [popupBannersSuccess, popupBanners]);

  const { events } = useGetFacilityPlanningEventList();

  const [isShowNoticeModal, setIsShowNoticeModal] = useState(false);
  const [isShowGuideModal, setIsShowGuideModal] = useState(false);
  const [isShowPriceModal, setIsShowPriceModal] = useState(false);
  const [isShowSeatModal, setIsShowSeatModal] = useState(false);

  useAndroidAppBlockSwipeRefresh(isShowNoticeModal || isShowGuideModal || isShowPriceModal || isShowSeatModal);

  return (
    <>
      <LayoutMobile useTransparency title={team.categoryName} footer={<></>}>
        <main id="m_content" className="m_container page_main_team" role="main">
          <section
            className="m_section section_team_spotlight"
            style={{ background: `${team.bgColorCode || '#878D95'}` }}
          >
            <div className="team_spotlight">
              <h2 className="blind">{team.teamName}</h2>
              <div className="team_spotlight_imgbox">
                {team.logoImagePath && (
                  <img src={team.logoImagePath} alt={`${team.teamName} 엠블럼 이미지`} className="team_spotlight_img" />
                )}
              </div>
              <span className="team_spotlight_name">{team.teamName}</span>
            </div>
          </section>
          <section className="m_section section_team_info">
            <h3 className="blind">구단 정보</h3>
            <div className="team_info_box">
              <ul className="team_info_list">
                <li className="team_info_item">
                  <button type="button" className="team_info_link" onClick={() => setIsShowNoticeModal(true)}>
                    구단공지
                  </button>
                </li>
                <li className="team_info_item">
                  <button type="button" className="team_info_link" onClick={() => setIsShowGuideModal(true)}>
                    구단안내
                  </button>
                </li>
                <li className="team_info_item">
                  <button type="button" className="team_info_link" onClick={() => setIsShowPriceModal(true)}>
                    요금/할인카드
                  </button>
                </li>
                <li className="team_info_item">
                  <button type="button" className="team_info_link" onClick={() => setIsShowSeatModal(true)}>
                    좌석도
                  </button>
                </li>
              </ul>
            </div>
          </section>
          {isShowCoupon && (
            <section className="m_section section_team_coupon">
              <div className="common_btn_box">
                <a href={`/sports/${teamId}/coupons`} className="common_btn btn_secondary btn_small btn_coupon_info">
                  할인쿠폰
                </a>
              </div>
            </section>
          )}
          {(banners.length > 0 || events.length > 0) && (
            <section className="m_section section_team_banner">
              {banners.length > 0 && <TeamBannerMobile banners={banners} />}
              {events.length > 0 && <EventsMobile categoryId={categoryId!} events={events} />}
            </section>
          )}
          {teamId && categoryId && (
            <TeamScheduleMobile
              teamId={teamId}
              setIsShowCoupon={(value: boolean) => setIsShowCoupon(value)}
              facilityCategoryId={categoryId}
            />
          )}
        </main>
      </LayoutMobile>

      {isShowPopupNoticeModal && (
        <Modal title={notice?.noticeTitle} onClose={onClosePopupNotice}>
          <div className="modal_notice" dangerouslySetInnerHTML={{ __html: notice?.noticeContent! }}></div>
        </Modal>
      )}

      {isShowPopupBannerModal && popupBanners.length > 0 && !isShowPopupNoticeModal && isFetchedAfterMount && (
        <BottomModalMobile
          onClose={() => setIsShowPopupBannerModal(false)}
          hasFooter={true}
          hasNeverSee={true}
          isLarge={popupBanners.length > 1}
          code={`${COOKIE_KEY.SPORTS_TEAM_MAIN_MODAL_SHOW}_${teamId}`}
        >
          <ul className="bottom_banner_list">
            {popupBanners.map((popupBanner: SportsPopupBanner) => {
              const { url, target } = getMobileBannerLinkInfo({
                appTarget: popupBanner.bannerTargetCode,
                appUrl: popupBanner.bannerAppUrl,
                webTarget: popupBanner.bannerUrlTargetCode,
                webUrl: popupBanner.bannerUrl
              });
              return (
                <li className="bottom_banner_item" key={popupBanner.bannerId}>
                  <div className="bottom_banner_imgbox">
                    <a href={url} target={target} className="bottom_banner_link">
                      <img src={popupBanner.imagePath} alt={popupBanner.title} className="bottom_banner_img" />
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        </BottomModalMobile>
      )}

      {isShowNoticeModal && (
        <FullScreenModalMobile title="구단공지" onClose={() => setIsShowNoticeModal(false)}>
          <TeamNoticeMobile teamId={teamId!} />
        </FullScreenModalMobile>
      )}
      {isShowGuideModal && (
        <FullScreenModalMobile title="구단안내" onClose={() => setIsShowGuideModal(false)}>
          <TeamInformationMobile teamId={teamId!} />
        </FullScreenModalMobile>
      )}
      {isShowPriceModal && (
        <FullScreenModalMobile title="요금/할인 안내" onClose={() => setIsShowPriceModal(false)}>
          <TeamPriceGuideMobile teamId={teamId!} />
        </FullScreenModalMobile>
      )}
      {isShowSeatModal && (
        <FullScreenModalMobile title="좌석도" onClose={() => setIsShowSeatModal(false)}>
          <TeamSeatMapMobile teamId={teamId!} />
        </FullScreenModalMobile>
      )}
    </>
  );
};

export default TeamMainMobile;
