import { FC } from 'react';
import { ReactDatePickerCustomHeaderProps, registerLocale } from 'react-datepicker';
import { CalendarType } from '@tkl-packages/models';
import { getMonth, getYear } from 'date-fns';
import ko from 'date-fns/locale/ko'; // 한국어적용
registerLocale('ko', ko); // 한국어적용

interface DatePickerHeaderProps {
  calendarType: CalendarType;
  yearItemNumber?: number;
  minDate: Date;
  maxDate: Date;
}

const datePickerHeader: Function = ({ calendarType, yearItemNumber = 6, minDate, maxDate }: DatePickerHeaderProps) => {
  let onPrevClick: () => void;
  let onNextClick: () => void;
  let isPrevDisabled: boolean;
  let isNextDisabled: boolean;
  let titleText: string;
  let prevText: string;
  let nextText: string;

  const DatePickerCore: FC<ReactDatePickerCustomHeaderProps> = ({
    date,
    decreaseMonth,
    decreaseYear,
    increaseMonth,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    prevYearButtonDisabled,
    nextYearButtonDisabled
  }) => {
    if (calendarType === 'day' || calendarType === 'week') {
      onPrevClick = decreaseMonth;
      onNextClick = increaseMonth;
      isPrevDisabled = prevMonthButtonDisabled;
      isNextDisabled = nextMonthButtonDisabled;

      titleText = `${getYear(date)}.${String(getMonth(date) + 1).padStart(2, '0')}`;
      prevText = '이전 달로 이동';
      nextText = '다음 달로 이동';
    } else if (calendarType === 'month') {
      onPrevClick = decreaseYear;
      onNextClick = increaseYear;
      isPrevDisabled = prevYearButtonDisabled;
      isNextDisabled = nextYearButtonDisabled;

      titleText = `${getYear(date)}`;
      prevText = '이전 연도로 이동';
      nextText = '다음 연도로 이동';
    } else if (calendarType === 'year') {
      const currentYear = new Date(date).getFullYear();
      const showPer = currentYear / yearItemNumber;
      const isInteger = Number.isInteger(showPer);

      let min = minDate.getFullYear() / yearItemNumber;
      let max = maxDate.getFullYear() / yearItemNumber;

      // isPrevDisabled MIN
      if (Number.isInteger(min)) {
        min = min - 1;
      } else {
        min = Math.floor(min);
      }

      if (isInteger) {
        if (showPer - 1 === min) {
          isPrevDisabled = true;
        }
      } else {
        if (prevYearButtonDisabled) {
          isPrevDisabled = prevYearButtonDisabled;
        } else {
          if (Math.floor(showPer) === min) {
            isPrevDisabled = true;
          }
        }
      }

      // isNextDisabled MAX
      if (!Number.isInteger(max)) {
        max = Math.floor(max) + 1;
      }

      if (!isInteger) {
        if (Math.floor(showPer) + 1 === max) {
          isNextDisabled = true;
        }
      } else {
        if (nextYearButtonDisabled) {
          isNextDisabled = nextYearButtonDisabled;
        }
      }

      onPrevClick = decreaseYear;
      onNextClick = increaseYear;

      titleText = '연도 선택';
      prevText = '이전 연도로 이동';
      nextText = '다음 연도로 이동';
    }

    return (
      <div className="calendar_custom_header">
        <div className="calendar_nav">
          <strong className="calendar_title">{titleText}</strong>

          <button
            type="button"
            className="calendar_nav_btn calendar_prev"
            onClick={onPrevClick}
            disabled={isPrevDisabled}
          >
            <span className="common_icon icon_arrow_left ty_xs"></span>
            <span className="blind">{prevText}</span>
          </button>

          <button
            type="button"
            className="calendar_nav_btn calendar_next"
            onClick={onNextClick}
            disabled={isNextDisabled}
          >
            <span className="common_icon icon_arrow_right ty_xs"></span>
            <span className="blind">{nextText}</span>
          </button>
        </div>
      </div>
    );
  };
  DatePickerCore.displayName = 'DatePickerCore';

  return DatePickerCore;
};

export default datePickerHeader;
