// import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { APP_SCHEME, callAppScheme } from '@tkl-packages/constants/appScheme';
import { VISIBLE_VALUE } from '@tkl-packages/constants/user';
import { ModalProps } from '@tkl-packages/models';
import { expireCookieFormat, getDateAfterDays } from '@tkl-packages/sdk/src/utils/date';
import { isAppWeb } from '@tkl-packages/sdk/src/validations';
import Cookies from 'js-cookie';

const Modal = ({
  children,
  onClose,
  title,
  hasHideToday = false,
  code,
  buttonName = '닫기',
  className
}: ModalProps) => {
  if (hasHideToday && code && Cookies.get(code) === VISIBLE_VALUE.HIDE) {
    return null;
  }

  const setHideToday = (e: React.MouseEvent) => {
    e.preventDefault();
    const expireDate = expireCookieFormat(getDateAfterDays());
    if (code) {
      Cookies.set(code, VISIBLE_VALUE.HIDE, { expires: new Date(expireDate) });
    }
    if (isAppWeb()) {
      callAppScheme(APP_SCHEME.NOT_SHOW_UP, { key: code, expire: expireDate });
    }
    onClose();
  };

  return (
    <div className={`common_modal_wrap ${className ? className : ''}`}>
      <div className="dimmed" onClick={onClose}></div>
      <div className="common_modal" role="dialog" aria-modal="true">
        <div className="common_modal_header">
          <h3 className="common_modal_title">{title}</h3>
        </div>
        <div className="common_modal_content">{children}</div>
        <div className="common_modal_footer">
          {hasHideToday && (
            <span className="checkbox_item">
              <input
                type="checkbox"
                name="checkbox_group"
                id="check_today_03"
                className="input_checkbox"
                onClick={(e) => setHideToday(e)}
              />
              <label htmlFor="check_today_03" className="label_checkbox">
                오늘 하루 그만보기
              </label>
            </span>
          )}
          <button type="button" className="common_modal_close" onClick={onClose}>
            {buttonName}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
