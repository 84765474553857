import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const OgTag = () => {
  const location = useLocation();

  return (
    <Helmet key={location.key}>
      <meta property="og:type" content="website" />
      <meta property="og:title" content="티켓링크" />
      <meta
        property="og:description"
        content="티켓예매, 스포츠, 공연, 전시 등 티켓 예매서비스 제공. 페이코(PAYCO)간편결제 지원."
      />
      <meta property="og:image" content="https://tketlink.dn.toastoven.net/static/ticketlink_renewal.png" />
    </Helmet>
  );
};

export default OgTag;
