import { forwardRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductContext } from '@tkl-apps/ticketlink/src/components/Context/Product';
import { ReserveButton } from '@tkl-apps/ticketlink/src/components/Product';
import payco_banner_src from '@tkl-packages/assets/payco_payment_banner.png';
import { SALE_STATE } from '@tkl-packages/constants/product';

interface props {
  globalWebUseYn: boolean;
}

const ReserveNotAvailable = forwardRef(({ globalWebUseYn }: props, ref: any) => {
  const { t } = useTranslation();
  const { isShowAuthFan, fanclubName, onFanClubCertify, saleStatus, onGlobalReserve, isAuthReinforced } =
    useContext(ProductContext);

  return (
    <section className="common_section section_product_reserve">
      <h2 className="blind">상품 예매</h2>
      <div className="product_detail_reserve" style={{ display: 'flex' }}>
        <p className="product_reserve_notice">
          {saleStatus === SALE_STATE.BEFORE ? t('예매 오픈 전 입니다.') : t('예매 마감 되었습니다.')}
        </p>
      </div>
      <div className="product_reserve_util" ref={ref}>
        {saleStatus === SALE_STATE.BEFORE && (
          <div className="product_reserve_banner">
            <a href="/event/paycoPromo" target="_blank" className="product_reservebn_link">
              <img src={payco_banner_src} alt="페이코 간편 결제" className="product_reservebn_img" />
            </a>
          </div>
        )}

        <div className="product_reserve_btnarea">
          {isShowAuthFan && (
            <>
              <a href="#" className="common_btn btn_ghost btn_xlarge" onClick={onFanClubCertify}>
                {fanclubName} {t('인증')}
              </a>
              <span className="product_reserve_arrow">
                <span className="common_icon icon_arrow_right ty_sm"></span>
              </span>
            </>
          )}

          {globalWebUseYn && (
            <a href="#" className="common_btn btn_ghost btn_xlarge" onClick={onGlobalReserve}>
              {t('GLOBAL BOOKING')}
            </a>
          )}
          <ReserveButton
            isAuthReinforced={isAuthReinforced}
            saleStatus={saleStatus}
            className="btn_xlarge"
            onReserve={() => {}}
          />
        </div>
      </div>
    </section>
  );
});
ReserveNotAvailable.displayName = 'ReserveNotAvailable';

export default ReserveNotAvailable;
