import { GNB_EXPOSURE_TYPE } from '@tkl-packages/constants/common';
import { CategoryComponentType, GnbCategory, Links } from '@tkl-packages/models';
import { isAppWeb, isMobile } from '@tkl-packages/sdk/src/validations';
import dayjs from 'dayjs';

const link = (category: GnbCategory) =>
  channel(
    category.gnbExposureTypeCode === GNB_EXPOSURE_TYPE.GENERAL
      ? category.generalPageLinkInfo!
      : category.eventPageLinkInfo!
  );

const channel = (links: Links) =>
  isAppWeb()
    ? { url: links?.mobileAppCategoryLinkInfo?.url, target: links?.mobileAppCategoryLinkInfo?.targetCode }
    : isMobile()
      ? {
          url: links?.mobileWebCategoryLinkInfo?.url,
          target: links?.mobileWebCategoryLinkInfo?.targetCode
        }
      : { url: links?.pcCategoryLinkInfo?.url, target: links?.pcCategoryLinkInfo?.targetCode };

const convert = (category: GnbCategory, needAllOptions = true): CategoryComponentType[] => {
  if (!category || category.subGnbs.length === 0) return [];

  const newCategory: CategoryComponentType[] = [];

  if (needAllOptions) {
    const { url, target } = link(category);
    newCategory.push({
      code: category.categoryId.toString(),
      name: '전체',
      url,
      target,
      subCategory: []
    });
  }

  category.subGnbs?.forEach((c) => {
    if (c.gnbExposureTypeCode === GNB_EXPOSURE_TYPE.GENERAL) {
      const { url, target } = link(c);
      const nc = {
        code: c.categoryId.toString(),
        name: c.categoryNameKor,
        url,
        target
      } as unknown as CategoryComponentType;
      if (category.subGnbs?.length > 0) {
        nc.subCategory = convert(c);
      }
      newCategory.push(nc);
    }
  });

  return newCategory;
};

const filterByExposureDates = (categories: GnbCategory[]) => {
  return categories.filter((category: GnbCategory) => {
    if (category.subGnbs.length > 0) {
      category.subGnbs = filterByExposureDates(category.subGnbs);
    }
    return (
      category.gnbExposureTypeCode === GNB_EXPOSURE_TYPE.GENERAL ||
      (category.gnbExposureTypeCode === GNB_EXPOSURE_TYPE.EVENT &&
        dayjs().isAfter(new Date(category.eventPageLinkInfo?.exposureStartDatetime!)) &&
        dayjs().isBefore(new Date(category.eventPageLinkInfo?.exposureEndDatetime!)))
    );
  });
};

export { link, convert, filterByExposureDates };
