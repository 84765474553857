import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LoginContext } from '@tkl-apps/ticketlink/src/components/Context/Login';
import { QuickMenu } from '@tkl-packages/components/src/ticketlink/Menu';
import openWindow from '@tkl-packages/sdk/src/utils/openWindow';

const HelpQuickMenu = () => {
  const { needLogin } = useContext(LoginContext);

  return (
    <QuickMenu title="고객센터">
      <li className="quick_menu_item qmenu1">
        <Link to="/help/guide/memberInfo" className="quick_link">
          아이디/
          <br />
          패스워드 찾기
        </Link>
      </li>
      <li className="quick_menu_item qmenu2">
        <a
          onClick={needLogin(() =>
            openWindow(`https://${location.hostname}/cs/inquiry-history`, '', {
              left: 100,
              top: 100,
              width: 880,
              height: 900
            })
          )}
          className="quick_link"
        >
          상담내역
          <br />
          확인하기
        </a>
      </li>
      <li className="quick_menu_item qmenu3">
        <Link to="/help/guide/cancel" className="quick_link">
          예매취소
          <br />
          환불 문의
        </Link>
      </li>
      <li className="quick_menu_item qmenu4">
        <Link to="/help/guide/ticket" className="quick_link">
          티켓
          <br />
          배송문의
        </Link>
      </li>
      <li className="quick_menu_item qmenu5">
        <Link to="/help/guide/discountMethod" className="quick_link">
          할인수단
          <br />
          안내
        </Link>
      </li>
      <li className="quick_menu_item qmenu6">
        <Link to="/help/guide/reservation/baseball" className="quick_link">
          스포츠티켓
          <br />
          알아보기
        </Link>
      </li>
      <li className="quick_menu_item qmenu7">
        <Link to="/help/guide/discountCard/baseball" className="quick_link">
          할인카드
          <br />
          안내보기
        </Link>
      </li>
    </QuickMenu>
  );
};

export default HelpQuickMenu;
