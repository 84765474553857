import { ReactNode } from 'react';

export interface BaseProps {
  children?: ReactNode;
  className?: string;
  name: string;
}

export type Props = BaseProps;

function LeftSubMenu(props: BaseProps) {
  const { name, children } = props;
  return (
    <li className="has_sub">
      <span className="help_submenu_tit">{name}</span>
      <ul className="help_submenu">{children}</ul>
    </li>
  );
}
export default LeftSubMenu;
