import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../../components/HTMLHeader/NormalHeader';
import PlanningEvent from '../../../pages/global/PlanningEvent';

const ESportsRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel pc={<PlanningEvent />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default ESportsRoute;
