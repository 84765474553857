import { Route, Routes } from 'react-router-dom';
import Channel from '@tkl-packages/components/src/ticketlink/Channel';
import NormalHeader from '../../components/HTMLHeader/NormalHeader';
import { FacilityCategory } from '../../pages/facility';
import { CategoryMobile } from '../../pages/mobile';

const CategoryRoute = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <NormalHeader>
            <Channel mobile={<CategoryMobile />} facility={<FacilityCategory />} />
          </NormalHeader>
        }
      />
    </Routes>
  );
};

export default CategoryRoute;
