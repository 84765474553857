const isValidUrl = (str: string) => {
  try {
    new URL(str, 'http://example.com');
    return true;
  } catch (e) {
    return false;
  }
};

export default isValidUrl;
